import React from "react";
import Hero from "../../components/about-us/hero/hero";
import SectionTwo from "../../components/about-us/section-2/section-two";
import SectionThree from "../../components/about-us/section-3/section-three";
import SectionFour from "../../components/about-us/section-4/section-four";
import SectionFive from "../../components/about-us/section-5/section-five";
import SectionSix from "../../components/about-us/section-6/section-six";
import SectionSeven from "../../components/about-us/section-7/section-seven";
import SectionEight from "../../components/about-us/section-8/section-eight";
import SectionNine from "../../components/about-us/section-9/section-nine";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import rightArrow from '../../assets/images/icons/right-arrow.svg'
function AboutUs(props) {
  const  {t} = useTranslation();
  return (
    <>
      <Hero />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
      <SectionSeven />
      <SectionEight />
      <SectionNine />
      <div className="w-100 text-right p-3">
        <Link className="btn font-weight-bold" to={'/our-services'}>{t('our-services-nav')}<img src={rightArrow} alt="right-arrow" className="ml-2"/></Link>
      </div>
    </>
  );
}

export default AboutUs;
