import React, { useState } from "react";
import "./App.scss";
import Navbar from "./components/navbar/navbar";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Footer from "./components/footer/footer";
import Home from "./views/home/home";
import AboutUs from "./views/about-us/about-us";
import OurServices from "./views/our-services/our-services";
import ScrollToTop from "./scroll-to-top";
import ContactUs from "./views/contact-us/contact-us";
import TermsAndConditions from "./views/terms-and-conditions/terms-and-conditions";
import Loader from "./components/loader/loader";
import { useTranslation } from "react-i18next";
import PrivacyAndCookies from "./views/privacy-and-cookies/privacy-and-cookies";
import Report from "./views/report/report";
import CompanyReport from "./views/company-report/company-report";
import CompanyProfile from "./views/company-profile/company-profile";
import { Auth0Provider } from "@auth0/auth0-react";

import { Admin } from "./views/admin";
function App(props) {
  const { ready } = useTranslation("homepage", { useSuspense: false });
  const [languageChanged, setLanguageChanged] = useState(false);
  const languageButtonClicked = (event) => {
    setLanguageChanged(true);
    setTimeout(() => {
      setLanguageChanged(false);
    }, 500);
  };
  if (!ready || languageChanged) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Loader />
      </div>
    );
  }
  return (
    <div className="App">
      <BrowserRouter>
        <div className="app-container mx-auto h-100">
          <Navbar onLanguageChange={(event) => languageButtonClicked(event)} />
          <Switch>
            <Route path="/about-us">
              <AboutUs />
            </Route>
            <Route path="/our-services">
              <OurServices />
            </Route>
            <Route path="/contact-us">
              <ContactUs />
            </Route>
            <Route path="/terms-and-conditions">
              <TermsAndConditions />
            </Route>
            <Route path="/privacy-and-cookies">
              <PrivacyAndCookies />
            </Route>
            <Route path="/Griffin_Mining_Eureka_Initiation_Report_25_Sep_2020">
              <Report />
            </Route>
            <Route path="/company-report/:companyid">
              <CompanyReport />
            </Route>
            <Route path="/company-profile/:companyid">
              <CompanyProfile />
            </Route>
            <Route path="/analyst-training" component={() => {
                  window.location.replace('https://forms.office.com/r/fAv6tXZc3k');
                  return null;
              }} />
            <Route path="/admin">
              <Auth0Provider
                domain={process.env.REACT_APP_AUTH0_DOMAIN}
                clientId={process.env.REACT_APP_CLIENT_ID}
                redirectUri={process.env.REACT_APP_REDIRECT_URI}
                audience={process.env.REACT_APP_AUDIENCE}
              >
                <Admin />
              </Auth0Provider>
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>

          <Footer />
        </div>
        <ScrollToTop />
      </BrowserRouter>
    </div>
  );
}

export default App;
