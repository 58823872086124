import React from "react";
import "./content.scss";
function TermsAndConditionsContent(props) {
  return (
    <div className="container-fluid mb-5 text-left py-5 terms-and-conditions-content animate__animated animate__fadeIn animate__delay-1s">
      <h3>Terms & Conditions</h3>
      <p>
        Welcome to our website. Please read the terms and conditions (’terms’)
        for your use of our website below. If you have any questions or
        concerns, please contact us by emailing{" "}
        <a href="mailto:info@eightfoldresearch.com">
          info@eightfoldresearch.com
        </a>
      </p>
      <p>
        Please do not use this website if you do not agree to these terms. If
        you access any part of this website, you agree to be bound by these
        terms and to comply with them.
      </p>
      <p>
        The term ‘Eightfold Research’ or ‘us’ or ‘we’ refers to the owner of the
        website whose registered office is Shibuya 2-10-15, Shibuya-ku, Tokyo,
        Japan 150-0002. Our company registration number is 9011003010605, Tokyo,
        Japan. The term ‘you’ refers to the user or viewer of our website.
      </p>

      <h3 className="mt-2">Website</h3>
      <ul>
        <li>
          Eightfold Research reserves the right to changes any part of this
          website or the terms without notice. You should read our terms and
          conditions each time you use the website.
        </li>
        <li>
          The content of this website is for your general information and use
          only.
        </li>
        <li>
          From time to time, this website may also include links to other
          websites. These links are provided for your convenience to provide
          further information. They do not signify that we endorse the
          website(s). We have no responsibility for the content of the linked
          website(s)
        </li>
        <li>
          We reserve the right to suspend access to this website at any time,
          either temporarily or permanently, and without notice. We will not
          accept any liability for any interruptions or inability to use our
          website.
        </li>
      </ul>

      <h3 className="mt-2">Use</h3>
      <ul>
        <li>
          All our research reports and other content on this website should be
          considered purely as dissemination of information about the companies
          we cover and not as investment recommendations or advice.
        </li>
        <li>
          Our company-sponsored research reports are prepared and issued upon
          being commissioned by the companies discussed in those reports, in
          consideration of a fee payable by the companies.
        </li>
        <li>
          We do not provide any investment banking or brokerage services to
          publicly listed companies, nor do we provide investment advice to any
          individual or institutional investor.
        </li>
        <li>
          Your use of any information or materials on this website is entirely
          at your own risk, for which we shall not be liable. It shall be your
          own responsibility to ensure that any products, services or
          information available through this website meet your specific
          requirements.
        </li>
        <li>
          Neither we nor any third parties provide any warranty or guarantee as
          to the accuracy, timeliness, performance, completeness or suitability
          of the information and materials found or offered on this website for
          any particular purpose. You acknowledge that such information and
          materials may contain inaccuracies or errors and we expressly exclude
          liability for any such inaccuracies or errors to the fullest extent
          permitted by law.
        </li>
      </ul>

      <h3 className="mt-2">Translation Disclaimer</h3>
      <ul>
        <li>Google Translate is used to translate company filing documents on the Eightfold Research website. Google Translate is a free online language translator that can convert text and web pages into a variety of languages. These translations may not accurately reflect the content of the original filing and may contain incorrect language in some cases.</li>
        <li>The accuracy, reliability, or timeliness of any information translated by this system is not guaranteed by Eightfold Research. Furthermore, some company filings, including images and photos, have not been translated.</li>
        <li>Any individual or entity that relies on information obtained from translated documents on the Eightfold Research website does so entirely at their own risk. The user assumes the risk of any inaccuracies, errors, or other issues that may arise. Eightfold Research is not liable for any damage or problems that may arise as a result of using Google Translate.</li>
      </ul>
      
      <h3 className="mt-2">Privacy and Cookies </h3>
      <ul>
        <li>
          The terms and use of the website are also governed by our Privacy and
          cookies policy (
          <a href="https://www.eightfoldresearch.com/privacy-and-cookies/">
            https://www.eightfoldresearch.com/privacy-and-cookies/
          </a>
          ).
        </li>
      </ul>

      <h3 className="mt-2">Trademark and Copyright</h3>
      <ul>
        <li>
          This website contains content that is owned by or licensed to us. This
          material includes, but is not limited to, the design, layout, look,
          appearance, and graphics.
        </li>
        <li>
          The trademarks and logos used and displayed on this website are
          Eightfold Research’s registered or unregistered trademarks or those of
          our licensors.
        </li>
        <li>
          Unauthorised reproduction of content is prohibited. Unauthorised use
          of this website may give rise to a claim for damages and/or could be a
          criminal offence.
        </li>
      </ul>

      <h3 className="mt-2">Jurisdiction</h3>
      <ul>
        <li>
          Your use of this website and any dispute arising out of such use of
          the website is subject to the laws of Japan. If you continue to browse
          and use this website, you are agreeing to comply with and be bound by
          the following terms and conditions of use, which together with our
          privacy policy govern Eightfold Research’s relationship with you in
          relation to this website. If you disagree with any part of these terms
          and conditions, please do not use our website.
        </li>
      </ul>
    </div>
  );
}

export default TermsAndConditionsContent;
