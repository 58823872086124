import React from "react";

export function AssetsOverTime(props) {
  return (
    <div className="seperator-top-xtra p-3" style={{background:"#e8e8e8"}}>
      <h5 className="font-weight-bold">Griffin Mining</h5>
      <h5 className="text-dark font-weight-bold">Acquisition of assets over time</h5>
      <table className="table table-striped">
        <caption>Source: Eightfold Research, Griffin Mining</caption>
        <tbody>
          <tr>
            <td className="font-weight-bold">May 1988</td>
            <td>Bermuda based mining finance company</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Jun 1997</td>
            <td>listed on London Stock Exchange AIM</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Nov 1997</td>
            <td>
              Acquired 50% interest in China Zinc which owned 60% of Hebei Hua
              Ao
            </td>
          </tr>
          <tr>
            <td className="font-weight-bold">Mar 1998</td>
            <td>Acquired remaining 50% interest in China Zinc</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Jun 2012</td>
            <td>Acquired additional 28.8% in Hebei Hua Ao via China Zinc</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
