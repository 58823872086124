import React from "react";
import "./section-seven.scss";
import image from "../../../assets/images/about-us-section-seven.png";
import { useTranslation } from "react-i18next";
function SectionSeven(props) {
  const { t } = useTranslation();
  return (
    <div className="about-us-section-seven bg-white mt-5 animate__animated animate__fadeIn animate__delay-1s">
      <div className="container-fluid p-0 no-gutters">
        <div className="row no-gutters">
          <div className="col-md-4 position-relative">
            <img
              src={image}
              alt="about us section three"
              className="img-fluid w-100 d-none d-sm-block"
            />
          </div>
          <div className="col-md-8 position-relative">
            <div className="container text-section d-flex flex-column justify-content-center ">
              <h3 className=" text-left">
                {t("about-us.value-proposition-for-investors")}
              </h3>
              <p className="text-left">
                {t("about-us.our-research-helps-investors-discover")}
              </p>
              <p className="text-left">
                {t("about-us.eightfold-research-has-a-long-term-value")}
              </p>
              <p className="text-left">{t("about-us.it-is-no-secret")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionSeven;
