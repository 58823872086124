import React from "react";
import "./tables.scss";
export function ProductionAndMetalConcentrates(props) {
  return (
    <div className="mt-4 seperator-top container-grey p-2">
      <h5>
        Production and metal concentrate sales in actual and normalised earnings
        scenarios
      </h5>
      <table className="table custom-tables table-striped table-sm table-responsive">
        <caption>Source: Griffin Mining, Eightfold Research</caption>
        <thead>
          <tr>
            <th>Actual & estimated normalised production</th>
            <th>FY 2017</th>
            <th>FY 2018</th>
            <th>FY 2019</th>
            <th>1.0 MT</th>
            <th>1.5 MT</th>
            <th>2.0 MT</th>
          </tr>

          {/* <tr>
            <td>Mine Production</td>

            <td>1.00</td>
            <td>1.50</td>
            <td>2.00</td>
            <td>
              M<span>ultiplier</span>
            </td>
          </tr>
          <tr>
            <td>Ore Mined (000 T)</td>
            <td>920</td>
            <td>872</td>
            <td>862</td>
            <td>1000</td>
            <td>1500</td>
            <td>2000</td>
          </tr> */}
        </thead>

        <tbody className="highlight-last-rows">
          <tr>
            <td colSpan="7"></td>
          </tr>
          <tr className="text-center">
            <td></td>
            <td colSpan="3" className="font-weight-bold">
              Actual Production
            </td>
            <td colSpan="3" className="font-weight-bold">
              Normalised Scenarios
            </td>
          </tr>
          <tr>
            <td>Ore Production (Tonnes)</td>
            <td>968,080</td>
            <td>930,472</td>
            <td>930,613</td>
            <td>1,000,000</td>
            <td>1,500,000</td>
            <td>2,000,000</td>
          </tr>
          <tr height="29" className="seperator-row">
            <td colSpan="7">
              <p className="font-weight-bold mt-3 mb-0">Zinc</p>
            </td>
          </tr>
          <tr>
            <td>Recovery rate (%)</td>
            <td>4.5%</td>
            <td>4.0%</td>
            <td>4.0%</td>
            <td>4.0%</td>
            <td>4.0%</td>
            <td>4.0%</td>
          </tr>
          <tr>
            <td>Zinc metal in concentrate production (Tonnes)</td>
            <td>43,403</td>
            <td>37,112</td>
            <td>37,413</td>
            <td>40,000</td>
            <td>60,000</td>
            <td>80,000</td>
          </tr>

          <tr>
            <td>Zinc&nbsp;metal in concentrate sale (Tonnes)</td>
            <td>43,342</td>
            <td>36,672</td>
            <td>37,811</td>
            <td>40,000</td>
            <td>60,000</td>
            <td>80,000</td>
          </tr>
          <tr>
            <td>LME Zinc Price (USD/Tonne)</td>
            <td>2,799</td>
            <td>2,793</td>
            <td>2,500</td>
            <td>2,230</td>
            <td>2,230</td>
            <td>2,230</td>
          </tr>
          <tr>
            <td>Zinc Smelter Disount</td>
            <td>15%</td>
            <td>15%</td>
            <td>15%</td>
            <td>15%</td>
            <td>15%</td>
            <td>15%</td>
          </tr>
          <tr>
            <td>Smelter disount (85%)</td>
            <td>2379.15</td>
            <td>2,374.05</td>
            <td>2,125.00</td>
            <td>1,896</td>
            <td>1,896</td>
            <td>1,896</td>
          </tr>
          <tr>
            <td>Zinc Smelter Treatment Charge</td>
            <td>74</td>
            <td>225</td>
            <td>250</td>
            <td>250</td>
            <td>250</td>
            <td>250</td>
          </tr>
          <tr>
            <td>Price Realised</td>
            <td>2,305</td>
            <td>2,149</td>
            <td>1,471</td>
            <td>1,646</td>
            <td>1,646</td>
            <td>1,646</td>
          </tr>
          <tr className="seperator-row">
            <td>Zinc sales (USD m)</td>
            <td>100</td>
            <td>79</td>
            <td>56</td>
            <td>66</td>
            <td>99</td>
            <td>132</td>
          </tr>
          <tr className="seperator-row">
            <td colSpan="7">
              <p className="font-weight-bold mt-3 mb-0">Gold</p>
            </td>
          </tr>
          <tr>
            <td>Recovery rate (Ounce/Tonne)</td>
            <td>0.021</td>
            <td>0.017</td>
            <td>0.019</td>
            <td>0.02</td>
            <td>0.02</td>
            <td>0.02</td>
          </tr>
          <tr>
            <td>Gold metal in concentrate production (Ounces)</td>
            <td>20,489</td>
            <td>16,230</td>
            <td>17,768</td>
            <td>20,000</td>
            <td>30,000</td>
            <td>40,000</td>
          </tr>
          {/* <tr>
            <td>oz per ton (grade)</td>
            <td>0.02</td>
            <td>0.02</td>
            <td>0.02</td>
            <td>0.02</td>
            <td>0.02</td>
            <td>0.02</td>
          </tr> */}
          <tr>
            <td>Gold metal in concentrate sale (Ounces)</td>
            <td>20,489</td>
            <td>16,206</td>
            <td>17,712</td>
            <td>20,000</td>
            <td>30,000</td>
            <td>40,000</td>
          </tr>
          <tr>
            <td>LME Gold Price (USD/Ounce)</td>
            <td>1258</td>
            <td>1270</td>
            <td>1420</td>
            <td>1,250</td>
            <td>1,250</td>
            <td>1,250</td>
          </tr>
          <tr>
            <td>Price Realisation Discount %</td>
            <td>6.0%</td>
            <td>7.6%</td>
            <td>7.2%</td>
            <td>7%</td>
            <td>7%</td>
            <td>7%</td>
          </tr>
          <tr>
            <td>Price Realised</td>
            <td>1183</td>
            <td>1173</td>
            <td>1318</td>
            <td>1,165</td>
            <td>1,165</td>
            <td>1,165</td>
          </tr>
          {/* <tr>
            <td>Gap</td>
            <td>75</td>
            <td>97</td>
            <td>102</td>
            <td>85</td>
            <td>85</td>
            <td>85</td>
          </tr> */}
          <tr className="seperator-row">
            <td>Gold sales (USD m)</td>
            <td>24.2</td>
            <td>19.0</td>
            <td>23.3</td>
            <td>23</td>
            <td>35</td>
            <td>47</td>
          </tr>

          <tr className="seperator-row">
            <td colSpan="7">
              <p className="font-weight-bold mt-3 mb-0">Silver</p>
            </td>
          </tr>
          <tr>
            <td>Recovery rate (Ounce/Tonne)</td>
            <td>0.41</td>
            <td>0.30</td>
            <td>0.37</td>
            <td>0.35</td>
            <td>0.35</td>
            <td>0.35</td>
          </tr>
          <tr>
            <td>Silver metal in concentrate production (Ounces)</td>
            <td>394,117</td>
            <td>280,712</td>
            <td>344,228</td>
            <td>350,000</td>
            <td>525,000</td>
            <td>700,000</td>
          </tr>
          <tr>
            <td>Silver metal in concentrate sale (Ounces)</td>
            <td>310,611</td>
            <td>279,632</td>
            <td>333,093</td>
            <td>350,000</td>
            <td>525,000</td>
            <td>700,000</td>
          </tr>
          <tr>
            <td>LME Silver Price (USD/Ounce)</td>
            <td>17.07</td>
            <td>15.81</td>
            <td>17.00</td>
            <td>17</td>
            <td>17</td>
            <td>17</td>
          </tr>
          <tr>
            <td>Price Realisation Discount %</td>
            <td>21%</td>
            <td>20%</td>
            <td>19%</td>
            <td>20%</td>
            <td>20%</td>
            <td>20%</td>
          </tr>
          <tr>
            <td>Price Realised</td>
            <td>13.50</td>
            <td>12.60</td>
            <td>13.80</td>
            <td>14</td>
            <td>14</td>
            <td>14</td>
          </tr>
          {/* <tr>
            <td>Gap</td>
            <td>3.57</td>
            <td>3.21</td>
            <td>3.20</td>
          </tr> */}
          <tr className="seperator-row">
            <td>Silver sales (USD m)</td>
            <td>4.2</td>
            <td>3.5</td>
            <td>4.6</td>
            <td>4.8</td>
            <td>7.1</td>
            <td>9.5</td>
          </tr>
          <tr className="seperator-row">
            <td colSpan="7">
              <p className="font-weight-bold mt-3 mb-0">Lead</p>
            </td>
          </tr>
          <tr>
            <td>Recovery rate (%)</td>
            <td>0.15%</td>
            <td>0.11%</td>
            <td>0.13%</td>
            <td>0.12%</td>
            <td>0.1%</td>
            <td>0.1%</td>
          </tr>
          <tr>
            <td>Lead&nbsp;metal in concentrate production (Tonnes)</td>
            <td>1,421</td>
            <td>1,030</td>
            <td>1,219</td>
            <td>1,200</td>
            <td>1,800</td>
            <td>2,400</td>
          </tr>
          <tr>
            <td>Lead&nbsp;metal in concentrate sale (Tonnes)</td>
            <td>1,420</td>
            <td>1,027</td>
            <td>1,221</td>
            <td>1,200</td>
            <td>1,800</td>
            <td>2,400</td>
          </tr>
          <tr>
            <td>LME Lead Price (USD/Tonne)</td>
            <td>2,320</td>
            <td>2,264</td>
            <td>1,600</td>
            <td>1,600</td>
            <td>1,600</td>
            <td>1,600</td>
          </tr>
          <tr>
            <td>Price Realisation Discount %</td>
            <td>3.4%</td>
            <td>0.6%</td>
            <td>1.6%</td>
            <td>3%</td>
            <td>3%</td>
            <td>3%</td>
          </tr>
          <tr>
            <td>Price Realised</td>
            <td>2,242</td>
            <td>2,250</td>
            <td>1,575</td>
            <td>1,552</td>
            <td>1,552</td>
            <td>1,552</td>
          </tr>
          <tr className="seperator-xtra">
            <td>Lead sales (USD m)</td>
            <td>3.2</td>
            <td>2.3</td>
            <td>1.9</td>
            <td>1.9</td>
            <td>2.8</td>
            <td>3.7</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
