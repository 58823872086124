import React from 'react';
import downloadNormal from "../../assets/report-static-assets/download.svg";
import downloadWhite from "../../assets/report-static-assets/download-white.svg";

function DownloadButton(props){
  const white = props.white;
  return (
      <a className={`btn btn-outline-${white?'light':'primary'}`} href={`${process.env.PUBLIC_URL}/${props.fileName}`}
        target="_blank" rel="noopener noreferrer" download>
        Download<img src={white?downloadWhite:downloadNormal} alt="Download report" className="ml-2" />
      </a>
  )
}

export default DownloadButton;