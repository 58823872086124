import React from "react";
import { HashLink } from "react-router-hash-link";
import DownloadButton from "../../download-button/dowload-button";
import TableOfContentsAccordian from "./table-of-content-accordian";
import './table-of-contents.scss'
function TableOfContents(props) {
  const { sticky = true } = props;
  const stickyClassName = `position-sticky shadow rounded`;
  return (
    <TableOfContentsAccordian sticky={sticky}>
      <ol className={`flex-column table-of-contents ${sticky ? stickyClassName : ""} pb-3`}
        style={{ top: 10 }}>
        {sticky && <h4 className="toc-header">Table of Contents</h4>}
        <li className="nav-item">
          <a className=" nav-link active" href="#" onClick={()=>window.scrollTo(0,0)}>
            Introduction
          </a>
        </li>
        <li className="nav-item">
          <HashLink className="nav-link active" to="#stock-price-performance" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Stock Price Performance
          </HashLink>
        </li>
        <li className="nav-item">
          <HashLink className="nav-link" to="#executive-summary" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Executive Summary
          </HashLink>
        </li>
        <li className="nav-item">
          <HashLink className="nav-link" to="#sector-brief" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Sector Brief
          </HashLink>
        </li>

        <li className="nav-item">
          <HashLink className="nav-link" to="#company-description" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Company Description
          </HashLink>
        </li>
        <li className="nav-item">
          <HashLink className="nav-link" to="#board-and-management" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Board & Management
          </HashLink>
        </li>
        <li className="nav-item">
          <HashLink className="nav-link" to="#management-strategy" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Management Strategy
          </HashLink>
        </li>
        <li className="nav-item">
          <HashLink className="nav-link" to="#financial-analysis" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Financial Analysis
          </HashLink>
        </li>
        <li className="nav-item">
          <HashLink className="nav-link" to="#shareholders" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Shareholders
          </HashLink>
        </li>
        <li className="nav-item">
          <HashLink className="nav-link" to="#valuation" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Valuation
          </HashLink>
        </li>
        <li className="nav-item">
          <HashLink className="nav-link" to="#potential-catalysts" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Potential Catalysts
          </HashLink>
        </li>
        <li className="nav-item">
          <HashLink className="nav-link" to="#risks-and-concerns" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Risks and Concerns
          </HashLink>
        </li>
        <li className="nav-item">
          <HashLink className="nav-link" to="#recent-developments" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Recent Developments
          </HashLink>
        </li>
        <li className="nav-item">
          <HashLink className="nav-link" to="#company-timeline" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Company Timeline
          </HashLink>
        </li>
        <li className="nav-item">
          <HashLink className="nav-link" to="#resources-and-production" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Resources and Production
          </HashLink>
        </li>
        <li className="nav-item">
          <HashLink className="nav-link" to="#key-financials" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Key Financials
          </HashLink>
        </li>
        <li className="nav-item">
          <HashLink className="nav-link" to="#disclaimer" scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
            Disclaimer
          </HashLink>
        </li>
        <div className="pt-4"><DownloadButton white={false} fileName="Griffin_Mining_-_Eureka_Initiaition_Report_-_Sep_2020.pdf" /></div>      
      </ol>
    </TableOfContentsAccordian>
  );
}

export default TableOfContents;
