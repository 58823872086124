import React from "react";
import "./section-five.scss";
import { useTranslation } from "react-i18next";
function SectionFive(props) {
  const {t} = useTranslation();
  return (
    <div className="about-us-section-five bg-white d-flex flex-column text-center apply-padding text-white animate__animated animate__fadeIn animate__delay-1s">
      <h4>
      {t('about-us.we-strive-to-level')}
      </h4>
    </div>
  );
}

export default SectionFive;
