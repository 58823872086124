import React, { useEffect, useState } from "react";

function ReportKeyInfoTable(props) {
  const [tableData , setTableData] = useState({});

  useEffect(() => {
    setTableData(props.tableData);
  }, [props]);

  return (
    <>
      <table className="table table-sm table-borderless intro-table">
        <thead className="shadow-sm">
          <tr>
            {
              tableData.TableHeader &&
              tableData.TableHeader.split('|').map((item, index) => (
                <th key={"th"+index} scope="col" className={index === 0 ? "col-6": "col"}>{ item }</th>
              ))
            }
          </tr>
        </thead>
        <tbody>
            {
              tableData.TableBody && tableData.TableBody.length > 0 &&
              tableData.TableBody.map((bodyItem, bodyItemIndex) => (
                <tr key={"bitr"+bodyItemIndex}>
                  {
                    bodyItem.Item.split('|').map((item, index) => (
                      index === 0 ? (tableData.HasLinkRowOnly == true ? <td className="text-right col"><a href={item} target="_blank">{item}</a></td> 
                                      : <th scope="row" className="text-left col-6" key={bodyItemIndex+"bit"+index}>{item}</th>)
                        : <td className="text-right col" key={bodyItemIndex+"bit"+index}>{item}</td>
                    ))
                  }
                </tr>
              ))
            }            
        </tbody>
      </table>
      <div className="col-12 pr-0">
      {
        tableData.TableFooter && tableData.TableFooter.length > 0 &&
        <p className="font-italic font-weight-lighter small text-right">{tableData.TableFooter}</p>
      }
      </div>
    </>
  );
}

export default ReportKeyInfoTable;