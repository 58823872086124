import React from "react";

function DetailedTable(props) {
  return (    
    <div className="container-grey p-2 seperator-top mt-4">
      <table className="table table-sm custom-tables table-striped table-responsive mt-4">
        {props && props.tableData && props.tableData.TableFooter && <caption>{props.tableData.TableFooter}</caption>}
        <thead>
          <tr>
            {
              props && props.tableData && props.tableData.TableHeader && 
              props.tableData.TableHeader.split('|').map(item => (
                <th>{item}</th>
              ))
            }
          </tr>
        </thead>
        <tbody>
        <tr>
          {
            props && props.tableData && props.tableData.TableHeader && props.tableData.TableTitle &&
            <td colSpan={props.tableData.TableHeader.split('|').length} className="seperator-xtra">
              <h4>{props.tableData.TableTitle}</h4>
            </td>
          }
        </tr>
        {
          props && props.tableData && props.tableData.TableBody && 
          props.tableData.TableBody.map((bodyItem, bodyItemIndex) => (
            <tr className={bodyItemIndex === props.tableData.TableBody.length - 1 ? 'seperator-row' : ''}>
              {
                bodyItem.Item.split('|').map((itemData, itemDataIndex) => (
                  itemDataIndex === 0 ? <th>{itemData}</th> : <td>{itemData}</td>
                ))
              }
            </tr>
          ))
        }
        </tbody>
      </table>
    </div>
  );
}

export default DetailedTable;