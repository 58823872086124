import React from "react";
import tokyo from "../../../assets/images/tokyo.jpg";
import london from "../../../assets/images/london.jpg";
import { useTranslation } from "react-i18next";
import "./address.scss";
function Address(props) {
  const { t } = useTranslation();
  return (
    <div className="container-fluid mb-5 address animate__animated animate__fadeIn animate__delay-1s">
      {/* <h3 className="text-left">{t("contact-us.offices")}</h3> */}
      <div className="row">
        <div className="col-md-4 my-5">
          <h4 className="text-left">{t("contact-us.tokyo")}</h4>
          <div className="card shadow-sm rounded-0 h-100">
            <img src={tokyo} className="card-img-top" alt="..." />
            <div className="card-body text-left">
              <p className="card-text">{t("contact-us.tokyo-address")}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 my-5">
        <h4 className="text-left">London</h4>
          <div className="card shadow-sm rounded-0 h-100">
            <img src={london} className="card-img-top" alt="..." />
            <div className="card-body text-left">
              <p className="card-text">
                71-75, Shelton Street, Covent Garden, London, United Kingdom,
                WC2H 9JQ
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Address;
