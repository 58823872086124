import React from "react";
import { useTranslation } from "react-i18next";

function LanguageSelector(props) {
  const { className = "", onChange } = props;
  const { i18n } = useTranslation();
  const changeLanguage = (lang) => {
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
      onChange(lang);
    }
  };
  return (
    <div
      className={`btn-group ${className}`}
      role="group"
      aria-label="Basic example"
    >
      <button
        type="button"
        className={`btn btn-link p-1 ${i18n.language !== "ja" ? "active" : ""}`}
        onClick={() => changeLanguage("en")}
      >
        EN
      </button>
      <button
        type="button"
        className={`btn btn-link p-1 ${i18n.language === "ja" ? "active" : ""}`}
        onClick={() => changeLanguage("ja")}
      >
        日
      </button>
    </div>
  );
}

export default LanguageSelector;
