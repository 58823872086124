import React from "react";
import { NavLink } from "react-router-dom";
import "./footer.scss";
import linkedInIcon from "../../assets/images/icons/linkedin.svg";
import twitterIcon from "../../assets/images/icons/twitter.svg";
import youtubeIcon from "../../assets/images/icons/youtube.svg";
import gmailIcon from "../../assets/images/icons/gmail.svg";
import logo from "../../assets/images/white-logo.svg";
import { useTranslation } from "react-i18next";
function Footer(props) {
  const { t, i18n } = useTranslation();
  return (
    <div className="footer-section text-white apply-padding text-justify d-flex flex-column">
      <div className="d-flex my-4">
        <img src={logo} alt="logo" />
      </div>
      <div className="row">
        <div className="col-md-8 d-flex flex-column flex-lg-row">
          <NavLink
            className="mb-3 mr-3 text-reset"
            to="/"
            activeClassName="active"
            exact
          >
            {t("home-nav")}
          </NavLink>
          <NavLink
            className="mb-3 mr-3 text-reset"
            to="/about-us"
            activeClassName="active"
          >
            {t("about-us-nav")}
          </NavLink>
          <NavLink
            className="mb-3 mr-3 text-reset"
            to="/our-services"
            activeClassName="active"
          >
            {t("our-services-nav")}
          </NavLink>
          <div className="mb-3 mr-3 dropdown">
            <div
              className="dropdown-toggle"
              id="navbarDropdownMenuCoverage"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              activeClassName="active">{t("coverage-nav")}
            </div>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuCoverage">
              <div className="dropdown-header font-weight-bold">{t("coverage-nav-japan")}</div>
              <NavLink className="dropdown-item" to="/company-profile/6237-iwaki-pumps" activeClassName="active">6237 - Iwaki Pumps</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/3836-avant-corporation" activeClassName="active">3836 - Avant Corporation</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/3252-jinushi" activeClassName="active">3252 - Jinushi Co.,Ltd.</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/4478-freee" activeClassName="active">4478 - freee K.K.</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/6235-optorun" activeClassName="active">6235 - Optorun Co.,Ltd.</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/5999-ihara" activeClassName="active">5999 - Ihara Science Corporation</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/4369-tri-chemical" activeClassName="active">4369 - Tri Chemical Laboratories Inc.</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/4326-intage" activeClassName="active">4326 - Intage Holdings Inc.</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/2492-infomart" activeClassName="active">2492 - Infomart Corporation</NavLink>
              <div className="dropdown-divider"></div>
              <div className="dropdown-header font-weight-bold">{t("coverage-nav-india")}</div>
              <NavLink className="dropdown-item" to="/company-profile/531213-manappuram" activeClassName="active">531213 - Manappuram Finance Limited</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/543254-antony-waste-handling" activeClassName="active">543254 - Antony Waste Handling Cell Ltd.</NavLink>
            </div>
          </div>
          <div className="mb-3 mr-3 dropdown">
            <div
              className="dropdown-toggle"
              id="navbarDropdownMenuLink"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">{t("reports-nav")}
            </div>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <div className="dropdown-header font-weight-bold">{t("sample-reports-nav")}</div>
              <NavLink className="dropdown-item" to="/company-report/6237-iwaki-pumps" activeClassName="active">6237 - Iwaki Pumps</NavLink>
              <NavLink className="dropdown-item" to="/Griffin_Mining_Eureka_Initiation_Report_25_Sep_2020" activeClassName="active">GFM - Griffin Mining</NavLink>
            </div>
          </div>
          <NavLink
            className="mb-3 mr-3 text-reset"
            to="/contact-us"
            activeClassName="active"
          >
            {t("contact-us-nav")}
          </NavLink>
        </div>
        <div className="col-md-4 d-flex flex-column justify-content-end">
          <div className="address">
            <h6>{t("footer.address-header")}</h6>
            <p>{t("footer.address")}</p>
          </div>
          <div className="d-flex justify-content-start mb-4 icon-section">
            <a
              className="icon"
              href="https://www.linkedin.com/company/eightfold-research/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedInIcon} alt="linkedin" />
            </a>
            <a
              className="icon"
              href="https://twitter.com/8FoldResearch"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitterIcon} alt="twitter" />
            </a>
            <a
              className="icon"
              href="mailto:info@eightfoldresearch.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={gmailIcon} alt="email" />
            </a>
            <a
              className="icon"
              href="https://www.youtube.com/channel/UC-Ff6sutLKnF2df-ssrMXXQ/featured"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtubeIcon} alt="youtube" />
            </a>
          </div>
        </div>
      </div>

      <div className="d-flex mb-1 flex-column flex-md-row tnc">
        <a className="mr-2 my-1 font-weight-bold text-reset" href="/terms-and-conditions">
          {t("footer.terms-and-condition")}
        </a>
        <a className="my-1 font-weight-bold text-reset" href="/privacy-and-cookies">
          {t("footer.privacy-and-cookies")}
        </a>
      </div>
      {i18n.language === "ja" && (
        <p className="d-block mb-0 mt-2 font-weight-bold">
          {t("footer.disclaimer")}
        </p>
      )}
      <p className="d-block border-bottom pb-3 mb-0 disclaimer">
        {t("footer.disclaimer-text")}
      </p>
      <p className="d-block text-center mt-3 mb-0 copyright">
        © 2020 Eightfold Research Limited. All rights reserved.
      </p>
    </div>
  );
}

export default Footer;
