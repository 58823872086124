import React from "react";
import { useQuery } from "react-query";
import Api from "../../services/api";
import AddDatapoints from "./add-datapoints";
import moment from "moment";
import Loader from "../../components/loader/loader";
function Datapoints(props) {
  const { id } = props;
  const { isLoading, isError, data, error } = useQuery(
    ["single-company-datapoints", id],
    () => Api.fetchDatapoints(id)
  );

  if (isLoading) {
    return (
      <div className="container-fluid h-100 d-flex flex-column justify-content-center">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <AddDatapoints id={id} />
      {data && data.length > 0 && (
        <div style={{ maxHeight: 500, overflowY: "scroll" }}>
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Date</th>
                <th scope="col">Close</th>
                <th scope="col">Volume</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d, key) => (
                <tr key={key}>
                  <th scope="row">{key + 1}</th>
                  <td className="text-justify">
                    {moment(d.date).format("DD/MM/YYYY")}
                  </td>
                  <td>{d.close}</td>
                  <td>{d.volume}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default Datapoints;
