import React from 'react';
import Hero from '../../components/terms-and-conditions/hero/hero';
import Contennt from '../../components/terms-and-conditions/content/content';

function TermsAndConditions(props) {
    return <>
        <Hero/>
        <Contennt/>
    </>
}

export default TermsAndConditions