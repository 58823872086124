import React from "react";
import "./our-services-hero.scss";
import arrowLeft from "../../../assets/images/arrow-left.svg";
import { useTranslation } from "react-i18next";
function OurServicesHero(props) {
  const {t} = useTranslation();
  return (
    <div className="our-services-hero d-flex align-items-center position-relative overflow-hidden animate__animated animate__fadeIn animate__delay-1s">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-8 text-white text-left">
            <h2>{t('our-services.banner-text')}</h2>
            <p>{t('our-services.banner-subtitle')}</p>
          </div>
        </div>
      </div>
      <img src={arrowLeft} className="left-arrow d-none d-sm-block position-absolute" alt="styling arrow right" style={{right:0}}/>
    </div>
  );
}

export default OurServicesHero;
