import React from "react";
import "./section-four.scss";
import { useTranslation } from "react-i18next";
function SectionFour(props) {
  const { t , i18n} = useTranslation();
  const language = i18n.language;
  return (
    <div className="about-us-section-four bg-white d-flex flex-column apply-padding animate__animated animate__fadeIn animate__delay-1s">
      <h3 className="text-md-center text-left mb-4">
        {t("about-us.our-mission")}
      </h3>
      {language!=='ja' && <p className="text-left">{t("about-us.our-misson-text.en")}</p>}
      {language==='ja' && <p className="text-left">{t("about-us.our-misson-text.jp-1")}</p>}
      {language==='ja' && <p className="text-left">{t("about-us.our-misson-text.jp-2")}</p>}
      {language==='ja' && <p className="text-left">{t("about-us.our-misson-text.jp-3")}</p>}
    </div>
  );
}

export default SectionFour;
