import React from 'react';
import Hero from '../../components/contact-us/hero/hero';
import Address from '../../components/contact-us/address/address';
import Form from '../../components/contact-us/form/form';

function ContactUs(props) {
    return <>
        <Hero/>
        <Address/>
        <Form/>
    </>
}

export default ContactUs