import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import "./navbar.scss";
import LanguageSelector from "./language-selector";
import { useTranslation } from "react-i18next";
function Navbar(props) {
  let [navbarOpen, setNavbarOpen] = useState(false);
  const {onLanguageChange} = props;
  const { t } = useTranslation();
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white animate__animated animate__fadeIn animate__delay-1s">
      <LanguageSelector className="d-sm-block d-md-none" />
      <NavLink className="navbar-brand" to="/" activeClassName="active">
        <img src={logo} alt="logo" />
      </NavLink>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        <span
          className={`navbar-toggler-icon ${navbarOpen ? "close-icon" : ""}`}
        ></span>
      </button>
      <div
        className="collapse navbar-collapse justify-content-end text-left"
        id="navbarNav"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <NavLink className="nav-link" to="/" activeClassName="active" exact>
              {t("home-nav")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="/about-us"
              activeClassName="active"
            >
              {t("about-us-nav")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="/our-services"
              activeClassName="active"
            >
              {t("our-services-nav")}
            </NavLink>
          </li>
          <li className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              id="navbarDropdownMenuCoverage"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              activeClassName="active">{t("coverage-nav")}
            </div>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuCoverage">
              <div className="dropdown-header font-weight-bold">{t("coverage-nav-japan")}</div>
              <NavLink className="dropdown-item" to="/company-profile/6237-iwaki-pumps"
                activeClassName="active">6237 - Iwaki Pumps</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/3836-avant-corporation"
                activeClassName="active">3836 - Avant Corporation</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/3252-jinushi"
                activeClassName="active">3252 - Jinushi Co.,Ltd.</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/4478-freee"
                activeClassName="active">4478 - freee K.K.</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/6235-optorun"
                activeClassName="active">6235 - Optorun Co.,Ltd.</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/5999-ihara"
                activeClassName="active">5999 - Ihara Science Corporation</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/4369-tri-chemical"
                activeClassName="active">4369 - Tri Chemical Laboratories Inc.</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/4326-intage"
                activeClassName="active">4326 - Intage Holdings Inc.</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/2492-infomart"
                activeClassName="active">2492 - Infomart Corporation</NavLink>
              <div className="dropdown-divider"></div>
              <div className="dropdown-header font-weight-bold">{t("coverage-nav-india")}</div>
              <NavLink className="dropdown-item" to="/company-profile/531213-manappuram"
                activeClassName="active">531213 - Manappuram Finance Limited</NavLink>
              <NavLink className="dropdown-item" to="/company-profile/543254-antony-waste-handling"
                activeClassName="active">543254 - Antony Waste Handling Cell Ltd.</NavLink>
            </div>
          </li>
          <li className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              id="navbarDropdownMenuLink"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">{t("reports-nav")}
            </div>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <div className="dropdown-header font-weight-bold">{t("sample-reports-nav")}</div>
              <NavLink className="dropdown-item" to="/company-report/6237-iwaki-pumps" activeClassName="active">6237 - Iwaki Pumps</NavLink>
              <NavLink className="dropdown-item" to="/Griffin_Mining_Eureka_Initiation_Report_25_Sep_2020"
                activeClassName="active">GFM - Griffin Mining</NavLink>
            </div>
          </li>
          <li className="nav-item ">
            <NavLink
              className="nav-link"
              to="/contact-us"
              activeClassName="active"
              aria-disabled="true"
            >
              {t("contact-us-nav")}
            </NavLink>
          </li>
          {/* <li className="nav-item pl-2 pt-1">
            <button className="btn btn-warning btn-sm" type="submit"
              id="button-clientlogin">Client Login</button>
          </li> */}
          <li className="nav-item nav-language-selector">
            <LanguageSelector className="d-none d-sm-block" onChange={(event)=>onLanguageChange(event)}/>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
