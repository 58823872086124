import React, { useEffect } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  Link,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Api from "../../services/api";
import ListCompanies from "../company-data/list-companies";
import CompanyDatapoints from "../company-datapoints/company-datapoints";
import "./index.scss";
import Loader from "../../components/loader/loader";
export function Admin(props) {
  let { path, url } = useRouteMatch();
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
  } = useAuth0();
  let history = useHistory();
  if (!isAuthenticated && !isLoading) {
    localStorage.setItem("redirectURL", history.location.pathname);
    loginWithRedirect();
  }

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        audience: "https://eightfold-data",
      }).then((token) => {
        Api.setToken(token);
        const redirect = localStorage.getItem("redirectURL");
        history.push(redirect);
      });
    }
  }, [isAuthenticated]);
  return (
    <Switch>
      <Route path={`${url}/companies`}>
        <ListCompanies />
      </Route>
      <Route path={`${url}/company/:id`}>
        <CompanyDatapoints />
      </Route>
      <Route exact path={path}>
        <div className="h-100 d-flex flex-column justify-content-center align-items-start admin-intro">
          {isAuthenticated && (
            <>
              <h1 className="display-3">
                Welcome <span className="name">{user.name}</span>
              </h1>
              <Link className="btn btn-primary my-3" to={`${url}/companies`}>
                View Companies
              </Link>
            </>
          )}

          {!isAuthenticated && (
            <>
              {" "}
              <h1 className="display-3 d-flex">
                <span className="name mr-3">Logging you in</span>
                <Loader />
              </h1>
            </>
          )}
        </div>
      </Route>
    </Switch>
  );
}
