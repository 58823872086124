import React from "react";
import { useTranslation } from "react-i18next";

function Form(props) {
  const { t } = useTranslation();
  return (
    <div className="container-fluid my-5 text-left animate__animated animate__fadeIn animate__delay-1s">
      <h3 className="my-3">{t("contact-us.form.contact-us-directly")}</h3>
      <form
        name="signup"
        method="POST"
        data-netlify="true"
        className="col-md-8 px-0"
      >
        <input type="hidden" name="form-name" value="signup" />
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="fullname">{t("contact-us.form.fullname")}</label>
              <input
                type="text"
                className="form-control"
                id="fullname"
                name="fullname"
                placeholder={t("contact-us.form.fullname")}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label htmlFor="companyName">
                {t("contact-us.form.company-name")}
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                placeholder={t("contact-us.form.company-name")}
                name="companyName"
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="email">{t("contact-us.form.email")}</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder={t("contact-us.form.email")}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">{t("contact-us.form.message")}</label>
          <textarea
            className="form-control"
            id="message"
            placeholder={t("contact-us.form.message")}
            required
            name="message"
          ></textarea>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="tncCheckbox"
          />
          <label className="form-check-label" htmlFor="tncCheckbox">
            {t("contact-us.form.tnc")}
          </label>
        </div>
        <button className="btn btn-primary my-3" type="submit">
          {t("contact-us.form.submit")}
        </button>
      </form>
    </div>
  );
}

export default Form;
