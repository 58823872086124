import React from "react";
import "./section-eight.scss";
import arrow from "../../../assets/images/about-us-section-eight-arrow.svg";
import { useTranslation } from "react-i18next";
function SectionEight(props) {
  const {t} = useTranslation();
  return (
    <div className="about-us-section-eight bg-white d-flex flex-column text-center apply-padding justify-content-center position-relative overflow-hidden mt-5 animate__animated animate__fadeIn animate__delay-1s">
      <div className="container-fluid">
        <h4 style={{ zIndex: 2 }}>
          {t('about-us.we-carry-out-peer-and-market')}
        </h4>
      </div>
      <img
        src={arrow}
        alt="styling arrow"
        className="position-absolute d-none d-sm-block"
        style={{ right: 0, top: "40%", zIndex: 0 }}
      />
    </div>
  );
}

export default SectionEight;
