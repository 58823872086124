import React from "react";
import Hero from "../../components/homepage/hero/hero";
import HomepageInfo from "../../components/homepage/info/info";
import HomepageServices from "../../components/homepage/our-services/our-services";
import HomepageAboutUs from "../../components/homepage/about-us/about-us";
import HomepageSubscribe from "../../components/homepage/subscribe/subscribe";
function Home(props) {
  return (
    <>
      <Hero />
      <HomepageInfo />
      <HomepageServices />
      <HomepageAboutUs />
      <HomepageSubscribe />
    </>
  );
}

export default Home;
