import React from "react";

function ImageHalfWidth(props) {
  return (<>
    { props && props.imageContent && props.imageContent.ImageTitle && <h5 className="font-weight-bold">{props.imageContent.ImageTitle}</h5>}
    <img className={props && props.imageContent && props.imageContent.ImageFooter ? "img-fluid mb-2 image-half-width" : "img-fluid mb-4 image-half-width"} alt="Report Image" src={`${process.env.PUBLIC_URL}/company-report-data/${props.imageContent.Src}`} />
    { props && props.imageContent && props.imageContent.ImageFooter && <div className="small mb-4">{props.imageContent.ImageFooter}</div>}
  </>);
}

export default ImageHalfWidth;