import React from "react";
import "./hero.scss";
import arrow from "../../../assets/images/about-us-hero-arrow.svg";
import { useTranslation } from "react-i18next";
function Hero(props) {
  const { t } = useTranslation();
  return (
    <div className="about-us-hero d-flex flex-column position-relative overflow-hidden animate__animated animate__fadeIn animate__delay-1s">
      <div className="container my-auto">
        <div className="row">
          <div className="col-md-8 text-white text-left">
            <h1>{t("about-us.banner-text")}</h1>
            <p>{t("about-us.banner-subtitle")}</p>
          </div>

          <img
            src={arrow}
            alt="hero arrow "
            className="position-absolute d-none d-sm-block"
            style={{ right: 0, top: 0 }}
          />
        </div>
      </div>
    </div>
  );
}

export default Hero;
