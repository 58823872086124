import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import api from "../../services/api";

function AddDatapoints(props) {
  const { id } = props;
  let [csv, setCSV] = useState(null);
  const queryClient = useQueryClient();
  const mutation = useMutation((formData) => api.createDatapoints(formData), {
    onSuccess: () => {
      queryClient.invalidateQueries('single-company-datapoints')
    },
  });
  const submit = () => {
    let formData = new FormData();
    formData.append("csv", csv[0]);
    formData.append("id", id);
    mutation.mutate(formData);
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-primary my-4"
        data-toggle="modal"
        data-target={`#companyDatapoints-${id}`}
      >
        Add Datapoints
      </button>

      <div
        className="modal fade"
        id={`companyDatapoints-${id}`}
        tabIndex="-1"
        aria-labelledby="companyDatapointsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="companyDatapointsLabel">
                Upload datapoint
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setCSV(null)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="csv"
                  onChange={(e) => setCSV(e.target.files)}
                />
                <label className="custom-file-label" htmlFor="csv">
                  {csv ? csv[0].name : "Choose file"}
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => setCSV(null)}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => submit()}
                data-dismiss="modal"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddDatapoints;
