import React from "react";

function UnorderedList(props) {
  return (
    <ul>
    {
        props.listData.map((item, index) => {
            return <li className="text-dark pb-2" key={index}>{item.Item}</li>
        })
    }
    </ul>
  );
}

export default UnorderedList;