import React, { useState } from "react";
import { useMutation, useQueryClient, useQuery } from "react-query";
import Api from "../../services/api";
function AddCompany(props) {
  let [name, setName] = useState("");
  let [ticker, setTicker] = useState("");
  let [exchange, setExchange] = useState(-1);

  const { data } = useQuery("exchanges", Api.getAllExchanges);

  const queryClient = useQueryClient();
  const mutation = useMutation((newCompany) => Api.createCompany(newCompany), {
    onSuccess: () => {
      queryClient.invalidateQueries("companies");
    },
  });

  const onCancel = () => {
    setExchange(-1)
    setName("")
    setTicker("")
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#createCompany"
      >
        Add Company
      </button>

      <div
        className="modal fade"
        id="createCompany"
        tabIndex="-1"
        aria-labelledby="createCompanyLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="createCompanyLabel">
                Add Company
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="companyName">Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyName"
                    aria-describedby="companyNameHelp"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <small id="companyNameHelp" className="form-text text-muted">
                    Enter the name of the company eg. "Amazon Inc."
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="companyTicker">Company Ticker</label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyTicker"
                    aria-describedby="companyTickerHelp"
                    value={ticker}
                    onChange={(e) => setTicker(e.target.value)}
                  />
                  <small
                    id="companyTickerHelp"
                    className="form-text text-muted"
                  >
                    Enter the ticket of the company eg. "AMZN"
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="exchanges">Exchange</label>
                  <select
                    id="exchanges"
                    className="form-control"
                    onChange={(e) => setExchange(+e.target.value)}
                    value={exchange}
                  >
                    <option value={-1}>Select exchange</option>
                    {data &&
                      data.length > 0 &&
                      data.map(({ name, ticker, ID }) => (
                        <option key={ticker} value={ID}>
                          {name} ({ticker})
                        </option>
                      ))}
                  </select>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => mutation.mutate({ ticker, name, exchange_id: exchange })}
                data-dismiss="modal"
                disabled={!name.length || exchange === -1 || !ticker.length}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCompany;
