import React, { useRef, useEffect, useState } from "react";
import AMChartWithStockEvents from "./../../historical-data-chart/historical-data-chart";
import DownloadButton from "../../download-button/dowload-button";
import reportImage from "../../../assets/company-profile-static/report.png";
import fileIcon from "../../../assets/images/file.svg";
import googleTranslate from "../../../assets/images/g_translate_icon.svg";
import "./company-profile-body.scss";
import moment from "moment";

function CompanyProfileBody(props) {
  
  const scrollToPricingChart = useRef();
  const scrollToCompanyFilings = useRef();
  const scrollToFinancialSummary = useRef();
  const scrollToOtherInformation = useRef();
  const scrollToEfrReports = useRef();

  const [companyProfileData , setPageData] = useState(props.companyData);

  useEffect(() => {
      setPageData(props.companyData);
  }, [props]);

  let annualAndInterimData = {};
  let sortedReportData = [];
  if(companyProfileData && companyProfileData.AnnualAndInterimReportData &&
     companyProfileData.AnnualAndInterimReportData.ReportData) {
      sortedReportData = companyProfileData.AnnualAndInterimReportData.ReportData?.sort((a, b) => new moment(b.Report?.Date, "DD-MM-YYYY") > new moment(a.Report?.Date, "DD-MM-YYYY") ? 1: -1);
      annualAndInterimData = sortedReportData[0];
     }
  var formattedEmailBody = encodeURIComponent("Please provide the following details to help us reach you: \n \n Name/Ticker of company to be covered: [Name/Ticker] \n Requester name: [Name] \n Requester Email: [EmailAddress] \n Requester Designation: [Designation] \n Company: [CompanyName] \n \n");
  var bespokeReportEmailSubject = "Request for full version of " + companyProfileData?.Name + " [" + companyProfileData?.Ticker +"] bespoke research report";
  var bespokeReportEmailBody = encodeURIComponent("Please send us an email with the information below and we will be in touch with you shortly. \n \n Requester name: \n Job Title:  \n Company:  \n \n Additional Comments: \n \n");
  var sponsoredReportEmailSubject = "Request for research updates on " + companyProfileData?.Name + " [" + companyProfileData?.Ticker +"]";
  var sponsoredReportEmailBody = encodeURIComponent("Please send us an email with the information below and we will be in touch with you shortly. \n \n Requester name: \n Job Title:  \n Company:  \n \n Additional Comments: \n \n");
  var lastMarketDate = moment(companyProfileData?.lastMarketDate, 'DD-MM-YYYY').format("DD MMM YYYY");  
  var lastClosingPrice = companyProfileData?.lastClosingPrice;
  var jpyusd = companyProfileData?.jpyUsdConversionRate;

  var latestAnnualOrInterimReportDate = moment().format("DD MMM YYYY");
  var annualAndInterimDataItems = [];

  if(annualAndInterimData && annualAndInterimData.Report) {
    latestAnnualOrInterimReportDate = moment(annualAndInterimData.Report.Date, 'DD-MM-YYYY').format("DD MMM YYYY");
    annualAndInterimDataItems = annualAndInterimData.Report.DataItems;
  }
  
  if(annualAndInterimDataItems && annualAndInterimDataItems.length > 0) {
    var eps = annualAndInterimDataItems.find(x => x.Item["ID"] === "eps")?.Item?.Value;
    var bvps = annualAndInterimDataItems.find(x => x.Item["ID"] === "bvps").Item.Value;
    var tbvps = annualAndInterimDataItems.find(x => x.Item["ID"] === "tbvps").Item.Value;
    var sos = annualAndInterimDataItems.find(x => x.Item["ID"] === "sos").Item.Value;
    var ltmebitda = annualAndInterimDataItems.find(x => x.Item["ID"] === "ltmebitda").Item.Value;
    var ltmebit = annualAndInterimDataItems.find(x => x.Item["ID"] === "ltmebit").Item.Value;
    var ltmoi = annualAndInterimDataItems.find(x => x.Item["ID"] === "ltmoi").Item.Value;
    var td = annualAndInterimDataItems.find(x => x.Item["ID"] === "td").Item.Value;
    var nd = annualAndInterimDataItems.find(x => x.Item["ID"] === "nd").Item.Value;
    var minints = annualAndInterimDataItems.find(x => x.Item["ID"] === "minints").Item.Value;
    var ce = annualAndInterimDataItems.find(x => x.Item["ID"] === "ce").Item.Value;
    var roe = annualAndInterimDataItems.find(x => x.Item["ID"] === "roe").Item.Value;
    var dps = annualAndInterimDataItems.find(x => x.Item["ID"] === "dps").Item.Value;
    var dpr = annualAndInterimDataItems.find(x => x.Item["ID"] === "dpr").Item.Value;
  }

  var sumoffiveyearROE = 0;
  var fiveYearDataIndex = 0;
  if(companyProfileData && companyProfileData.AnnualAndInterimReportData &&
    companyProfileData.AnnualAndInterimReportData.ReportData) {
    (sortedReportData).forEach((data, index) => {
      if(data.Report?.Type?.toLowerCase() === "annual" && fiveYearDataIndex < 5){
        fiveYearDataIndex++;
        sumoffiveyearROE = sumoffiveyearROE + data.Report?.DataItems?.find(x => x.Item["ID"] === "roe").Item.Value;
      }
    })
  }

  var mc = lastClosingPrice * sos;
  var ev = mc + minints + nd;
  var roe5yrAvg = (sumoffiveyearROE/(companyProfileData?.AnnualAndInterimReportData?.ReportData?.length > 5 ? 5 
    : companyProfileData?.AnnualAndInterimReportData?.ReportData?.length));

  var valuation = {
    "Price/Book Value": (lastClosingPrice/bvps).toFixed(2),  
    "Price/Tangible Book Value":(lastClosingPrice/tbvps).toFixed(2),
    "EV/LTM EBITDA": ltmebitda > 0 && ev/ltmebitda > 0 ? (ev/ltmebitda).toFixed(2) : "N/M",
    "EV/LTM EBIT":  ltmebit > 0 && ev/ltmebit > 0 ? (ev/ltmebit).toFixed(2) : "N/M",
    "EV/LTM Operating Income": ev/ltmoi > 0 ? (ev/ltmoi).toFixed(2) : "N/M",
    "P/LTM E": lastClosingPrice/eps > 0 ? (lastClosingPrice/eps).toFixed(2) : "N/M",
    "ROE ( 5 Year Avg)": roe5yrAvg && roe5yrAvg > -1 ? roe5yrAvg.toFixed(2) + "%" : "N/A",
    "Dividend Yield": isNumeric(dps) ? ((dps/lastClosingPrice) * 100).toFixed(2) + "%" : "N/A",
    "Dividend Payout Ratio": dpr && dpr > 0 ? dpr?.toFixed(2) + "%" : "N/A"
  };

  var capitalStruture = {
    "Common Equity":companyProfileData?.BaseCurrency + " " + parseFloat(ce?.toFixed(2)).toLocaleString(companyProfileData.NumberFormat) +  "mn",
    "+ Minority Interest":companyProfileData?.BaseCurrency + " " + parseFloat(minints?.toFixed(2)).toLocaleString(companyProfileData.NumberFormat) +  "mn",
    "+ Total Debt":companyProfileData?.BaseCurrency + " " + parseFloat(td?.toFixed(2)).toLocaleString(companyProfileData.NumberFormat) +  "mn",
    "= Total Capital":companyProfileData?.BaseCurrency + " " + parseFloat((ce + minints + td)?.toFixed(2)).toLocaleString(companyProfileData.NumberFormat) +  "mn"};

  var marketCap = {
    "Market Cap":companyProfileData?.BaseCurrency + " "+ (parseInt(mc?.toFixed(0))).toLocaleString(companyProfileData.NumberFormat) +" mn",
    "+ Minority Interest":companyProfileData?.BaseCurrency + " " + parseFloat(minints?.toFixed(2)).toLocaleString(companyProfileData.NumberFormat) +  "mn",
    "+ Net Debt":companyProfileData?.BaseCurrency + " " + parseFloat(nd?.toFixed(2)).toLocaleString(companyProfileData.NumberFormat) +  "mn",
    "= Enterprise Value":companyProfileData?.BaseCurrency + " " + parseFloat((mc + minints + nd)?.toFixed(2)).toLocaleString(companyProfileData.NumberFormat) +  "mn"};

  return (
    <>
    <div className="company-profile-hero d-flex align-items-center position-relative overflow-hidden animate__animated animate__fadeIn animate__delay-1s">
      <div className="container company-profile-hero-text mt-auto">
        <div className="row mx-2">
          <div className="mt-3 col-md-9 pl-0">
            <span className="badge badge-pill badge-secondary px-2 py-2">{companyProfileData?.Ticker}</span>
            <span className="px-2 pt-5 font-weight-bold">{companyProfileData?.Sector} / {companyProfileData?.SubIndustry}</span>
          </div>
          <div className="mt-3 col-md-3 text-right">
            { /* <a className="badge badge-pill badge-primary px-4 py-2" href="#nav-contact" aria-controls="nav-contact" aria-selected="false" onClick={() => scrollToEfrReports.current.scrollIntoView({behavior: 'smooth'})}>Trial Coverage</a>
             <a className="badge badge-pill badge-danger px-4 py-2 ml-2" href={"mailto:info@eightfoldresearch.com?subject=Request for sponsored coverage&body=" + formattedEmailBody} aria-controls="nav-contact" aria-selected="false">Request For Coverage</a> */}
             <a className="badge badge-pill px-4 py-2" style={{cursor: 'pointer', color: 'white', backgroundColor: '#dd484c'}} onClick={() => scrollToEfrReports.current.scrollIntoView({behavior: 'smooth'})}>{companyProfileData?.EFRReports?.SectionLabel}</a>
          </div>
        </div>
        <div className="row mx-2">
          <h1 className="mt-1">{companyProfileData.Name}</h1>
        </div>
      </div>
    </div>

    <div className="container company-profile-body">
      <div className="row">
        <div className="col text-left mt-1">                        
          { 
            companyProfileData.ExchangesListedOn?.map(exchange => (
              <span className="pl-2 text-muted font-weight-bold small">{exchange}</span>
            )) 
          }
        </div>
      </div>

      <div className="row my-2 mx-2 text-left py-3 border-top-grey border-bottom-grey small">
          <div className="col">
            <div className="font-weight-bold">Recent Price</div>
            <div>&nbsp;</div>
            <div>{companyProfileData.BaseCurrency + " " + (parseFloat(companyProfileData?.lastClosingPrice?.toFixed(2)).toLocaleString(companyProfileData.NumberFormat))}</div>
          </div>
          <div className="col">
            <div className="font-weight-bold">Market Cap</div>
            <div className="font-weight-bold small">(as of {lastMarketDate})</div>
            <div>{companyProfileData.BaseCurrency + " " + (parseInt(mc.toFixed(0))).toLocaleString(companyProfileData.NumberFormat)}mn / USD {(mc*jpyusd).toFixed(2)}mn</div>
          </div>
          <div className="col">
            <div className="font-weight-bold">52 Week High/Low</div>
            <div>&nbsp;</div>
            <div>{companyProfileData.last52WeekHighLow}</div>
          </div>
          { 
            companyProfileData.ShareHoldings?.map(holding => (
              <div className="col">
                <div className="font-weight-bold">{holding.Holding.Type}</div>
                <div>&nbsp;</div>
                <div>{holding.Holding.Quantity}</div>
              </div>
            )) 
          }   
      </div>

      <div className="row mt-4 text-left">
        { 
          companyProfileData.Description?.map(para => (
            <p className="px-4 text-justify">{para}</p>
          )) 
        }
      </div>

      <div className="row px-2">
        <div className="col text-left">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a className="nav-item nav-link always-active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Basic Information</a>
            <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#pricing-chart" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => scrollToPricingChart.current.scrollIntoView({behavior: 'smooth'})}>Pricing Chart</a>
            <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={() => scrollToEfrReports.current.scrollIntoView({behavior: 'smooth'})}>{companyProfileData?.EFRReports?.SectionLabel}</a>
            <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={() => scrollToCompanyFilings.current.scrollIntoView({behavior: 'smooth'})}>Company Filings</a>
            <a className="nav-item nav-link" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="false" onClick={() => scrollToFinancialSummary.current.scrollIntoView({behavior: 'smooth'})}>Financial Summary</a>
            <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => scrollToOtherInformation.current.scrollIntoView({behavior: 'smooth'})}>Other Information</a>
          </div>
        </nav>
        </div>
      </div>
      
      <div className="row mx-2 pt-3 pb-2" id="basic-info">
        <div className="col text-left">
          <div className="font-weight-bold">VALUATION</div>
          <table className="table table-sm text-left basic-info-table mt-2">
            <tbody>
            {
              Object.keys(valuation).map((key, i) => (
                <tr key={i}>
                  <th className="text-muted">{key}</th>
                  <td className="text-right">{valuation[key]}</td>
                </tr>
              ))   
            }
            </tbody>
          </table>
        </div>
        <div className="col text-left">
          <div className="row">
            <div className="font-weight-bold">CAPITAL STRUCTURE<span className="text-muted small">&nbsp; as of {latestAnnualOrInterimReportDate}</span></div>
            <table className="table table-sm text-left basic-info-table mt-2">
              <tbody>
              {
                Object.keys(capitalStruture).map((key, i) => (
                  <tr key={i}>
                    <th className="text-muted">{key}</th>
                    <td className="text-right">{capitalStruture[key]}</td>
                  </tr>
                ))   
              }
              </tbody>
            </table>
          </div>
          <div className="row">
          <div className="font-weight-bold">MARKET CAP<span className="text-muted small">&nbsp; as of {lastMarketDate}</span></div>
          <table className="table table-sm text-left basic-info-table mt-2">
            <tbody>
              {
                Object.keys(marketCap).map((key, i) => (
                  <tr key={i}>
                    <th className="text-muted">{key}</th>
                    <td className="text-right">{marketCap[key]}</td>
                  </tr>
                ))   
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row mt-5" ref={scrollToPricingChart} id="pricing-chart">
        <div className="col-12 mx-2 mb-2 text-left">
          <h2>Pricing Chart</h2>
        </div>
        <div className="col-12 text-left">
            <div className="col-12 amchart">
              <div className="col-12 text-right">
                <a href={`${process.env.PUBLIC_URL}/company-stock-data/${companyProfileData.ID}.csv`} target="_blank" rel="noopener noreferrer" download>Download chart data</a>
              </div>
              {
                companyProfileData?.BaseCurrency ?
                <AMChartWithStockEvents companyId={props.companyId} baseCurrency={companyProfileData?.BaseCurrency}></AMChartWithStockEvents>
                : <></>
              }
            </div>
        </div>
      </div>
    
      <div className="row mt-5 mb-5" ref={scrollToEfrReports} id="efr-reports">
        <div className="col-12 text-left mx-2">
          <h2>{companyProfileData?.EFRReports?.SectionLabel}</h2>
        </div>

        {
          companyProfileData?.EFRReports?.Type == "sponsored" ?
          <>
            <div className="col-12 text-left mx-2">
              <div className="row mt-2 mb-2 mx-2">
                <h4 className="report-tagline">{companyProfileData?.EFRReports?.Report?.Tagline}</h4>
                <div className="report-synopsis text-justify font-italic">{companyProfileData?.EFRReports?.Report?.Synopsis}</div>
              </div>
              <div className="table-responsive-sm">
                <table className="table efr-reports-table text-left">
                  <tbody>                    
                    <tr>
                      <td scope="row"><img src={fileIcon} className="filingsIcon"></img></td>
                      <td style={{verticalAlign: 'middle'}}>{companyProfileData?.EFRReports?.Report?.Title}</td>
                      <td style={{verticalAlign: 'middle'}}>{companyProfileData?.EFRReports?.Report?.PublishDate}</td>
                      <td style={{verticalAlign: 'middle'}}>{companyProfileData?.EFRReports?.Report?.Language}</td>
                      <td>
                        <a className="btn btn-outline-primary" href={`${process.env.PUBLIC_URL}/company-report/${companyProfileData.ID}`} target="_blank" rel="noreferrer">Read Now</a>
                      </td>
                      <td><DownloadButton fileName={"uploads/" + companyProfileData?.EFRReports?.Report?.ReportLinkOrFileName} /></td>
                      <td>
                        <a className="btn btn-outline-primary" 
                        href={`mailto:info@eightfoldresearch.com?subject=${sponsoredReportEmailSubject}&body=${sponsoredReportEmailBody}`} 
                        target="_blank" rel="noreferrer">Request Update</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
          :        
          <>
            {
            companyProfileData?.EFRReports?.Reports && companyProfileData?.EFRReports?.Reports.length > 0 ?
            <>
            {
              companyProfileData.EFRReports?.Reports?.map(report => (
                
                <>
                  <div className="col-12 text-left mx-2">
                    <div className="table-responsive-sm">
                      <table className="table efr-reports-table text-left">
                        <tbody>                    
                          <tr>
                            <td scope="row"><img src={fileIcon} className="filingsIcon"></img></td>
                            <td style={{verticalAlign: 'middle'}}>
                              {report.Report.Title}
                              <div>
                                <span className="small text-danger">{report.Report.Tagline} &nbsp;</span>
                                <span title={report.Report.Synopsis} style={{cursor: 'pointer'}} className="small" >ⓘ</span>
                              </div>
                            </td>
                            <td style={{verticalAlign: 'middle'}}>{report.Report.PublishDate}</td>
                            <td style={{verticalAlign: 'middle'}}>{report.Report.Language}</td>
                            <td>
                              <a className="btn btn-outline-primary" href={`${process.env.PUBLIC_URL}/uploads/${report.Report.ReportLinkOrFileName}`} target="_blank" rel="noreferrer">Read Now</a>
                            </td>
                            <td><DownloadButton fileName={"uploads/" + report.Report.ReportLinkOrFileName} /></td>
                            <td>
                              <a className="btn btn-outline-primary" 
                              href={`mailto:info@eightfoldresearch.com?subject=${bespokeReportEmailSubject}&body=${bespokeReportEmailBody}`} 
                              target="_blank" rel="noreferrer">Get Full Report</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              ))
            }
            </> : 
            <>
              <div className="col-12 text-left mx-2">
                <div className="row mt-2 mb-2 mx-2">
                  <h4 className="report-tagline">{companyProfileData?.EFRReports?.Report?.Tagline}</h4>
                  <div className="report-synopsis text-justify font-italic">{companyProfileData?.EFRReports?.Report?.Synopsis}</div>
                </div>
                <div className="table-responsive-sm">
                  <table className="table efr-reports-table text-left">
                    <tbody>                    
                      <tr>
                        <td scope="row"><img src={fileIcon} className="filingsIcon"></img></td>
                        <td style={{verticalAlign: 'middle'}}>{companyProfileData?.EFRReports?.Report?.Title}</td>
                        <td style={{verticalAlign: 'middle'}}>{companyProfileData?.EFRReports?.Report?.PublishDate}</td>
                        <td style={{verticalAlign: 'middle'}}>{companyProfileData?.EFRReports?.Report?.Language}</td>
                        <td>
                          <a className="btn btn-outline-primary" href={`${process.env.PUBLIC_URL}/uploads/${companyProfileData?.EFRReports?.Report?.ReportLinkOrFileName}`} target="_blank" rel="noreferrer">Read Now</a>
                        </td>
                        <td><DownloadButton fileName={"uploads/" + companyProfileData?.EFRReports?.Report?.ReportLinkOrFileName} /></td>
                        <td>
                          <a className="btn btn-outline-primary" 
                          href={`mailto:info@eightfoldresearch.com?subject=${bespokeReportEmailSubject}&body=${bespokeReportEmailBody}`} 
                          target="_blank" rel="noreferrer">Get Full Report</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </> 
            }
          </>
        }        
      </div>

      <div className="row mt-5" ref={scrollToCompanyFilings} id="company-filings">
        <div className="col-12 text-left mx-2">
            <h2>{companyProfileData.CompanyFilings?.SectionLabel}</h2>
        </div>
        <div className="col-12 text-left mx-2">
          <div className="table-responsive-sm filings-container">
            <table className="table filings-table">
              <tbody>
                { 
                  companyProfileData.CompanyFilings?.Filings?.map(filing => (
                    <tr>
                      <td scope="row"><img src={fileIcon} className="filingsIcon"></img></td>
                      <td>
                        <div>{filing.Filing.Name}</div>
                        <div className="small">{filing.Filing.SubText}</div>
                      </td>
                      <td><span className="align-middle">{filing.Filing?.Date}</span></td>
                      <td><span className="align-middle">{filing.Filing?.Language}</span></td>
                      <td>
                        <a className="btn btn-outline-primary" href={`${process.env.PUBLIC_URL}/uploads/${filing.Filing.FileName}`} target="_blank" rel="noreferrer">Read Now</a>
                      </td>
                      <td><DownloadButton fileName={"uploads/" + filing.Filing.FileName} /></td>
                      {                        
                        (!companyProfileData.CompanyFilings?.HideTranslations) ?
                        <><td>
                          <a className="btn btn-outline-primary" 
                          href={`https://translate.google.com/translate?js=n&sl=ja&tl=en&u=https://www.eightfoldresearch.com/company-profile-data/${companyProfileData.ID}/${moment(filing.Filing.Date, "DD-MM-YYYY").format("YYYYMMDD")}/report.htm`} 
                          target="_blank" rel="noreferrer" title="Powered by Google Translate.">
                          <span>Read Now (in English)</span><img src={googleTranslate} className="translateIcon" alt="Google Translate."></img> </a>
                        </td></> : <></>
                      }
                    </tr>
                  )) 
                }
              </tbody>
            </table>
          </div>
          {                        
            (!companyProfileData.CompanyFilings?.HideTranslations) ?
              <><div className="row mx-2 mt-4 text-justify">
                <span className="font-italic"><b>Note:</b> The company filings in English are translations of the original Japanese filings. These translations were created by <a href="https://translate.google.com/" target="_blank">Google Translate</a> and are provided solely for information purposes. In the event of a discrepancy, the Japanese original will take precedence. Please see our <a href="https://www.eightfoldresearch.com/terms-and-conditions" target="_blank">terms and conditions</a> for more information.</span>
              </div></> : <></>
          }
        </div>
      </div>

      <div className="row mt-5" ref={scrollToFinancialSummary} id="financial-summary">
        <div className="col-12 text-left mx-2">
            <h2>{companyProfileData.FinancialSummary?.SectionLabel}</h2>

            <div className="container-grey p-2 seperator-top mt-4">
              
              <div className="container-fluid no-gutters text-left table-meta mb-4">
                <div className="row text-left">
                  { 
                    companyProfileData.FinancialSummary?.FinancialSummaryHeaders?.Headers?.map(header => (
                      <div className="col">
                        <div className="pt-1">
                          <div className="col font-weight-bold">{header.Header.Title}</div>
                          <div className="col">{header.Header.Value}</div>
                        </div>
                      </div>
                    )) 
                  }
                </div>
              </div>

              { 
                companyProfileData.FinancialSummary?.Financials?.map(financial => (

                  <><table className="table table-sm custom-tables table-striped table-responsive">
                    <caption>{financial.Financial.FinancialItemSource}</caption>
                    <thead>
                      <tr>
                        {
                          financial.Financial.FinancialItemHeaderRow.split(',').map(item => (
                            <th>{item}</th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td colSpan={financial.Financial.FinancialItemHeaderRow.split(',').length} className="seperator-xtra">
                        <h4>{financial.Financial.FinancialItemTitle}</h4>
                      </td>
                    </tr>
                    {
                      financial.Financial.FinancialItemDatum.map((section) => (
                        section.FinancialItemData.map((fid, fidIndex) => (
                          <tr className={fidIndex === section.FinancialItemData.length -1 ? 'seperator-row' : ''}>
                            {
                              fid.DataItem.split(',').map((item) => (
                                <td>{isNumeric(item) ? parseFloat(item).toLocaleString(companyProfileData.NumberFormat) : item }</td>
                              ))
                            }
                          </tr>
                        ))
                      ))
                    }
                    </tbody>
                  </table></>
                )) 
              }
            </div>

        </div>
      </div>

      <div className="row mt-5" ref={scrollToOtherInformation} id="other-information">
        <div className="col-12 text-left mx-2">
            <h2>{companyProfileData.OtherInformation?.SectionLabel}</h2>
            <div className="card mt-4">
              <div className="card-body">
                <div className="row text-left">
                  { 
                    companyProfileData.OtherInformation?.CompanyInfo?.map(item => (
                      <div className="col pt-2">
                          <div className="font-weight-bold">{item.Item.Title}</div>
                          <div>{item.Item.Value}</div>
                       </div>
                    )) 
                  }
                </div>
              </div>
            </div>
            <div className="row mt-4 mb-4">
              <div className="col-md-6 col-xs-12">
                <table className="table table-sm text-left table-shareholders mt-2">
                  <thead>
                    <tr>
                      <th scope="col" colSpan="2" style={{borderBottom: 'none', paddingBottom: '1rem'}}>
                        {companyProfileData.OtherInformation?.SignificantShareholders?.Title}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    { 
                      companyProfileData.OtherInformation?.SignificantShareholders?.Shareholders?.map(item => (
                        <tr style={{borderBottom: '1px solid #ddd'}}>
                          <th scope="row" className="text-muted" style={{borderTop: 'none'}}>{item.Item.Title}</th>
                          <td className="text-right" style={{borderTop: 'none'}}>{item.Item.Value}</td>
                        </tr>
                      )) 
                    }
                  </tbody>
                </table>              
              </div>
            </div>
            
        </div>
      </div>

    </div>
    </>
  );
}

function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export default CompanyProfileBody;
