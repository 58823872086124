import React from "react";

function KeyFinancialsAndValuations(props) {
  return (
    <div className="mt-5 container-grey p-2 seperator-top">
      <div className="container-fluid no-gutters text-left table-meta">
        <div className="row">
          <div className="col-3">
            <p className="mb-0">Report Type</p>
          </div>
          <div className="col-3">
            <p className="font-weight-bold mb-0">Consolidated Annual</p>
          </div>
          <div className="col-3">
            <p className="mb-0">Accounting Standard</p>
          </div>
          <div className="col-3">
            <p className="font-weight-bold mb-0">IFRS</p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p className="mb-0">Unit</p>
          </div>
          <div className="col-3">
            <p className="font-weight-bold mb-0">USD/ % / x</p>
          </div>
          <div className="col-3">
            <p className="mb-0">Accounting Year-End</p>
          </div>
          <div className="col-3">
            <p className="font-weight-bold mb-0">December</p>
          </div>
        </div>
      </div>
      <table className="table table-sm custom-tables table-striped table-responsive">
        <caption>Source: Griffin Mining, Eightfold Research</caption>
        <thead>
          <tr>
            <th>Period</th>
            <th>FY 2016</th>
            <th>FY 2017</th>
            <th>FY 2018</th>
            <th>FY 2019</th>
            <th>LTM Jun-20</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="6" className="seperator-xtra">
              <h4>Key Financial Ratios</h4>
            </td>
          </tr>

          <tr>
            <td>Current Ratio</td>
            <td>0.3x</td>
            <td>0.7x</td>
            <td>1.0x</td>
            <td>0.8x</td>
            <td>0.0x</td>
          </tr>
          <tr>
            <td>Quick Ratio</td>
            <td>0.2x</td>
            <td>0.5x</td>
            <td>0.8x</td>
            <td>0.6x</td>
            <td>0.0x</td>
          </tr>
          <tr>
            <td>Non-Current Asset to Equity Ratio</td>
            <td>1.4x</td>
            <td>1.1x</td>
            <td>1.0x</td>
            <td>1.1x</td>
            <td>1.1x</td>
          </tr>
          <tr>
            <td>Fixed Asset to Equity Ratio</td>
            <td>1.4x</td>
            <td>1.1x</td>
            <td>1.0x</td>
            <td>1.1x</td>
            <td>1.1x</td>
          </tr>
          <tr className="seperator-xtra">
            <td>Cash/Total Assets</td>
            <td>0.1x</td>
            <td>0.1x</td>
            <td>0.1x</td>
            <td>0.1x</td>
            <td>0.0x</td>
          </tr>
        </tbody>
      </table>
      <table className="table table-sm custom-tables table-striped table-responsive">
        <caption>Source: Griffin Mining, Eightfold Research</caption>
        <thead>
          <tr>
            <th>Period</th>
            <th>FY 2016</th>
            <th>FY 2017</th>
            <th>FY 2018</th>
            <th>FY 2019</th>
            <th>LTM Jun-20</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="6" className="seperator-xtra">
              <h4>Per Share Items</h4>
            </td>
          </tr>
          <tr>
            <td>Earnings per share</td>
            <td>0.0</td>
            <td>0.3</td>
            <td>0.1</td>
            <td>0.0</td>
            <td>0.0</td>
          </tr>
          <tr>
            <td>Dividends per share</td>
            <td>0.0</td>
            <td>0.0</td>
            <td>0.0</td>
            <td>0.0</td>
            <td>0.0</td>
          </tr>
          <tr>
            <td>Dividend Payout Ratio</td>
            <td>0.0</td>
            <td>0.0</td>
            <td>0.0</td>
            <td>0.0</td>
            <td>0.0</td>
          </tr>
          <tr>
            <td>Lev. FCF per share</td>
            <td>0.0</td>
            <td>0.3</td>
            <td>0.0</td>
            <td>0.0</td>
            <td>0.0</td>
          </tr>
          <tr>
            <td>Book Value per share</td>
            <td>0.8</td>
            <td>1.1</td>
            <td>1.1</td>
            <td>1.1</td>
            <td>1.2</td>
          </tr>
          <tr>
            <td>Tangible BV per share</td>
            <td>0.8</td>
            <td>1.1</td>
            <td>1.1</td>
            <td>1.1</td>
            <td>1.2</td>
          </tr>
          <tr>
            <td>Shares o/s - Diluted</td>
            <td>170.3</td>
            <td>170.0</td>
            <td>188.3</td>
            <td>187.9</td>
            <td>172.7</td>
          </tr>
          <tr className="seperator-xtra">
            <td>Shares o/s - Basic</td>
            <td>170.3</td>
            <td>170.0</td>
            <td>171.8</td>
            <td>172.7</td>
            <td>172.7</td>
          </tr>
        </tbody>
      </table>
      <table className="table table-sm custom-tables table-striped table-responsive">
        <caption>Source: Griffin Mining, Eightfold Research</caption>
        <thead>
          <tr>
            <th>Period</th>
            <th>FY 2016</th>
            <th>FY 2017</th>
            <th>FY 2018</th>
            <th>FY 2019</th>
            <th>LTM Jun-20</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="6" className="seperator-xtra">
              <h4>Valuation</h4>
            </td>
          </tr>

          <tr>
            <td>EV / Total Revenue</td>
            <td>2.7</td>
            <td>2.6</td>
            <td>2.3</td>
            <td>1.7</td>
            <td>1.5</td>
          </tr>
          <tr>
            <td>EV / EBITDA</td>
            <td>30.4</td>
            <td>21.7</td>
            <td>4.2</td>
            <td>3.9</td>
            <td>3.8</td>
          </tr>
          <tr>
            <td>EV / EBIT</td>
            <td>25.5</td>
            <td>7.2</td>
            <td>5.0</td>
            <td>5.2</td>
            <td>5.7</td>
          </tr>
          <tr>
            <td>P/E</td>
            <td>NM</td>
            <td>20.0</td>
            <td>6.5</td>
            <td>9.1</td>
            <td>10.7</td>
          </tr>
          <tr>
            <td>P/BV</td>
            <td>0.5</td>
            <td>0.8</td>
            <td>1.4</td>
            <td>1.0</td>
            <td>0.7</td>
          </tr>
          <tr className="seperator-xtra">
            <td>P/Tangible BV</td>
            <td>0.5</td>
            <td>0.8</td>
            <td>1.4</td>
            <td>1.0</td>
            <td>0.7</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default KeyFinancialsAndValuations;
