import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import DownloadButton from "../../download-button/dowload-button";
import TableOfContentsAccordian from "./table-of-content-accordian";
import './table-of-contents.scss'

function TableOfContents(props) {
  const stickyClassName = `position-sticky shadow rounded`;
  const [sticky , setSticky] = useState(false);
  const [tocData , setTOCData] = useState({});

  useEffect(() => {
    setSticky(props.sticky);
    setTOCData(props.tocData);
  }, [props]);

  return (
    <TableOfContentsAccordian sticky={sticky}>
      <ol className={`flex-column table-of-contents ${sticky ? stickyClassName : ""} pb-3`}
        style={{ top: 10 }}>
        
        {sticky && <h4 className="toc-header pt-2">Table of Contents</h4>}

        {
          tocData && tocData.Links && tocData.Links.length > 0 &&
          tocData.Links.map((link, index) => 
            <li className="nav-item" key={link.ID}>
              <HashLink className="nav-link active" to={"#" + link.ID} scroll={(el) => { setTimeout(() => { el.scrollIntoView(); }, 0)} }>
                {link.Description}
              </HashLink>
            </li>
          )
        }

        <div className="pt-4"><DownloadButton white={false} fileName={tocData.ReportDownloadLink} /></div>      
      </ol>
    </TableOfContentsAccordian>
  );
}

export default TableOfContents;
