import React from "react";
import TextPrimary from "./text-primary";
import TextNormal from "./text-normal";
import TextNormalWithHtml from "./text-normal-with-html";
import TextBold from "./text-bold";
import TextItalic from "./text-italic";
import TextHeader from "./text-header";
import ImageHalfWidth from "./image-half-width";
import ImageFullWidth from "./image-full-width";
import OrderedList from "./ordered-list";
import UnorderedList from "./unordered-list";
import MiniTable from "./mini-table";
import DetailedTable from "./detailed-table";
import FinancialTable from "./financial-table";
import ReportImage from "./report-image";
import ValuationProjectedGrowthTable from "./valuation-projected-growth-table";

function SubContainer(props) {
  return (
    <div className="row pt-4" style={{clear: "both"}}>
        <div className="col-12">
        {
            props && props.containerItems && 
            props.containerItems.map((content) => {
                return getComponent(content.ContentItem)})
        }
        </div>
    </div>
  );

  function getComponent (contentItem) {
    switch(contentItem.Type) {
      case 'text-normal':
        return <TextNormal text={contentItem.Value}/>
      case 'text-normal-with-html':
        return <TextNormalWithHtml text={contentItem.Value}/>
      case 'text-bold':
        return <TextBold text={contentItem.Value}/>
      case 'text-primary':
        return <TextPrimary text={contentItem.Value}/>
      case 'text-italic':
        return <TextItalic text={contentItem.Value}/>
      case 'text-header':
        return <TextHeader text={contentItem.Value}/>
      case 'image':
        return <ReportImage imageContent={contentItem.Value} />
      case 'image-full-width':
        return <ImageFullWidth imageContent={contentItem.Value} />
      case 'image-half-width':
        return <ImageHalfWidth imageContent={contentItem.Value} />
      case 'ordered-list':
        return <OrderedList listData={contentItem.Value} />
      case 'unordered-list':
        return <UnorderedList listData={contentItem.Value} />
      case 'mini-table':
        return <MiniTable tableData={contentItem.Value} />
      case 'detailed-table':
        return <DetailedTable tableData={contentItem.Value} />
      case 'financial-table':
        return <FinancialTable tableData={contentItem.Value} />
      case 'valuation-projected-growth-table':
        return <ValuationProjectedGrowthTable tableData={contentItem.Value} />
      default:
        return <div>-----</div>
    }
  }
}

export default SubContainer;