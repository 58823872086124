import React from "react";
import "./report-hero.scss";
import arrowLeft from "../../../assets/images/arrow-left.svg";
import DownloadButton from "../../download-button/dowload-button";

function ReportHero(props) {
  const companyData = props.companyData;
  return (
    <div className="report-hero d-flex align-items-center position-relative overflow-hidden animate__animated animate__fadeIn animate__delay-1s">
      <div className="container h-100 d-flex flex-column justify-content-center">
        <div className="row px-md-4">
          <div className="col-md-6 col-lg-8 text-white text-left">
            <h1 className="font-weight-bold">{companyData.Name}</h1>
            <h4>{companyData.ReportHeader}</h4>
            <p className="mb-0 font-weight-bold">{companyData.ReportType}</p>
            <p>{companyData.ReportDate}</p>
            <DownloadButton white={true} fileName={companyData.ReportDownloadFile}/>
          </div>
        </div>
      </div>
      <img src={arrowLeft} className="left-arrow d-none d-sm-block position-absolute" alt="styling arrow right"/>
    </div>
  );
}

export default ReportHero;
