import React from "react";
import "./privacy-and-cookies-hero.scss";
import arrowLeft from "../../../assets/images/arrow-left.svg";
function PrivacyAndCookiesHero(props) {
  return (
    <div className="privacy-and-cookies-hero d-flex align-items-center position-relative overflow-hidden animate__animated animate__fadeIn animate__delay-1s">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-8 text-white text-left">
            <h2>Privacy and Cookies</h2>
          </div>
        </div>
      </div>
      <img src={arrowLeft} className="left-arrow d-none d-sm-block position-absolute" alt="styling arrow right" style={{right:0}}/>
    </div>
  );
}

export default PrivacyAndCookiesHero;
