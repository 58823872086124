import { React, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import CompanyProfileBody from '../../components/company-profile/company-profile-body/company-profile-body';

function CompanyProfile(props){
    const { companyid } = useParams();
    let [companyProfileData, setCompanyData] = useState({});
    useEffect(() => {
      fetch(`/company-profile-data/` + companyid + `-en.json`)
          .then((response) => response.json())
          .then((data) => {
            setCompanyData(data);
          });
      }, [companyid]);

    return ( <CompanyProfileBody companyData={companyProfileData} companyId={companyid} /> );
}

export default CompanyProfile;