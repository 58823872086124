import React from "react";
import "./our-services-section-four.scss";
// import leftArrow from "../../../assets/images/our-services-section-three-arrow-left.svg";
// import rightArrow from "../../../assets/images/our-services-section-three-arrow-right.svg";
import { useTranslation } from "react-i18next";
function OurServicesSectionFour(props) {
  const { t, i18n } = useTranslation();
  return (
    <div className="our-services-section-four d-flex flex-column bg-white animate__animated animate__fadeIn animate__delay-1s">
      <div className="banner d-flex flex-columnn apply-padding">
        <div className="container d-flex flex-column">
          <div className="row my-auto">
            <div className="col-md-6 col-lg-8 text-left">
              <h2 className="text-white font-weight-bold">
                {t("our-services.section-4.header-1")}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="apply-padding text-left mt-3">
        <p className="my-4">{t("our-services.section-4.body-1-1")}</p>
        {i18n.language === 'ja' && (
          <>
            <ul>
              <li>{t("our-services.section-4.list-1-1")}</li>
              <li>{t("our-services.section-4.list-1-2")}</li>
            </ul>
            <p className="my-4">{t("our-services.section-4.body-1-2")}</p>
          </>
        )}
        <h4>{t("our-services.section-4.header-2")}</h4>
        <p className="mt-2 mb-4">{t("our-services.section-4.body-2-1")}</p>
      </div>
    </div>
  );
}

export default OurServicesSectionFour;
