import React, { useRef, useLayoutEffect } from "react";
import moment from "moment";
import "./historical-data-chart.scss";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_rangeSelector from "@amcharts/amcharts4/plugins/rangeSelector";

am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);

const dateFormat = "dd-MM-yyyy";

export default function AMChartWithStockEvents(props) {
  
  const chartObj = useRef(null);
  let dataURL = process.env.PUBLIC_URL + `/company-stock-data/` + props.companyId + `.csv`;
  let dataStockEventsURL = process.env.PUBLIC_URL + `/company-stock-data/` + props.companyId + `-stockevents.csv`;
  let baseCurrency = props.baseCurrency;

  let stockEventsData = new am4core.DataSource();
  stockEventsData.url = dataStockEventsURL;
  stockEventsData.parser = new am4core.CSVParser();
  stockEventsData.parser.options.useColumnNames = true;

  useLayoutEffect(() => {
    
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.padding(0, 15, 0, 15);
    
    // Load external data
    chart.dataSource.url = dataURL;
    chart.dataSource.parser = new am4core.CSVParser();
    chart.dataSource.parser.options.useColumnNames = true;

    // the following line makes value axes to be arranged vertically.
    chart.leftAxesContainer.layout = "vertical";
    chart.dateFormatter.inputDateFormat = dateFormat;

    chart.cursor = new am4charts.XYCursor();

    // uncomment this line if you want to change order of axes
    // chart.bottomAxesContainer.reverseOrder = true;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.ticks.template.length = 8;
    dateAxis.renderer.ticks.template.strokeOpacity = 0.1;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.ticks.template.disabled = false;
    dateAxis.renderer.ticks.template.strokeOpacity = 0.2;
    dateAxis.renderer.minLabelPosition = 0.01;
    dateAxis.renderer.maxLabelPosition = 0.99;
    dateAxis.keepSelection = true;
    dateAxis.minHeight = 40;

    dateAxis.groupData = false;
    dateAxis.minZoomCount = 5;

    // these two lines makes the axis to be initially zoomed-in
    // dateAxis.start = 0.7;
    // dateAxis.keepSelection = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.zIndex = 1;
    valueAxis.renderer.baseGrid.disabled = true;
    // height of axis
    valueAxis.height = am4core.percent(74);

    // valueAxis.renderer.gridContainer.background.fill = am4core.color("#000000");
    // valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.verticalCenter = "bottom";
    valueAxis.renderer.labels.template.padding(2, 2, 2, 2);

    //valueAxis.renderer.maxLabelPosition = 0.95;
    valueAxis.renderer.fontSize = "0.8em"

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "Date";
    series.dataFields.valueY = "Adj Close";
    series.tooltipText = baseCurrency + " " + "{valueY.value}";
    series.name = props.companyId + ": Value";
    series.defaultState.transitionDuration = 0;

    // Create hiddenSeries to provide tooltip behavior for "StockEvent"
    var hiddenSeries = chart.series.push(new am4charts.LineSeries());
    hiddenSeries.dataSource = stockEventsData;
    hiddenSeries.dataFields.valueY = "Adj Close";
    hiddenSeries.dataFields.dateX = "Date";
    hiddenSeries.strokeWidth = 17;
    hiddenSeries.strokeOpacity = 0;
    
    //Bullets for Stock Events.
    var stockBullet = hiddenSeries.bullets.push(new am4charts.Bullet());
    stockBullet.dy = -12;
    var circle = stockBullet.createChild(am4core.Circle);
    circle.stroke = "#000";
    circle.strokeWidth = 0.5;
    circle.radius = 8.5;
    circle.fill = am4core.color("#EEE");
    circle.dy = -10;
    var line = stockBullet.createChild(am4core.Line);
    line.stroke = "#000";
    line.strokeWidth = 0.7;
    line.height = 12;
    var label = stockBullet.createChild(am4core.Label);
    label.fill = am4core.color("#000");
    label.fontSize = 12;
    label.stroke = "#000"
    label.strokeWidth = 0.5;
    label.dy = -18;
    label.verticalCenter = "none";
    label.horizontalCenter = "middle";

    stockBullet.events.on("inited", function(event) { 
      if (event.target.dataItem && event.target.dataItem.dataContext && event.target.dataItem.dataContext.EventType) {
        event.target.children.getIndex(2).text = event.target.dataItem.dataContext.EventType;
        
        switch(event.target.dataItem.dataContext.EventType){
          case "D":
            event.target.children.getIndex(0).fill = am4core.color("#E6FFBA");
            break;
          case "S":
            event.target.children.getIndex(0).fill = am4core.color("#E3F9F8");
            break;
          case "B":
            event.target.children.getIndex(0).fill = am4core.color("#FFF6B2");
            break;
          case "I":
            event.target.children.getIndex(0).fill = am4core.color("#F7B29B");
            break;
          case "C":
            event.target.children.getIndex(0).fill = am4core.color("#FFDC96");
            break;
          case "M":
            event.target.children.getIndex(0).fill = am4core.color("#B5A4B4");
            break;
          case "X":
            event.target.children.getIndex(0).fill = am4core.color("#F2D7BC");
            break;
          case "O":
            event.target.children.getIndex(0).fill = am4core.color("#D0E0DA");
            break;
          case "V":
            event.target.children.getIndex(0).fill = am4core.color("#FFE4B2");
          default:
            break
        }

        event.target.tooltip = new am4core.Tooltip();
        event.target.tooltip.dy = -20;
        event.target.tooltipHTML = event.target.dataItem.dataContext.EventDescription;
        event.target.tooltip.contentAlign = "left";
        event.target.tooltip.getFillFromObject = false;
        event.target.tooltip.background.fill = am4core.color("#555c89");
        event.target.tooltip.background.fillOpacity = 1;
        event.target.tooltip.label.wrap = true;
        event.target.tooltip.label.maxWidth = 250;
        event.target.tooltip.fontSize = 14;

      } else {
        event.target.disabled = true;
      }
    });

    stockBullet.events.on("over", function(event){
      if (event.target.dataItem && event.target.dataItem.dataContext 
        && event.target.dataItem.dataContext.EventType && event.pointer && event.pointer.point) {
          event.target.tooltipHTML = "<strong>" + event.target.dataItem.dataContext.EventTitle + "</strong>" + 
               "<div style='word-wrap: break-word;'>" + moment(event.target.dataItem.dataContext.Date, "DD-MM-YYYY").format("DD MMM YYYY") + ": " 
               + event.target.dataItem.dataContext.EventDescription + "</div>";
          event.target.tooltip.showTooltip = true;
      }
    });

    let valueAxisSeparator = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxisSeparator.tooltip.disabled = true;
    // height of axis
    valueAxisSeparator.height = am4core.percent(1);
    valueAxisSeparator.zIndex = 3
    valueAxisSeparator.renderer.gridContainer.background.fill = am4core.color("#000000");
    valueAxisSeparator.renderer.gridContainer.background.fillOpacity = 0.09;
    valueAxisSeparator.maxHeight = am4core.percent(1);

    let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.tooltip.disabled = true;
    // height of axis
    valueAxis2.height = am4core.percent(25);
    valueAxis2.zIndex = 4
    // this makes gap between panels
    valueAxis2.marginTop = 40;
    valueAxis2.renderer.baseGrid.disabled = true;
    valueAxis2.renderer.inside = true;
    valueAxis2.renderer.labels.template.verticalCenter = "bottom";
    valueAxis2.renderer.labels.template.padding(2, 2, 2, 2);
    valueAxis.renderer.maxLabelPosition = 0.95;
    valueAxis2.renderer.fontSize = "0.8em";

    let series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.dateX = "Date";
    series2.dataFields.valueY = "Volume";
    series2.yAxis = valueAxis2;
    series2.tooltipText = "Vol " + "{valueY.value}";
    series2.name = props.companyId + ": Volume";
    // volume should be summed
    series2.groupFields.valueY = "sum";
    series2.defaultState.transitionDuration = 0;

    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    scrollbarX.marginBottom = 20;
    scrollbarX.scrollbarChart.xAxes.getIndex(0).minHeight = undefined;
    chart.scrollbarX = scrollbarX;
    chart.scrollbarX.parent = chart.bottomAxesContainer;

    // Add range selector
    let selector = new am4plugins_rangeSelector.DateAxisRangeSelector();
    selector.container = document.getElementById("controls");
    selector.axis = dateAxis;
    selector.inputDateFormat = dateFormat;
    chart.dateFormat = dateFormat;

    // Add 5Y option that is not available in the default list.
    // TODO: Add this option only if the data is more than 5 years.
    selector.periods.splice (5, 0, { name: "5Y", interval: { timeUnit: "year", count: 5 } });

    const ytdItem = selector.periods[4];
    const oneYearItem = selector.periods[3];
    selector.periods[3] = ytdItem;
    selector.periods[4] = oneYearItem;

    // Disable the logo
    // if (chart.logo) {
    //   chart.logo.disabled = true;
    // }

    // chart.events.on("ready", function () {
    //   setTimeout(() => {
    //     document.getElementsByClassName("amcharts-range-selector-period-button")[4].click();
    //   }, 2000);
    // });

    chartObj.current = chart; 

    return () => {
      chart.dispose();
    };
  }, [props.companyId]);

  return (<><div id="controls"></div><div id="chartdiv"></div></>);
}
