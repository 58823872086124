import React from "react";
import "./report-hero.scss";
import arrowLeft from "../../../assets/images/arrow-left.svg";
import DownloadButton from "../../download-button/dowload-button";

function ReportHero(props) {
  return (
    <div className="report-hero d-flex align-items-center position-relative overflow-hidden animate__animated animate__fadeIn animate__delay-1s">
      <div className="container h-100 d-flex flex-column justify-content-center">
        <div className="row px-md-4">
          <div className="col-md-6 col-lg-8 text-white text-left">
            <h1 className="font-weight-bold">Griffin Mining Ltd.</h1>
            <h4>Coverage Initiation Report</h4>
            <p className="mb-0 font-weight-bold">Eureka</p>
            <p>25 SEPTEMBER 2020</p>
            <DownloadButton white={true} fileName="Griffin_Mining_-_Eureka_Initiaition_Report_-_Sep_2020.pdf"/>
          </div>
        </div>
      </div>
      <img src={arrowLeft} className="left-arrow d-none d-sm-block position-absolute" alt="styling arrow right"/>
    </div>
  );
}

export default ReportHero;
