import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Api from "../../services/api";
function DeleteCompany(props) {
  const { id, name, onDelete } = props;
  const queryClient = useQueryClient();
  const mutation = useMutation((companyID) => Api.deleteCompany(companyID), {
    onSuccess: () => {
      queryClient.invalidateQueries("companies");
    },
  });
  const performDelete = (id) => {
    mutation.mutate(id);
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-danger"
        data-toggle="modal"
        data-target={`#deleteCompany-${id}`}
      >
        Delete
      </button>

      <div
        className={`modal fade`}
        id={`deleteCompany-${id}`}
        tabIndex="-1"
        aria-labelledby="deleteCompanyLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteCompanyLabel">
                Delete Company
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want delete {name}?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => performDelete(id)}
                data-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteCompany;
