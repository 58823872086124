import React from "react";

function ReportImage(props) {
  return (<>
    { props && props.imageContent && props.imageContent.ImageTitle && <h5 className="font-weight-bold">{props.imageContent.ImageTitle}</h5>}
    <img className={props && props.imageContent && props.imageContent.ImageFooter ? "img-fluid mb-2" : "img-fluid mb-4"} alt="Report Image" src={`${process.env.PUBLIC_URL}/company-report-data/${props.imageContent.Src}`} />
    { props && props.imageContent && props.imageContent.ImageFooter && <div className="small mb-4">{props.imageContent.ImageFooter}</div>}
  </>);
}

export default ReportImage;