import React from "react";

function OrderedList(props) {
  return (
    <ol>
    {
        props.listData.map((item, index) => {
            return <li className="text-dark pb-2" key={index}>{item.Item}</li>
        })
    }
    </ol>
  );
}

export default OrderedList;