import React from "react";

function KeyBalanceAndIncome(props) {
  return (
    <div className="container-grey p-2 seperator-top">
      <div className="container-fluid no-gutters text-left table-meta">
        <div className="row">
          <div className="col-3">
            <p className="mb-0">Report Type</p>
          </div>
          <div className="col-3">
            <p className="font-weight-bold mb-0">Consolidated Annual</p>
          </div>
          <div className="col-3">
            <p className="mb-0">Accounting Standard</p>
          </div>
          <div className="col-3">
            <p className="font-weight-bold mb-0">IFRS</p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p className="mb-0">Unit</p>
          </div>
          <div className="col-3">
            <p className="font-weight-bold mb-0">USD Million</p>
          </div>
          <div className="col-3">
            <p className="mb-0">Accounting Year-End</p>
          </div>
          <div className="col-3">
            <p className="font-weight-bold mb-0">December</p>
          </div>
        </div>
      </div>
      <table className="table table-sm custom-tables table-striped table-responsive">
        <caption>Source: Griffin Mining, Eightfold Research</caption>
        <thead>
          <tr>
            <th>Period</th>
            <th>FY 2016</th>
            <th>FY 2017</th>
            <th>FY 2018</th>
            <th>FY 2019</th>
            <th>LTM Jun-20</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="6" className="seperator-xtra">
              <h4>Key Balance Sheet Items</h4>
            </td>
          </tr>

          <tr>
            <td>Total Assets</td>
            <td>234</td>
            <td>253</td>
            <td>251</td>
            <td>254</td>
            <td>243</td>
          </tr>
          <tr>
            <td>Total Current Assets</td>
            <td>28</td>
            <td>37</td>
            <td>36</td>
            <td>26</td>
            <td>17</td>
          </tr>
          <tr>
            <td>Cash &amp; STI</td>
            <td>13</td>
            <td>27</td>
            <td>28</td>
            <td>20</td>
            <td>10</td>
          </tr>
          <tr>
            <td>Cash &amp; Equivalent</td>
            <td>13</td>
            <td>27</td>
            <td>28</td>
            <td>20</td>
            <td>10</td>
          </tr>
          <tr>
            <td>Short-term Investments</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Total Non-current Asset</td>
            <td>206</td>
            <td>217</td>
            <td>215</td>
            <td>229</td>
            <td>226</td>
          </tr>
          <tr>
            <td>Gross PP&amp;E</td>
            <td>252</td>
            <td>273</td>
            <td>278</td>
            <td>304</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Accumulated Depreciation</td>
            <td>-48</td>
            <td>-59</td>
            <td>-65</td>
            <td>-76</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Net PP&amp;E</td>
            <td>204</td>
            <td>215</td>
            <td>213</td>
            <td>228</td>
            <td>226</td>
          </tr>
          <tr>
            <td>Goodwill &amp; Intangibles</td>
            <td>2</td>
            <td>2</td>
            <td>2</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Long-term Investments</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr className="seperator-row">
            <td>Investments &amp; other assets</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>
              Total Liabilities
            </td>
            <td>91</td>
            <td>62</td>
            <td>40</td>
            <td>39</td>
            <td>34</td>
          </tr>
          <tr>
            <td>Total Current Liabilities</td>
            <td>82</td>
            <td>56</td>
            <td>35</td>
            <td>34</td>
            <td>27</td>
          </tr>
          <tr>
            <td>S/T Fin. Debt</td>
            <td>45</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Total Non-current Liabilities</td>
            <td>9</td>
            <td>6</td>
            <td>5</td>
            <td>5</td>
            <td>6</td>
          </tr>
          <tr>
            <td>L/T Fin Debt</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Total Lease Obligations</td>
            <td>7</td>
            <td>4</td>
            <td>2</td>
            <td>3</td>
            <td>2</td>
          </tr>
          <tr>
            <td>Total Net Assets</td>
            <td>143</td>
            <td>191</td>
            <td>211</td>
            <td>215</td>
            <td>209</td>
          </tr>
          <tr>
            <td>Total Shareholders' Equity</td>
            <td>143</td>
            <td>191</td>
            <td>211</td>
            <td>215</td>
            <td>209</td>
          </tr>
          <tr>
            <td>Non-Controlling Interests</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Total Debt</td>
            <td>51</td>
            <td>4</td>
            <td>2</td>
            <td>3</td>
            <td>2</td>
          </tr>
          <tr>
            <td>Net Debt</td>
            <td>38</td>
            <td>-22</td>
            <td>-27</td>
            <td>-17</td>
            <td>-9</td>
          </tr>
          <tr className="seperator-xtra">
            <td>Net Fin Debt</td>
            <td>31</td>
            <td>-27</td>
            <td>-28</td>
            <td>-20</td>
            <td>-10</td>
          </tr>
        </tbody>
      </table>
      <table className="table table-sm custom-tables table-striped table-responsive">
        <caption>Source: Griffin Mining, Eightfold Research</caption>
        <thead>
          <tr>
            <th>Period</th>
            <th>FY 2016</th>
            <th>FY 2017</th>
            <th>FY 2018</th>
            <th>FY 2019</th>
            <th>LTM Jun-20</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="6" className="seperator-xtra">
              <h4>Key Income Statement Items</h4>
            </td>
          </tr>

          <tr>
            <td>Total Sales</td>
            <td>66</td>
            <td>127</td>
            <td>99</td>
            <td>82</td>
            <td>65</td>
          </tr>
          <tr>
            <td>COGS</td>
            <td>38</td>
            <td>44</td>
            <td>46</td>
            <td>49</td>
            <td>43</td>
          </tr>
          <tr>
            <td>Gross Profit</td>
            <td>28</td>
            <td>82</td>
            <td>53</td>
            <td>34</td>
            <td>22</td>
          </tr>
          <tr>
            <td>Selling, General and Admin Expenses</td>
            <td>13</td>
            <td>19</td>
            <td>18</td>
            <td>19</td>
            <td>17</td>
          </tr>
          <tr>
            <td>EBITDA</td>
            <td>24</td>
            <td>74</td>
            <td>46</td>
            <td>27</td>
            <td>18</td>
          </tr>
          <tr>
            <td>EBIT</td>
            <td>15</td>
            <td>64</td>
            <td>36</td>
            <td>12</td>
            <td>3</td>
          </tr>
          <tr>
            <td>Operating Profit</td>
            <td>15</td>
            <td>64</td>
            <td>36</td>
            <td>12</td>
            <td>3</td>
          </tr>
          <tr>
            <td>Pretax Profit</td>
            <td>10</td>
            <td>61</td>
            <td>35</td>
            <td>12</td>
            <td>2</td>
          </tr>
          <tr>
            <td>Interest Expenses (net)</td>
            <td>4</td>
            <td>2</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr className="seperator-xtra">
            <td>Net Profit for Shareholders</td>
            <td>6</td>
            <td>43</td>
            <td>25</td>
            <td>6</td>
            <td>-2</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default KeyBalanceAndIncome;
