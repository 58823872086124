import React from 'react';
import OurServicesHero from '../../components/our-services/our-services-hero/our-services-hero';
import OurServicesSectionTwo from '../../components/our-services/our-services-section-two/our-services-section-two';
import OurServicesSectionThree from '../../components/our-services/our-services-section-three/our-services-section-three';
import OurServicesFaq from '../../components/our-services/our-services-faq/our-services-faq';
import OurServicesSectionFour from '../../components/our-services/our-services-section-four/our-services-section-four';
function OurServices(props){
    return (
        <>
            <OurServicesHero/>
            <OurServicesSectionTwo/>
            <OurServicesSectionThree/>
            <OurServicesSectionFour/>
            <OurServicesFaq/>
        </>
    )
}

export default OurServices;