import {React, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import ReportBody from "../../components/company-report/report-body/report-body";
import ReportHero from "../../components/company-report/report-hero/report-hero";
import HomepageSubscribe from "../../components/homepage/subscribe/subscribe";

function CompanyReport(props) {
    const { companyid } = useParams();
    let [companyData, setCompanyData] = useState({});
    useEffect(() => {
        fetch(`/company-report-data/` + companyid + `-en.json`)
          .then((response) => response.json())
          .then((data) => {
            setCompanyData(data);
          });
      }, []);

    return (
        <>
            <ReportHero companyData={companyData} companyId={companyid} />
            <ReportBody companyData={companyData} companyId={companyid} />
            <HomepageSubscribe/>
        </>
    );
}

export default CompanyReport;