import React, { useEffect, useState } from "react";

function MiniTable(props) {

  const [tableData, setTableData] = useState(props.tableData);

  useEffect(() => {
    setTableData(props.tableData);
  }, [props]);

  const bgColor = "#e8e8e8";
  return (  
       
      <div className="seperator-top-xtra p-3 pb-0 mb-3" style={{backgroundColor: bgColor}}>
      {tableData && tableData.TableTitle && <h5 className="font-weight-bold">{tableData.TableTitle}</h5>}
      {tableData && tableData.TableSubTitle && <h5 className="text-dark font-weight-bold">{tableData.TableSubTitle}</h5>}
        <table className="table table-striped">
          {tableData && tableData.TableFooter && <caption>{tableData.TableFooter}</caption>}
          {
            <thead><tr>
              {
                tableData && tableData.TableHeader &&
                tableData.TableHeader.split('|').map((headerItem, headerItemIndex) => 
                  {
                    return <th key={"hi" + headerItemIndex}>{headerItem}</th>
                  }
                )
              }
            </tr></thead>            
          }
          <tbody>
            {
              tableData && tableData.TableBody && tableData.TableBody.length > 0
              && tableData.TableBody.map((bodyItem, index) => (
                <tr key={"tr"+index}>
                  {
                    bodyItem.Item.split('|').map((rowItem, rowItemIndex) => 
                      {
                        return (rowItemIndex === 0 ? 
                        <th key={index+"ri"+rowItemIndex}>{rowItem}</th>
                        : <td key={index+"ri"+rowItemIndex}>{rowItem}</td>)
                      }
                    )
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    
  );
}

export default MiniTable;