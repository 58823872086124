import React, {useState, useEffect} from "react";

function FinancialTable(props) {

  const [tableData, setTableData] = useState(props.tableData);

  useEffect(() => {
    setTableData(props.tableData);
  }, [props]);

  return (
    <>
        <div className="container-grey p-2 seperator-top mt-4 mb-4">
              
            <div className="container-fluid no-gutters text-left table-meta mb-2">
                <div className="row text-left small">
                    { 
                    tableData && tableData.FinancialTable && tableData.FinancialTable.FinancialTableHeaders &&
                    tableData.FinancialTable.FinancialTableHeaders.map(header => (                        
                        <div className="col-md-6">
                        <div className="row pt-1">
                            <div className="col">{header.Header.Title}</div>
                            <div className="col font-weight-bold text-right pl-0">{header.Header.Value}</div>
                        </div>
                        </div>
                    )) 
                    }
                    {tableData && tableData.FinancialTable && tableData.FinancialTable.FinancialTableTitle && <h5 className="font-weight-bold">{tableData.FinancialTable.FinancialTableTitle}</h5>}
                    {tableData && tableData.FinancialTable && tableData.FinancialTable.FinancialTableSubTitle && <h5 className="text-dark">{tableData.FinancialTable.FinancialTableSubTitle}</h5>}
                </div>
            </div>

            { 
                tableData && tableData.FinancialTable && tableData.FinancialTable.Financials &&
                tableData.FinancialTable.Financials.map(financial => (

                    <><table className="table table-sm custom-tables table-striped table-responsive">
                    <caption>{financial.Financial.FinancialItemSource}</caption>
                    <thead>                        
                    {
                        financial.Financial.FinancialItemHeaders.map(headerItem => (
                            <tr>
                                {
                                    headerItem.FinancialItemHeader.split('|').map(item => (
                                        <th>{item}</th>
                                    ))
                                }
                            </tr>
                        ))
                    }                        
                    </thead>
                    <tbody>
                    { financial.Financial.FinancialItemTitle &&
                        <tr>
                            <td colSpan={financial.Financial.FinancialItemHeaders[0].FinancialItemHeader.split('|').length} className="seperator-xtra">
                            <h4>{financial.Financial.FinancialItemTitle}</h4>
                            </td>
                        </tr>
                    }
                    {
                        financial.Financial.FinancialItemDatum.map((section) => (
                        section.FinancialItemData.map((fid, fidIndex) => (
                            <tr className={fidIndex === section.FinancialItemData.length -1 ? 'seperator-row' : ''}>
                            {
                                fid.DataItem.split('|').map((item) => (
                                <td>{item}</td>
                                ))
                            }
                            </tr>
                        ))
                        ))
                    }
                    </tbody>
                    </table></>
                )) 
            }
        </div>
    </>
  );
}

export default FinancialTable;