import React from "react";
import "./our-services-faq.scss";
import faqArrow from "../../../assets/images/faq-arrow.svg";
import { useTranslation } from "react-i18next";
function OurServicesFaq(props) {
  const { t, i18n } = useTranslation();
  return (
    <div className="our-services-faq bg-white animate__animated animate__fadeIn animate__delay-1s">
      <div className="banner"></div>
      <div className="contaier-fluid bg-white apply-padding py-5">
        <h4 className="text-center my-5">Frequently Asked Questions</h4>
        <h5 className="text-left mt-3">{t("our-services.faq.header-1")}</h5>
        <div className="accordion container-md" id="faqAccordian">
          <div className="card border-0">
            <div className="card-header bg-white" id="headingTwo">
              <h2>
                <button
                  className="btn btn-link btn-block text-left text-dark  d-flex justify-content-between collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <span>{t("our-services.faq.one.question")}</span>
                  <img src={faqArrow} alt="faq arrow" />
                </button>
              </h2>
            </div>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#faqAccordian"
            >
              <div className="card-body">
                <p>{t("our-services.faq.one.answer-1")}</p>
                <p>{t("our-services.faq.one.answer-2")}</p>
                <p>{t("our-services.faq.one.answer-3")}</p>
              </div>
            </div>
          </div>
          <div className="card border-0">
            <div className="card-header bg-white" id="headingThree">
              <h2>
                <button
                  className="btn btn-link btn-block text-left text-dark  collapsed d-flex justify-content-between"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <span>{t("our-services.faq.two.question")}</span>
                  <img src={faqArrow} alt="faq arrow" />
                </button>
              </h2>
            </div>
            <div
              id="collapseThree"
              className="collapse"
              aria-labelledby="headingThree"
              data-parent="#faqAccordian"
            >
              <div className="card-body">
                <p>{t("our-services.faq.two.answer")}</p>
              </div>
            </div>
          </div>

          <div className="card border-0">
            <div className="card-header bg-white" id="headingFour">
              <h2>
                <button
                  className="btn btn-link btn-block text-left text-dark  d-flex justify-content-between collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <span>{t("our-services.faq.three.question")}</span>
                  <img src={faqArrow} alt="faq arrow" />
                </button>
              </h2>
            </div>
            <div
              id="collapseFour"
              className="collapse"
              aria-labelledby="headingFour"
              data-parent="#faqAccordian"
            >
              <div className="card-body">
                <p>{t("our-services.faq.three.answer-1")}</p>
                <ul>
                  <li>{t("our-services.faq.three.answer-list-1")}</li>
                  <li>{t("our-services.faq.three.answer-list-2")}</li>
                  <li>{t("our-services.faq.three.answer-list-3")}</li>
                </ul>
                <p>{t("our-services.faq.three.answer-list-4")}</p>
              </div>
            </div>
          </div>

          <div className="card border-0">
            <div className="card-header bg-white" id="headingFive">
              <h2>
                <button
                  className="btn btn-link btn-block text-left text-dark  d-flex justify-content-between collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  <span>{t("our-services.faq.four.question")}</span>
                  <img src={faqArrow} alt="faq arrow" />
                </button>
              </h2>
            </div>
            <div
              id="collapseFive"
              className="collapse"
              aria-labelledby="headingFive"
              data-parent="#faqAccordian"
            >
              <div className="card-body">
                <p>{t("our-services.faq.four.answer-1")}</p>
                {/* {<p>{t("our-services.faq.four.answer-2")}</p>} */}
              </div>
            </div>
          </div>

          <div className="card border-0">
            <div className="card-header bg-white" id="headingSix">
              <h2>
                <button
                  className="btn btn-link btn-block text-left text-dark  d-flex justify-content-between collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  <span>{t("our-services.faq.five.question")}</span>
                  <img src={faqArrow} alt="faq arrow" />
                </button>
              </h2>
            </div>
            <div
              id="collapseSix"
              className="collapse"
              aria-labelledby="headingSix"
              data-parent="#faqAccordian"
            >
              <div className="card-body">
                <p>{t("our-services.faq.five.answer-1")}</p>
                <p>{t("our-services.faq.five.answer-2")}</p>
                <p>{t("our-services.faq.five.answer-3")}</p>
                <p>{t("our-services.faq.five.answer-4")}</p>
              </div>
            </div>
          </div>

          <div className="card border-0">
            <div className="card-header bg-white" id="headingSeven">
              <h2>
                <button
                  className="btn btn-link btn-block text-left text-dark  d-flex justify-content-between collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  <span>{t("our-services.faq.six.question")}</span>
                  <img src={faqArrow} alt="faq arrow" />
                </button>
              </h2>
            </div>
            <div
              id="collapseSeven"
              className="collapse"
              aria-labelledby="headingSeven"
              data-parent="#faqAccordian"
            >
              <div className="card-body">
                <p>
                  {t("our-services.faq.six.answer")}
                  <a href="mailto:info@eightfoldresearch.com">
                    info@eightfoldresearch.com.
                  </a>
                  {t("our-services.faq.six.jp-suffix")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <h5 className="text-left mt-5 mb-3">
          {t("our-services.faq.header-2")}
        </h5>
        <div className="accordion container-md" id="bespokeAccordia">
          <div className="card border-0">
            <div className="card-header bg-white" id="bespokeHeadingTwo">
              <h2>
                <button
                  className="btn btn-link btn-block text-left text-dark  d-flex justify-content-between collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#bespokeCollapseTwo"
                  aria-expanded="false"
                  aria-controls="bespokeCollapseTwo"
                >
                  <span>{t("our-services.faq.seven.question")}</span>
                  <img src={faqArrow} alt="faq arrow" />
                </button>
              </h2>
            </div>
            <div
              id="bespokeCollapseTwo"
              className="collapse"
              aria-labelledby="bespokeHeadingTwo"
              data-parent="#bespokeAccordia"
            >
              <div className="card-body">
                <p>{t("our-services.faq.seven.answer.p1")}</p>
              </div>
            </div>
          </div>

          <div className="card border-0">
            <div className="card-header bg-white" id="bespokeHeadingFour">
              <h2>
                <button
                  className="btn btn-link btn-block text-left text-dark  d-flex justify-content-between collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#bespokeCollapseFour"
                  aria-expanded="false"
                  aria-controls="bespokeCollapseFour"
                >
                  <span>{t("our-services.faq.eight.question")}</span>
                  <img src={faqArrow} alt="faq arrow" />
                </button>
              </h2>
            </div>
            <div
              id="bespokeCollapseFour"
              className="collapse"
              aria-labelledby="bespokeHeadingFour"
              data-parent="#bespokeAccordia"
            >
              <div className="card-body">
                <p>{t("our-services.faq.eight.answer.p1")}</p>
                <ul>
                  <li>{t("our-services.faq.eight.answer.l1")}</li>
                  <li>{t("our-services.faq.eight.answer.l2")}</li>
                  <li>{t("our-services.faq.eight.answer.l3")}</li>
                </ul>
                {i18n.language === "en" && (
                  <p>{t("our-services.faq.eight.answer.p2")}</p>
                )}
              </div>
            </div>
          </div>

          <div className="card border-0">
            <div className="card-header bg-white" id="bespokeHeadingThree">
              <h2>
                <button
                  className="btn btn-link btn-block text-left text-dark  collapsed d-flex justify-content-between"
                  type="button"
                  data-toggle="collapse"
                  data-target="#bespokeCollapseThree"
                  aria-expanded="false"
                  aria-controls="bespokeCollapseThree"
                >
                  <span>{t("our-services.faq.nine.question")}</span>
                  <img src={faqArrow} alt="faq arrow" />
                </button>
              </h2>
            </div>
            <div
              id="bespokeCollapseThree"
              className="collapse"
              aria-labelledby="bespokeHeadingThree"
              data-parent="#bespokeAccordia"
            >
              <div className="card-body">
                <p>{t("our-services.faq.nine.answer")}</p>
              </div>
            </div>
          </div>

          <div className="card border-0">
            <div className="card-header bg-white" id="bespokeHeadingFive">
              <h2>
                <button
                  className="btn btn-link btn-block text-left text-dark  d-flex justify-content-between collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#bespokeCollapseFive"
                  aria-expanded="false"
                  aria-controls="bespokeCollapseFive"
                >
                  <span>{t("our-services.faq.ten.question")}</span>
                  <img src={faqArrow} alt="faq arrow" />
                </button>
              </h2>
            </div>
            <div
              id="bespokeCollapseFive"
              className="collapse"
              aria-labelledby="bespokeHeadingFive"
              data-parent="#bespokeAccordia"
            >
              <div className="card-body">
                <p>{t("our-services.faq.ten.answer")}</p>
              </div>
            </div>
          </div>

          <div className="card border-0">
            <div className="card-header bg-white" id="bespokeHeadingSix">
              <h2>
                <button
                  className="btn btn-link btn-block text-left text-dark  d-flex justify-content-between collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#bespokeCollapseSix"
                  aria-expanded="false"
                  aria-controls="bespokeCollapseSix"
                >
                  <span>{t("our-services.faq.eleven.question")}</span>
                  <img src={faqArrow} alt="faq arrow" />
                </button>
              </h2>
            </div>
            <div
              id="bespokeCollapseSix"
              className="collapse"
              aria-labelledby="bespokeHeadingSix"
              data-parent="#bespokeAccordia"
            >
              <div className="card-body">
                <p>{t("our-services.faq.eleven.answer")}</p>
              </div>
            </div>
          </div>

          <div className="card border-0">
            <div className="card-header bg-white" id="bespokeHeadingSeven">
              <h2>
                <button
                  className="btn btn-link btn-block text-left text-dark  d-flex justify-content-between collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#bespokeCollapseSeven"
                  aria-expanded="false"
                  aria-controls="bespokeCollapseSeven"
                >
                  <span>{t("our-services.faq.twelve.question")}</span>
                  <img src={faqArrow} alt="faq arrow" />
                </button>
              </h2>
            </div>
            <div
              id="bespokeCollapseSeven"
              className="collapse"
              aria-labelledby="bespokeHeadingSeven"
              data-parent="#bespokeAccordia"
            >
              <div className="card-body">
                <p>{t("our-services.faq.twelve.answer")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServicesFaq;
