import React from "react";
import caretDown from '../../../assets/report-static-assets/caret-down.svg'
function TableOfContentsAccordian(props) {
  const { sticky = true, children } = props;

  if (sticky) {
    return children;
  } else
    return (
      <div className="accordion my-3 table-of-contents pt-2" id="tableOfContentsID">
        <div className="card rounded-0">
          <div className="card-header p-0" id="tableOfContentsToggle" style={{backgroundColor:"#565D84"}}>
            <h2 className="mb-0">
              <button className="btn btn-block text-left text-uppercase pl-1 text-white d-flex justify-content-between align-items-center"
                type="button" data-toggle="collapse" data-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne">
                <p className="mb-0 toc-header-accordian pl-2">Table of Contents</p>
                <img src={caretDown} alt="caret down" className="caret-down" style={{width:20}}/>
              </button>
            </h2>
          </div>

          <div id="collapseOne" className="collapse" aria-labelledby="tableOfContentsToggle"
            data-parent="#tableOfContentsID">
            <div className="card-body">{children}</div>
          </div>
        </div>
      </div>
    );
}

export default TableOfContentsAccordian;
