import React from "react";
import "./subscribe.scss";
import { useTranslation } from "react-i18next";

function HomepageSubscribe(props) {
  const { showText = true } = props;
  const { t } = useTranslation();
  return (
    <div className="homepage-subscribe d-flex flex-column flex-md-row apply-padding justify-content-center align-items-center text-left animate__animated animate__fadeIn animate__delay-1s">
      {showText && <h4 className="text-white mr-3 font-weight-normal">
        {t("homepage.subscribe.get-instant-notifications")}
      </h4>}
      <form name="signuponly" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="signuponly" />
        <div className="input-group">
          <input
            type="email"
            className="form-control"
            placeholder={t("homepage.subscribe.input-placeholder")}
            aria-label={t("homepage.subscribe.input-placeholder")}
            aria-describedby="button-signup"
            name="email"
          />
          <div className="input-group-append">
            <button
              className="btn btn-primary"
              id="button-signup"
              type="submit"
            >
              {t("homepage.subscribe.subscribe")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default HomepageSubscribe;
