import React from "react";
export function ImpliedPE(props) {
  return (
    <div className="mt-4 seperator-top container-grey p-2">
      <h5 className="mt-3">Implied P/E valuation with actual and normalised earnings</h5>
      <table className="table custom-tables table-sm table-striped table-responsive">
      <caption>Source: Griffin Mining, Eightfold Research</caption>
        <thead>
          <tr>
            <th>Actual and estimated normalised production</th>
            <th>FY 2017</th>
            <th>FY 2018</th>
            <th>FY 2019</th>
            <th>1.0 MT</th>
            <th>1.5 MT</th>
            <th>2.0 MT</th>
          </tr>
        </thead>
        <tbody className="highlight-last-rows">
          <tr className="text-center">
            <td></td>
            <td colSpan="3" className="font-weight-bold">
              Actual Production
            </td>
            <td colSpan="3" className="font-weight-bold">
              Normalised Scenarios
            </td>
          </tr>

          <tr className="seperator-xtra">
            <td colSpan="7">
              <p className="font-weight-bold mb-0">Profit and Loss</p>
            </td>
          </tr>
          <tr>
            <td>Gross Revenue</td>
            <td>132</td>
            <td>104</td>
            <td>85</td>
            <td>96</td>
            <td>144</td>
            <td>191</td>
          </tr>
          <tr>
            <td>Resource Tax (%)</td>
            <td>-5%</td>
            <td>-5%</td>
            <td>-4%</td>
            <td>-5%</td>
            <td>-5%</td>
            <td>-5%</td>
          </tr>
          <tr>
            <td>Less Resource Tax</td>
            <td>-6.0</td>
            <td>-4.7</td>
            <td>-3.2</td>
            <td>-4.8</td>
            <td>-7.2</td>
            <td>-9.6</td>
          </tr>
          <tr className="seperator">
            <td>Net Revenue</td>
            <td>126</td>
            <td>99</td>
            <td>82</td>
            <td>91</td>
            <td>136</td>
            <td>182</td>
          </tr>
          <tr>
            <td>Less Cost of Goods Sold&nbsp;</td>
            <td>-33</td>
            <td>-35</td>
            <td>-36</td>
            <td>-35</td>
            <td>-54</td>
            <td>-73</td>
          </tr>
          <tr>
            <td>Less Depreciation</td>
            <td>-9.8</td>
            <td>-10.3</td>
            <td>-12.3</td>
            <td>-12.3</td>
            <td>-12.3</td>
            <td>-12.3</td>
          </tr>
          <tr>
            <td>Gross Profit</td>
            <td>82</td>
            <td>53</td>
            <td>33.7</td>
            <td>44</td>
            <td>70</td>
            <td>97</td>
          </tr>
          <tr>
            <td>Less SG&amp;A</td>
            <td>-19</td>
            <td>-18</td>
            <td>-19</td>
            <td>-20</td>
            <td>-20</td>
            <td>-20</td>
          </tr>
          <tr className="seperator">
            <td>EBIT</td>
            <td>64</td>
            <td>36</td>
            <td>14</td>
            <td>24</td>
            <td>50</td>
            <td>77</td>
          </tr>
          <tr>
            <td>Add Depreciation</td>
            <td>10</td>
            <td>10</td>
            <td>12</td>
            <td>12</td>
            <td>12</td>
            <td>12</td>
          </tr>
          <tr>
            <td>EBITDA</td>
            <td>74</td>
            <td>46</td>
            <td>27</td>
            <td>36</td>
            <td>63</td>
            <td>89</td>
          </tr>
          <tr>
            <td>Less Interest Expense</td>
            <td>-2</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>PBT</td>
            <td>62</td>
            <td>35</td>
            <td>14</td>
            <td>24</td>
            <td>50</td>
            <td>77</td>
          </tr>
          <tr>
            <td>Less Taxes @ 25%</td>
            <td>-18</td>
            <td>-10</td>
            <td>-4</td>
            <td>-6</td>
            <td>-13</td>
            <td>-19</td>
          </tr>
          <tr className="seperator">
            <td>Net Profit</td>
            <td>44</td>
            <td>25</td>
            <td>10</td>
            <td>18</td>
            <td>38</td>
            <td>58</td>
          </tr>
          <tr>
            <td>No. of Shares (Diluted)</td>
            <td>170</td>
            <td>188</td>
            <td>188</td>
            <td>188</td>
            <td>188</td>
            <td>188</td>
          </tr>
          <tr>
            <td>EPS in USD</td>
            <td>0.26</td>
            <td>0.13</td>
            <td>0.05</td>
            <td>0.09</td>
            <td>0.20</td>
            <td>0.31</td>
          </tr>
          <tr>
            <td>Share Price on 25 September 2020 (GBP)</td>
            <td>0.62</td>
            <td>0.62</td>
            <td>0.62</td>
            <td>0.62</td>
            <td>0.62</td>
            <td>0.62</td>
          </tr>
          <tr>
            <td>Share Price on 25 September 2020 (USD)</td>
            <td>0.80</td>
            <td>0.80</td>
            <td>0.80</td>
            <td>0.80</td>
            <td>0.80</td>
            <td>0.80</td>
          </tr>
          <tr className="seperator font-weight-bold">
            <td>Implied Price to Earnings</td>
            <td>3.11</td>
            <td>5.99</td>
            <td>14.95</td>
            <td>8.50</td>
            <td>3.98</td>
            <td>2.60</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
