import React from "react";
import "./section-two.scss";
import { useTranslation } from "react-i18next";

function SectionTwo(props) {
  const {t} = useTranslation();
  return (
    <div className="about-us-section-two bg-white animate__animated animate__fadeIn animate__delay-1s">
      <div className="container">
        <div className="row">
          <div className="col-md-4 d-flex text-left align-items-center py-3">
            <h1>{t('about-us.aspiring-to-be-a-global-leader')}</h1>
          </div>
          <div className="col-md-8 py-3 text-left">
            <p className="mb-0">
            {t('about-us.aspiring-to-be-a-global-leader-text')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionTwo;
