import React from "react";

export function HistoricalTimeline(props) {
  return (
    <div>
      <h5>Historical timeline of significant events in Griffin Mining</h5>
      <h5 className="text-dark mb-0 d-inline-block seperator"  >1994 to 2004</h5>
      <table className="table table-striped">
        <caption>Source: Eightfold Research, Griffin Mining</caption>
        <tbody>
          <tr>
            <td>1994</td>
            <td>
              On <span className="font-weight-bold">26 November 1997</span>,
              Griffin acquired 50% of the issued share capital of China Zinc Pty
              Limited (“China Zinc”) together with an option to acquire the
              remaining 50% of the issued share capital, for a consideration of
              USD million. This was satisfied by the issue 2,230,000 new
              ordinary shares in Griffin and cash of USD 538,000. Hebei Hua-Ao
              was a 60% subsidiary of China Zinc at the time.
            </td>
          </tr>
          <tr>
            <td>1998</td>
            <td>
              The call option to acquire the remaining 50% of China Zinc was
              exercised on 17 April 1998 satisfied by the issue of 3,500,000 new
              Griffin Mining shares. In October 1998, China Zinc, via its
              subsidiary Hebei Hua-Ao became the first foreign-controlled joint
              venture to be awarded a new exploration licence for a hard rock
              deposit in Chine when it received an exploration licence covering
              an area of 11.3 square kilometres at Caijiaying.
            </td>
          </tr>
          <tr>
            <td>2000</td>
            <td>
              Mining via underground mining methods was selected because of the
              low cost and long life of the operation in conjunction with an
              acceptable rate of return, as outlined by a scoping study
              completed in <span className="font-weight-bold">2000.</span>
            </td>
          </tr>
          <tr>
            <td>2002</td>
            <td>
              On <span className="font-weight-bold">21 March 2002</span>, Hebei
              Hua-Ao became the first foreign-controlled joint venture to be
              granted a mining licence over a base metals deposit in China when
              it was awarded a mining licence (Zone III) over 1.56 square
              kilometres of the original 11.3 square kilometre licence area at
              Caijiaying.
            </td>
          </tr>
          <tr>
            <td>2003</td>
            <td>
              Griffin commissioned CSA Australia to complete a full feasibility
              study (“the Study”) on zone III at Caijiaying, which was completed
              in August 2003. The study broadly followed the plan that was
              presented in the scoping study conducted in 2000 for a zinc-gold
              mine with an initial throughput of 200,000 tonnes per annum using
              a western-style decline and low-cost Chinese mining methods to
              feed a process plant to produce zinc concentrate. A gold
              processing plant was included in the design to make gold dore bars
              on-site for refining and sale. The basic structure of the mine
              incorporated a Chinese built processing plant using conventional
              crushing, grinding and flotation technology to produce standard
              zinc concentrate for sale to the local market. However, the gold
              circuit was of an Australian modular design to treat a precious-
              metals concentrate from which both gravity and cyanide- extracted
              gold could be won. The study indicated the need for total
              pre-production and working capital of US$15.7 million.
            </td>
          </tr>

          <tr>
            <td>2004</td>
            <td>
              In <span className="font-weight-bold">January 2004</span> a second
              contractual joint venture company, the Hebei Sino Anglo Mining
              Development Company Limited (“Hebei Anglo”), was formed to hold an
              exploration licence over 55.7 square kilometres surrounding the
              original 11.3 square kilometre licence area at Caijiaying and any
              other areas of interest in Hebei Province. Griffin Mining, through
              its wholly-owned UK subsidiary company Panda Resources Limited,
              had a 90% interest in Hebei Anglo. The other Chinese shareholders
              remained the same as in Hebei Hua-Ao. Their 10% interest remained
              free carried until the commissioning of a full feasibility study
              on any new mineral deposits found by Hebei Anglo. In May 2004,
              work commenced on the construction of the main processing plant
              buildings, administration and office buildings and supporting
              service facilities. Crucially these were structurally completed
              before the onset of winter, allowing for the installation of plant
              and equipment during the winter months.
            </td>
          </tr>
        </tbody>
      </table>

      <h5 className="text-dark mb-0 d-inline-block seperator  mt-4" >2005 to 2009</h5>
      <table className="table table-striped">
        <caption>Source: Eightfold Research, Griffin Mining</caption>
        <tbody>
          <tr>
            <td>2005</td>
            <td>
              In <span className="font-weight-bold">July 2005</span>, Griffin
              successfully commissioned the mine and processing facilities at
              Caijiaying with an initial design production rate of 200,000
              tonnes of ore per annum. This throughput was estimated to produce
              some 18,000 tonnes of zinc metal in concentrate. During{" "}
              <span className="font-weight-bold">2005</span>, the main
              production decline was developed to a depth of 200 vertical metres
              from the surface (1300 metres above sea level “the 1300 level”).
              The production and ventilation drive at the 1400 level was
              completed linking the main decline with the northern ventilation
              shaft.
            </td>
          </tr>
          <tr>
            <td>2007</td>
            <td>
              Griffin Mining declared a maiden dividend of USD 0.03 per share.
              Since commissioning approximately 18 months ago, throughput
              doubled from 200,000 tonnes of ore per annum to over 400,000
              tonnes of ore per annum in{" "}
              <span className="font-weight-bold">2007</span>. Zinc price fell
              46% in <span className="font-weight-bold">2007</span>, from USD
              4,100 in January to USD 2,200 in December. Griffin Mining issued
              68,181,818 shares to Citadel at GBP 1.10 per share in{" "}
              <span className="font-weight-bold">July 2007</span> to raise
              USD151.7 million. A new precious metal concentrate circuit
              containing gold, silver and lead was commissioned in December{" "}
              <span className="font-weight-bold">2007</span>.
            </td>
          </tr>
          <tr>
            <td>2008</td>
            <td>
              Zinc prices fell 50% in 2008 after falling 46% in 2007. Griffin
              Mining maintained its FY 2007 dividend of USD 0.03 per share and
              went on a lookout for undervalued assets to acquire. Griffin
              reviewed over 600 mining companies. Company’s annual processing
              throughput increased over 500,000 tonnes in{" "}
              <span className="font-weight-bold">2008</span>. A new mineable
              orebody at Zone II is discovered. It is only 1.5 kilometres from
              the Caijiaying processing facilities, allowing easy haulage at
              minimum cost. It provides an alternate source of ore to ease the
              scheduling of mining and haulage timetables at Zone III. It
              confirms the long-held view that Zone II and III are, in effect,
              one orebody. That prospect opens up the possibility of an
              additional 1.5 kilometres of mineralisation. In
              <span className="font-weight-bold"> May 2008</span>, Griffin Mining
              bought back its own shares from Citadel realising a gain of USD 30
              million. LME Zinc fell to USD 1,100 during the year. In
              <span className="font-weight-bold"> April 2008</span>, the company
              negotiated an agreed merger with Yukon Zinc Corporation, which was
              subsequently cancelled due to a higher takeover bid by a third
              party. Griffin Mining walked away from the transaction after
              receiving a CAD 2.5 million break-up fee. In
              <span className="font-weight-bold"> October 2008</span>, a further
              68,000 shares were purchased in the market for cancellation at an
              average price of GBP 0.146 per share.
            </td>
          </tr>
          <tr>
            <td>2009</td>
            <td>
              In <span className="font-weight-bold">January 2009</span>, a
              further 34,567 shares were repurchased for cancellation in the
              market at an average price of GBP 0.15 per share. Zinc prices
              collapsed further in the{" "}
              <span className="font-weight-bold">first half of 2009</span> due
              to the Global Financial Crisis. Griffin Mining shut down
              production in{" "}
              <span className="font-weight-bold">the first half of 2009</span>,
              placed staff on temporary leave, and undertook maintenance and
              extensive geological work. In
              <span className="font-weight-bold"> March 2009</span>, Griffin
              Mining made an unsolicited bid for a Canadian company, Ivernia
              Inc. The company’s management took a poison pill to deliver
              effective control, through massive dilution of its share capital,
              to a related party without shareholder approval. Mining in Griffin
              Mining commenced in{" "}
              <span className="font-weight-bold">June 2009</span> after five
              months of shutdown. The company did not declare an FY 2008
              dividend in 2009.
            </td>
          </tr>
        </tbody>
      </table>

      <h5 className="text-dark mb-0 d-inline-block seperator  mt-4" >2010 to 2013</h5>
      <table className="table table-striped">
        <caption>Source: Eightfold Research, Griffin Mining</caption>
        <tbody>
          <tr>
            <td>2010</td>
            <td>
              On <span className="font-weight-bold">15 January 2010</span>, the
              company received a permit to mine below 1300 MSL. It had
              previously restricted activity to above 1300 MSL (approximately
              120 metres below the surface) where ore lenses were less
              continuous. The new permit enabled access to larger and
              higher-grade ores. It also allowed for mechanical extraction and
              improved extraction rates. The company did not declare a dividend
              to retain funds for a further upgrade of processing capacity to
              750,000 tonnes per annum. Hebei Hua Ao was presented with the
              environmental award at the{" "}
              <span className="font-weight-bold">2010 </span>
               China Mining Conference.
            </td>
          </tr>
          <tr>
            <td>2011</td>
            <td>
              In <span className="font-weight-bold">January 2011</span>, mining
              resources were upgraded by 18% in Zone III and II ( from 32.8MT to
              38.6MT) representing a 30-year mine life at an annual ore
              production rate of 750,000 tonnes. Griffin Mining did not
              reinstate its dividend to save internal funds for opportunistic
              acquisitions. In the meantime, the company continued its share
              buyback programme. The company received the Mine Development
              Outstanding Achievement Award in China in{" "}
              <span className="font-weight-bold">2011</span>.
            </td>
          </tr>
          <tr>
            <td>2012</td>
            <td>
              Resources in Zone II and III increased by 32% representing 50+
              years of production. On{" "}
              <span className="font-weight-bold">10 May 2012</span>, the company
              increased stake in Hebei Hua Ao to 88.8% from 60% and extended
              term of the joint venture to October 2037 for approximately USD
              118.5 million. The purchase was financed with a combination of
              Griffin Mining’s existing cash resources, undistributed dividends
              from Hebei Hua Ao, Chinese banking facilities. There was an
              increase in effective rate of income tax for Griffin Mining in
              China from 12.5% to 25% due to the end of the pre-construction tax
              concessions enjoyed by Hebei Hua Ao Mining Company Limited and a
              withholding tax of 10% on dividends paid overseas. A fresh
              application of a Zone II mining license agreement was made for an
              initial annual ore production of 500 tonnes.
            </td>
          </tr>
          <tr>
            <td>2013</td>
            <td>
              The company management decided that investment in the Caijiaying
              Mine will generate higher returns for the company and its
              shareholders than any new, low return/high-risk investment
              elsewhere. The company continues to investigate a large number of
              potential mining ventures worldwide for opportunistic
              acquisitions. Work to access Zone II from the main Zone III
              decline begun in anticipation of the granting of the new mining
              licence in Zone II. Griffin Mining disposed its share in the
              Spitfire asset at the{" "}
              <span className="font-weight-bold">end of 2013</span> for a loss
              of USD 2.23 million.
            </td>
          </tr>
        </tbody>
      </table>

      <h5 className="text-dark mb-0 d-inline-block seperator  mt-4" >2014 to 2018</h5>
      <table className="table table-striped">
        <caption>Source: Eightfold Research, Griffin Mining</caption>
        <tbody>
          <tr>
            <td>2014</td>
            <td>
              Caijiaying Mine was in the active construction stage of increasing
              the mining and processing of ore to 1.5 million tonnes per annum.
              Significant progress was made in the application for a new mining
              licence in Zone II and the area between Zone II and Zone III.
              Development work commenced accessing the Zone II area underground
              from both declines. Increased number of underground drilling rigs
              from two to five to triple fold exploration drilling. Gold
              recoveries improved to 60% from improvements to the flotation
              circuit.
            </td>
          </tr>
          <tr>
            <td>2015</td>
            <td>
              Griffin Mining reported its first net loss in operating history.
              This was brought about by a combination of mining operations being
              suspended for three months and zinc metal prices falling through
              the year. Zone II licence application is delayed. New ball mill
              and grid connection increased processing capacity to a minimum of
              1.5million tonnes per annum. Mining tunnels in Zone II and III
              were connected.
            </td>
          </tr>
          <tr>
            <td>2017</td>
            <td>
              Griffin Mining paid off all debt with internal accruals and
              emerged a net cash company. Zone II licence is delayed. Circuit
              improvements and de-bottlenecking in the processing plant resulted
              in gold recoveries increasing from 58.2% in 2016 to 65.6% in{" "}
              <span className="font-weight-bold">2017</span>. A new 20-tonne
              haulage truck joined the underground haulage fleet.
            </td>
          </tr>
          <tr>
            <td>2018</td>
            <td>
              Zone II licence is delayed. Constructed and commissioned
              infrastructure in Zone II waits for this new source of ore to be
              mined and processed to increase metal production substantially.
              Griffin Mining carries out share buybacks in the open market.
              Finally, on <span className="font-weight-bold">11 July 2018</span>
              , Hebei Hua-Ao signed a contract with the Hebei Department of Land
              and Mineral Resources, and the Zone II mining licence application
              entered its final phase
            </td>
          </tr>
        </tbody>
      </table>

      <h5 className="text-dark mb-0 d-inline-block seperator  mt-4" >2019 and 2020</h5>
      <table className="table table-striped">
        <caption>Source: Eightfold Research, Griffin Mining</caption>
        <tbody>
          <tr>
            <td>2019</td>
            <td>
              Griffin Mining announced a 78.5% increase in mineral resource for
              Zone III. The full extraction of the new estimated resource will
              require the extension of Griffin Mining’s business licence beyond
              2037 or an increase in production capacity and enhanced permits.
              On <span className="font-weight-bold">8</span> August 2019, the
              Chinese Ministry of Natural Resources accepted the lodgement by
              Hebei Hua-Ao of its mining licence application for Zone II at the
              Caijiaying Mine. MNR expected to provide its approval and issuance
              of the new mining licence within 40-50 business days. On{" "}
              <span className="font-weight-bold">20 September 2020</span>, two
              further minor supplementary documents were requested by the MNR on
              13 August were lodged. MNR asked for further clarification
              concerning farming surface rights and suspended the acceptance
              period with effect from
              <span className="font-weight-bold"> 24th October 2019</span> until
              it accepts the required response from Griffin Mining.
            </td>
          </tr>
          <tr>
            <td>2020</td>
            <td>
              There was no further update from Griffin Mining on the Zone II
              MLA. On <span className="font-weight-bold">29 January 2020</span>,
              the company suspended mining operations in Caijiaying considering
              the restrictions placed due to the outbreak of CoVID-19.{" "}
              <span className="font-weight-bold">13 March 2020</span>, the
              company announced that its mining operations had reached 100%, and
              processing operations 75%, of planned output. Sales of stored
              concentrate started within a week.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
