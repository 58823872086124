import React from "react";
import "./section-nine.scss";
import prithish from "../../../assets/images/prithish.png";
import kris from "../../../assets/images/kris.png";
import { useTranslation } from "react-i18next";
function SectionNine(props) {
  const { t } = useTranslation();
  return (
    <div className="about-us-section-nine bg-white text-center animate__animated animate__fadeIn animate__delay-1s">
      <div className="container mb-5">
        <h3 className="mb-3">
          {t("about-us.the-founders-bring-a-value-investor")}
        </h3>
        <p className="mb-3">
          {t("about-us.eightfold-research-co-founders-kris-and-prithish")}
        </p>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img src={kris} alt="Kris Vidane" className="my-4" />
            <h3 className="my-2">{t("about-us.kris-vidane")}</h3>
            <p>{t("about-us.kris-vidane-designation")}</p>
            <p className="text-left">{t("about-us.kris-vidane-bio")}</p>
          </div>

          <div className="col-md-6 text-center">
            <img src={prithish} alt="Prithish Ray" className="my-4" />
            <h3 className="my-2">{t("about-us.prithish-ray")}</h3>
            <p>{t("about-us.prithish-ray-designation")}</p>
            <p className="text-left">{t("about-us.prithish-ray-bio")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionNine;
