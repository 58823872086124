import React, { useEffect, useState } from "react";
import TableOfContents from "./table-of-contents";
import ReportKeyInfoTable from "./report-key-info-table";
import TextPrimary from "./text-primary";
import TextNormal from "./text-normal";
import TextNormalWithHtml from "./text-normal-with-html";
import TextBold from "./text-bold";
import TextItalic from "./text-italic";
import TextHeader from "./text-header";
import ImageHalfWidth from "./image-half-width";
import ImageFullWidth from "./image-full-width";
import OrderedList from "./ordered-list";
import UnorderedList from "./unordered-list";
import MiniTable from "./mini-table";
import DetailedTable from "./detailed-table";
import FinancialTable from "./financial-table";
import ValuationProjectedGrowthTable from "./valuation-projected-growth-table";
import ReportImage from "./report-image";
import SubContainer from "./sub-container";
import "./report-body.scss";

function ReportBody(props) {

  const [companyData , setPageData] = useState(props.companyData);

  useEffect(() => {
      setPageData(props.companyData);
  }, [props]);

  var tocData = {};

  if(companyData && companyData.Sections) {
    tocData.Links = [];
    tocData.Links.push({ Description: companyData.Introduction.Title, ID: companyData.Introduction.SectionIdentifier });
    tocData.Links.push({ Description: companyData.Chart.Title, ID: companyData.Chart.SectionIdentifier });

    companyData.Sections.forEach(section => {
      tocData.Links.push({ Description: section.Section.Title, ID: section.Section.SectionIdentifier});
    });

    tocData.ReportDownloadLink = companyData.ReportDownloadFile;
  }

  return (
    <>
    <div className="container report-body">
      <div className="row main-section">
        <div className="col-md-7 pt-3">
          { companyData && companyData.Introduction && 
            <h1 className="report-header font-weight-bold" id={companyData.Introduction.SectionIdentifier}>{companyData.Introduction.Tagline}</h1>
          }

          {
            companyData && companyData.Introduction && companyData.Introduction.Contents &&
            companyData.Introduction.Contents.map((content) => {
              return getComponent(content.ContentItem)})
          }
        </div>
        <div className="col-md-5">
          <div className="shadow mb-3">
            <TableOfContents tocData={tocData} sticky={false} />
          </div>
          <div className="d-flex justify-content-center align-items-center shadow pt-4 pb-1">            
            {
              companyData.Logo && companyData.Logo.length > 0 ? 
              <img src={`${process.env.PUBLIC_URL}/company-report-data/${companyData.Logo}`} alt="Company logo" className="img-fluid mb-3" />
              : <h1 className="font-weight-bold mt-6 mb-6">{companyData.Name}</h1>
            }
          </div>
          <div className="shadow p-2">
            {             
              companyData && companyData.KeyInformationInTabularFormat && companyData.KeyInformationInTabularFormat.length > 0 &&          
              companyData.KeyInformationInTabularFormat.map((infoTable, index) => {
                return <ReportKeyInfoTable tableData={infoTable.Table} />
              })
            }
          </div>
        </div>
      </div>
      <div className="row list-section mt-5">
        <div className="col-md-4 order-md-1">
          <TableOfContents tocData={tocData} sticky={true} />
        </div>

        <div className="col-md-12">
          {
            companyData && companyData.Chart && 
            <div id={companyData.Chart.SectionIdentifier}>
              <h2 className="text-left pb-2">{companyData.Chart.Title}</h2>
              <img src={`${process.env.PUBLIC_URL}/company-report-data/${companyData.Chart.Image}`} className="img-fluid" alt="Stock performance" />
            </div>
          }
        </div>
          
        { 
          companyData && companyData.Sections && 
          <div className="col-md-8">
            {
              companyData && companyData.Sections && companyData.Sections.map((section, index) => {
                return (<>
                <div id={section.Section.SectionIdentifier} className="mt-5">
                  <h2 className="text-left mb-4 seperator pb-2">{section.Section.Title}</h2>
                </div>
                { 
                  section && section.Section && section.Section.Contents && section.Section.Contents.map((content) => {
                    return getComponent(content.ContentItem)
                  })
                }
                </>)
              })
            }
          </div>
        }
        
      </div>
    </div>
    </>
    
  );
}

function getComponent (contentItem) {
  switch(contentItem.Type) {
    case 'text-normal':
      return <TextNormal text={contentItem.Value}/>
    case 'text-normal-with-html':
      return <TextNormalWithHtml text={contentItem.Value}/>
    case 'text-bold':
      return <TextBold text={contentItem.Value}/>
    case 'text-primary':
      return <TextPrimary text={contentItem.Value}/>
    case 'text-italic':
      return <TextItalic text={contentItem.Value}/>
    case 'text-header':
      return <TextHeader text={contentItem.Value}/>
    case 'image':
      return <ReportImage imageContent={contentItem.Value} />
    case 'image-full-width':
      return <ImageFullWidth imageContent={contentItem.Value} />
    case 'image-half-width':
      return <ImageHalfWidth imageContent={contentItem.Value} />
    case 'ordered-list':
      return <OrderedList listData={contentItem.Value} />
    case 'unordered-list':
      return <UnorderedList listData={contentItem.Value} />
    case 'mini-table':
      return <MiniTable tableData={contentItem.Value} />
    case 'detailed-table':
      return <DetailedTable tableData={contentItem.Value} />
    case 'financial-table':
      return <FinancialTable tableData={contentItem.Value} />
    case 'valuation-projected-growth-table':
      return <ValuationProjectedGrowthTable tableData={contentItem.Value} />
    case 'sub-container':
      return <SubContainer containerItems={contentItem.Value} />
    default:
      return <div>-----</div>
  }
}

export default ReportBody;
