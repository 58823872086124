import React from "react";
import stockPerfomance from "../../../assets/images/performance.png";
import "./report-body.scss";
import { AssetsOverTime } from "./tables/assets-over-time.js";
import holdingsStructure from "../../../assets/report-static-assets/holdings_structure.png";
import operatingPerformanceOne from "../../../assets/report-static-assets/operating-performance-1.png";
import operatingPerformanceTwo from "../../../assets/report-static-assets/operating-performance-2.png";
import operatingPerformanceThree from "../../../assets/report-static-assets/operating-performance-3.png";
import balanceSheetAndCapitalStructureOne from "../../../assets/report-static-assets/balance-sheet-and-capital-structure-1.png";
import balanceSheetAndCapitalStructureTwo from "../../../assets/report-static-assets/balance-sheet-and-capital-structure-2.png";
import balanceSheetAndCapitalStructureThree from "../../../assets/report-static-assets/balance-sheet-and-capital-structure-3.png";

import FnFAndProfitabilityOne from "../../../assets/report-static-assets/fcf-and-profitability-1.png";
import FnFAndProfitabilityTwo from "../../../assets/report-static-assets/fcf-and-profitability-2.png";
import FnFAndProfitabilityThree from "../../../assets/report-static-assets/fcf-and-profitability-3.png";

import HistoricalValuationsOne from "../../../assets/report-static-assets/historical-valuations-1.png";

import EarningsBasedValuationRatios from "../../../assets/report-static-assets/earningss-based-valuation-ratios-1.png";

import resourceAndProductionOne from "../../../assets/report-static-assets/resources-and-production-1.png";
import resourceAndProductionTwo from "../../../assets/report-static-assets/resources-and-production-2.png";
import resourceAndProductionThree from "../../../assets/report-static-assets/resources-and-production-3.png";
import resourceAndProductionFour from "../../../assets/report-static-assets/resources-and-production-4.png";
import resourceAndProductionFive from "../../../assets/report-static-assets/resources-and-production-5.png";
import resourceAndProductionSix from "../../../assets/report-static-assets/resources-and-production-6.png";
import logo from "../../../assets/report-static-assets/logo.png";
import { ProductionAndMetalConcentrates } from "./tables/production-and-metal-concentrates";
import { ImpliedPE } from "./tables/implied-pe";
import { HistoricalTimeline } from "./tables/historical-timeline";
import KeyBalanceAndIncome from "./tables/key-balance-and-income";
import KeyCashFlowFinancials from "./tables/key-cash-flow-financials";
import KeyFinancialsAndValuations from "./tables/key-financials-and-valuations";

import TableOfContents from "./table-of-contents";
function ReportBody(props) {
  return (
    <div className="container report-body">
      <div className="row main-section">
        <div className="col-md-6 pt-3">
          <h1 className="report-header font-weight-bold">Unlocking value from the deep</h1>
          <p className="my-5 text-blue report-sub-header">
            Griffin Mining presents an opportunity to invest in a debt-free,
            high-quality, low-cost zinc-gold mine. The company is trading at a
            35% discount to its net asset value. Griffin Mining could soon
            increase its production by 1.5X to 2X for minimal additional
            investments. We estimate that the company is currently trading at
            3x-4x its normalised EPS in the increased production scenarios. The
            company is led by a management team that likes to think like owners
            and has demonstrated sound capital allocation skills
          </p>
          <p className="font-weight-bold">
            Debt-free, low-cost, and profitable mine
          </p>
          <p className="mb-4">
            Griffin owns a top quartile low-cost underground
            zinc-gold-silver-lead production mine in China. The underground mine
            is located around 250 Km from Beijing in Hebei province where the
            weather allows 365-days-a-year operation. The mine has excellent
            access to roads, power, and water.
          </p>
          <p className="font-weight-bold">
            Capable and aligned management team
          </p>
          <p className="mb-4">
            Griffin’s management team has shown that it likes to think like an
            owner. It successfully commissioned a low-cost mine in 2005 without
            any debt. Since then, the team has kept the mine profitable and
            quintupled production capacity using internal cash flows. Large
            insider holdings and stock options make management incentives
            aligned with shareholders.
          </p>

          <p className="font-weight-bold">
            Upside potential, with downside protection
          </p>
          <p className="mb-4">
            Griffin is trading at two-thirds its net asset value which offers
            substantial downside protection. Additionally, a recent doubling of
            capacity and a pending second mining licence could put Griffin in a
            position to increase its production by at least 1.5X
          </p>
        </div>
        <div className="col-md-6 ">
          <div className="shadow mb-3">

            <TableOfContents sticky={false} />
          </div>
          <div className="d-flex justify-content-center align-items-center shadow">
            <img src={logo} alt="Company logo" className="img-fluid mb-3" />
          </div>
          <div className="shadow p-1">

            <table className="table table-sm  table-borderless intro-table">
              <thead className="shadow">
                <tr>
                  <th scope="col">LISTING DETAILS</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Ticker</th>
                  <td>GFM</td>
                </tr>
                <tr>
                  <th scope="row">Primary Exchange</th>
                  <td>London Stock Exchange</td>
                </tr>
                <tr>
                  <th scope="row">Stock Price (25/09/2020)</th>
                  <td>GBP 0.62</td>
                </tr>
                <tr>
                  <th scope="row">52 Week High/Low</th>
                  <td>GBP 0.90/ 0.30</td>
                </tr>
                <tr>
                  <th scope="row">Shares Outstanding</th>
                  <td>172.7 m</td>
                </tr>
                <tr>
                  <th scope="row">Market Capitalisation</th>
                  <td>GBP 106 m</td>
                </tr>
                <tr>
                  <th scope="row">Daily Avg Trading Value</th>
                  <td>GBP 72,660</td>
                </tr>
                <tr>
                  <th scope="row">Free Float</th>
                  <td>68.0%</td>
                </tr>
              </tbody>
            </table>

            <table className="table table-sm table-borderless intro-table">
              <thead className="shadow">
                <tr>
                  <th scope="col">CAPITAL STRUCTURE</th>
                  <th scope="col">GBP</th>
                  <th scope="col">USD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Market Cap</th>
                  <td>106.2</td>
                  <td>134.9</td>
                </tr>
                <tr>
                  <th scope="row">Cash & STI</th>
                  <td>8.1</td>
                  <td>10.4</td>
                </tr>
                <tr>
                  <th scope="row">Long Term Inv</th>
                  <td>0.0</td>
                  <td>0.0</td>
                </tr>
                <tr>
                  <th scope="row">Total Debt</th>
                  <td>1.3</td>
                  <td>1.7</td>
                </tr>
                <tr>
                  <th scope="row">Preferred Equity</th>
                  <td>0.0</td>
                  <td>0.0</td>
                </tr>
                <tr>
                  <th scope="row">Net Debt (Cash)</th>
                  <td>-6.8</td>
                  <td>-8.6</td>
                </tr>
                <tr>
                  <th scope="row">Non Contr interests</th>
                  <td>0.0</td>
                  <td>0.0</td>
                </tr>
                <tr>
                  <th scope="row">Enterprise Value</th>
                  <td>99.4</td>
                  <td>126.3</td>
                </tr>
                <tr>
                  <th scope="row">BV/Share</th>
                  <td>1.0</td>
                  <td>1.2</td>
                </tr>
                <tr>
                  <th scope="row">Tang. BV/Share</th>
                  <td>1.0</td>
                  <td>1.2</td>
                </tr>
              </tbody>
              <caption>
                <p className="font-italic mb-0 font-weight-lighter">*All numbers in millions except per share items</p>
              </caption>
            </table>

            <table className="table table-sm table-borderless intro-table">
              <thead className="shadow">
                <tr>
                  <th scope="col">VALUATION</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Price/BV</th>
                  <td>0.6x</td>
                </tr>
                <tr>
                  <th scope="row">Price/Tang. BV</th>
                  <td>0.6x</td>
                </tr>
                <tr>
                  <th scope="row">EV/LTM EBITDA</th>
                  <td>4.8x</td>
                </tr>
                <tr>
                  <th scope="row">EV/LTM EBIT</th>
                  <td>10.3x</td>
                </tr>
                <tr>
                  <th scope="row">EV/LTM Operating Income</th>
                  <td>10.3x</td>
                </tr>
                <tr>
                  <th scope="row">P/LTM E</th>
                  <td>N/A</td>
                </tr>
                <tr>
                  <th scope="row">ROE (5 yr avg)</th>
                  <td>8.0%</td>
                </tr>
                <tr>
                  <th scope="row">Dividend Yield</th>
                  <td>0.0%</td>
                </tr>
                <tr>
                  <th scope="row">Dividend Payout Ratio</th>
                  <td>0.0%</td>
                </tr>
              </tbody>
            </table>

            <table className="table table-sm  table-borderless intro-table">
              <thead className="shadow">
                <tr>
                  <th scope="col">NEXT EVENT</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">FY 2020 Results</th>
                  <td>April 2021</td>
                </tr>
              </tbody>
            </table>

            <table className="table table-sm  table-borderless intro-table">
              <thead className="shadow">
                <tr>
                  <th scope="col" colSpan="2">COMPANY WEBSITE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row"></th>
                  <td><a href="http://www.griffinmining.com">www.griffinmining.com</a></td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
      <div className="row list-section mt-5">
        <div className="col-md-4 order-md-1">
          <TableOfContents />
        </div>

        <div className="col-md-12">
          <div id="stock-price-performance">
            <h2 className="text-left mb-4 pb-2">Stock Price Performance</h2>
            <img
              src={stockPerfomance}
              className="img-fluid"
              alt="Stock performance"
            />
          </div>
        </div>
        <div className="col-md-8">
          <div id="executive-summary" className="mt-5">
            <h2 className="text-left mb-4 seperator pb-2">Executive Summary</h2>
            <p>
              Mining is a fixed cost business whose profitability is governed by
              the swings of the commodity market. All mining operators are
              hostages to the vagaries of the business cycle, and only the best
              prepared players come out as the winners in the long run. There
              are a few factors that can give mining companies a sustainable
              advantage in this game.
            </p>
            <p>
              Being a low-cost producer, ideally a top-decile or a top-quartile
              mine in the industry, ensures profitability even in downturns when
              other competitors are bleeding themselves blue.
            </p>
            <p>
              Ability to halt production when markets are not conducive is
              another long-term advantage. This usually stems from a combination
              of low- fixed costs, no-debt and interest payment overhangs, and
              no hedging commitments. A lack of these factors often forces
              mining companies to continue operating in unfavourable markets
              simply to remain solvent, even when it is a loss-making and
              value-destroying activity.
            </p>
            <p>
              Ability to hold cash for opportunistic acquisitions is the next
              significant advantage for long-term players in the mining
              industry. As in most other sectors, real value is created by
              purchasing mining assets below their intrinsic value, often at the
              low end of an economic cycle or in a severe financial downturn.
              Substantial shareholder value can be created by buying assets from
              motivated sellers, who often due to their urge to grow fast take
              on unsustainable amounts of leverage. In a sense, it is a waiting
              game, and players who have the capital discipline have an
              advantage.
            </p>
            <p>
              Griffin Mining is a debt-free, UK-listed, low-cost mining company
              and has no future or forward contract liabilities. It gives
              Griffin the ability to close mining activity and sit out the storm
              whenever Zinc prices do not satisfy a specific hurdle. In
              comparison, highly leveraged mines have no choice but to keep on
              producing ore at a loss just to service interest payments or
              hedging contracts.
            </p>
            <p>
              Griffin Mining’s primary asset is an 88.8% stake in a
              high-quality, low- cost, zinc-gold-silver-lead production mine
              near Caijiaying in Hebei province near Beijing, China.
            </p>
            <p>
              The Caijiaying Zone III mine was commissioned in 2005 and is
              currently capable of producing a combined one million tonnes of
              zinc, gold, silver, and lead ore per year. The company is close to
              getting a mining licence in another area near by – also known as
              Zone II. It plans to initially extract 500,000 tonnes of ore per
              year from Zone II.
            </p>
            <p>
              Griffin’s currently operating mine - the Caijiaying Zone III mine
              is a sustainable and profitable underground mine that uses
              cost-effective Chinese mining methods along with a western-style
              “decline,” i.e., a sloped access tunnel to haul out ore
              efficiently. It is a top-quartile low- cost zinc-gold-silver-lead
              production mine with cash costs of zinc extraction of around
              620-700 USD per tonne. That has enabled the mine to remain
              profitable even when zinc and other commodity markets were facing
              a considerable downturn within the last fifteen years.
            </p>
            <p>
              Construction of the mine commenced in 2003 with an estimated
              budget of USD 15-20 million. Construction of the mine and
              processing plant was completed within budget, and they were
              commissioned in 2005. It is worth noting that the decision to
              commence production came after a final feasibility study even
              though the price of zinc at the time was just USD 760 per tonne.
              In comparison, the lowest price of zinc in the last decade has
              been USD 1,470 in 2015.
            </p>
            <p>
              The mine started production in 2005 with an initial capacity of
              200,000 tons of ore per year. Griffin Mining did not use financial
              debt for the development of the mine and processing plant. The
              mine achieved break-even in the first year of operations and has
              been profitable every year at the operating profit level,
              including 2009, when zinc prices fell below USD 1,200 per tonne.
              Since the start of operations, the company has increased its
              processing capacity to 1,500,000 tonness per year using its
              internal cash flows.
            </p>
            <p>
              In our opinion, Griffin Mining offers a wide margin of safety for
              patient shareholders in the form of downside protection on the
              assets side. The company’s shares are trading at roughly 65% of
              its net asset value and tangible book value. These valuations were
              carried out by Griffin Mining assuming a long-term zinc price of
              USD 2,230 per tonne. The long-term assumption does not seem
              out-of- place as it is roughly equal to the mean zinc price over
              the last ten years. With demand expected to rise from emerging
              countries, and new mines getting tougher to find, we think an
              assumption at this level seems reasonable. Although the price of
              zinc dipped to as low as USD 1,800 in March 2020, zinc prices have
              recovered to 2,300 to 2,500 in the last couple of months.
            </p>
            <p>
              Additionally, we identify potential sources of upside from an
              increase in mining production. Griffin’s management is working
              towards a licence for a second production mine in Zone II, south
              of the first mine in Zone III. The extended application process
              entered the final stage in August 2019. In anticipation of the
              second mining licence, Griffin doubled its stated processing
              capacity from 750,000 tonnes per year to 1.5 million tonnes per
              year in 2018/19. Much of this capacity is currently lying idle,
              ready to take up supply from the second mine.
            </p>
            <p>
              The plant’s capacity can be further upgraded to process 2 million
              tonnes at minimal costs, according to the management. This puts
              Griffin Mining in an advantageous position to double their total
              zinc production without incurring significant capital
              expenditures.
            </p>
            <p>
              Griffin Mining is led by an experienced management team that
              thinks like an owner. Since 2005, it has run the mine profitably.
              It has been successful in increasing the mine’s production
              capacity by nearly 5x and processing plant capacity by 7.5x using
              internal cash flows only.
            </p>
            <p>
              We believe that incentives of the board and management of Griffin
              Mining are well aligned with common shareholders. Insiders hold
              nearly 20% of the company, own a substantial amount of stock
              options, and have bought shares in the open market as recently as
              March 2020.
            </p>
            <p>
              At the helm of the company is Mladen Ninkov, executive chairman
              since 2001. As his job title suggests, Mladen doubles up as the
              CEO and the chairman of the company. He is a capable leader with a
              relevant background. Over the last two decades, he has built a
              track record by profitably navigating the company through the ups
              and downs of metals and mining business cycles.
            </p>
            <p>
              The management team has shown good capital discipline over the
              year in developing a robust and low-cost mine by avoiding some of
              the typical and tempting pitfalls of mine development and
              fundraising. Mladen also demonstrates a good understanding of
              Value Investing concepts and likes to think like an owner. He is
              not afraid to think like a contrarian investor, and in the past,
              has generated value by making opportunistic bids and buybacks.
            </p>
          </div>
          <div id="sector-brief" className="mt-5">
            <h2 className="text-left mb-4 seperator pb-2">Sector Brief</h2>
            <p>
              Zinc is one of the world’s most widely used base metals. It is
              integral for many of the products we rely on in today’s society
              like automotive parts and construction materials. As global
              urbanisation continues to grow, so too will the demand for zinc.
            </p>
            <p>
              The zinc market stands at around 12-15 million tonnes of metal per
              annum, of which the majority, comes from newly mined metal with
              the balance from recycling. The majority of zinc produced is used
              for galvanising steel in various forms to prevent corrosion.
            </p>
            <p>
              Global zinc mine production in 2019 was estimated to be 13 million
              tonnes. The largest producers were China (34%), Peru (11%),
              Australia (10%), United States (6.1%), India (5.5%), and Mexico
              (5.4%), with Australia having the largest reserves.
            </p>
            <p>
              In the early part of the last decade, there were a limited number
              of new mine openings and a series of mine closures (e.g. Glencore
              Xstrata’s Perseverance and Brunswick mines, Vedanta’s Lisheen
              operation and MMG’s Century Zinc mine in Australia) which
              tightened Zinc supply in 2017-18.
            </p>
            <p>
              Global mine production of zinc in 2019 was estimated at 12.9
              million tons or a 0.9% increase from 2018, with the increase
              primarily attributed to the increased output from zinc mines
              located in Australia and South Africa.
            </p>
            <p>
              In 2020 production of zinc is expected to rise 3.7% to 13.99
              million tons, with this increase caused by increased production in
              China and India.
            </p>
            <p>
              In 2019, the global demand for refined zinc exceeded supply. It
              resulted in a deficit of 0.178 million tonnes, while in 2020 there
              is an expected surplus of 0.192 million tonnes.
            </p>
            <p>
              Zinc occurs throughout the world with Canada, Australia, the USA
              and Peru traditionally providing most of the mined zinc, however,
              in recent years China has become a significant producer having a
              market share of almost 40% of newly mined zinc. Yet, China
              consumes more zinc than it produces.
            </p>
            <p>
              There are few large mines in China, but hundreds of small mines
              dominate production. China’s zinc production has declined slightly
              over the past couple of years due to falling ore grades and
              increasingly stringent environmental regulations. As a result,
              some geologists believe that Chinese mine production is going to
              enter a long period of decline.
            </p>
            <p>
              The demand-supply gap for zinc in China is often reflected in
              slightly higher zinc prices in Chinese local markets and exchanges
              than those seen on the London Metals Exchange.
            </p>
          </div>
          <div id="company-description" className="mt-5">
            <h2 className="text-left mb-4 seperator pb-2">Company Description</h2>
            <p className="font-weight-bold">Brief Description of the Company</p>
            <p>
              Griffin Mining Limited is a mining and investment company,
              incorporated in Bermuda, whose shares are quoted on the
              Alternative Investment Market of the London Stock Exchange
              (“AIM”).
            </p>
            <p>
              Griffin Mining, through its 88.8% owned subsidiary Hebei Hua Ao,
              holds exploration licence tenements over 3.9 sq km at Caijiaying
              with a mining licence over some 1.5 sq km. Within these licence
              areas, there are eight zones of mineralisation, one of which, Zone
              III, is currently being mine.
            </p>
            <p>
              The Zone III mine is one of the lowest-cost (top quartile)
              production mines in the world. It has been profitable at the
              operating profit level every year since its commissioning in 2005.
            </p>
            <p>
              Zone III mine was a low-cost mine to develop. It started
              production in 2005 with an initial annual production capacity of
              200,000 tonnes of ore with a capital outlay of just USD 15
              million. The company achieved this in a debt-free manner by
              raising common stock and additional paid- in capital. The
              management avoided raising any debt at the time as it gave them
              the ability to keep mine development costs in control.
            </p>
            <AssetsOverTime />
            <p className="font-weight-bold">Company Background</p>
            <p>
              The company was initially created in 1988 as a Bermuda based
              mining finance company called European Mining Finance Ltd. After
              operating as a mining finance company for nearly a decade, the
              management listed it on the Alternative Investment Market (“AIM”)
              of the London Stock Exchange in June 1997.
            </p>
            <p>
              Soon after, in October 1997, the company changed its principal
              activity to mining, and it rechristened itself “Griffin Mining
              Limited” following a change in management. A month later, Griffin
              acquired a 50% equity interest in China Zinc Pty Ltd which held a
              60% interest in the Hebei Hua Ao Mining Industry Company Limited.
              Hebei Hua Ao is a Chinese joint venture company which holds, among
              other assets, exploration rights around Caijiaying in Hebei
              Province in China. In the following year, Griffin Mining acquired
              the remaining 50% equity stake in China Zinc Pty Ltd, giving it a
              controlling 60% interest in Hebei Hua Ao. Later, in 2012, Griffin
              Mining bought an additional 28.8% in the JV. Now, the main holding
              of Griffin Mining is an 88.8% interest in Hebei Hua Ao Mining
              Industry Company Limited (“Hebei Hua Ao”), which holds 3.9 sq. km.
              of mining and exploration licences in addition to the mine and
              processing facilities at the Caijiaying Mine in the People’s
              Republic of China. Griffin Mining also owns 90% of Hebei Sino
              Anglo Mining Industry Company Limited (“Hebei Anglo”), which holds
              15.7 sq. km. of exploration licences around the Caijiaying Mine.
            </p>
            <img
              className="img-fluid mb-5"
              alt="Holdings Structure"
              src={holdingsStructure}
            />
            <p className="font-weight-bold">Caijiaying Mine</p>
            <p>
              The Caijiaying area is on the south-east edge of the Mongolian
              Plateau. Conditions are not severe, although winters are cold and
              dry. Mining operations can be carried out throughout the year.
            </p>
            <p>
              Caijiaying is easily accessible by two separate freeways from
              Beijing. The site has significant water supplies, two 35 thousand
              volts “KV” power lines connected to the electricity grid, full
              connectivity to fixed and mobile telecommunications systems and
              broadband access for internet services.
            </p>
            <p>
              After carrying out prospecting and exploration in the Caijiaying
              mining area in 1998-2001, the management decided to develop a
              low-cost underground mine with an approximate cost of USD 15m.
              Griffin applied for a mining licence (MLA) for Caijiaying Zone III
              in 2001 and received it in 2002 (although restricted to above 1300
              metre level at the time.)
            </p>
            <p>
              Following a feasibility study and a final round of equity
              fundraising, the Caijiaying Mine was commissioned on time and
              budget in 2005 with an initial design production throughput rate
              of 200,000 tonnes of ore per annum.
            </p>
            <p>
              In December 2007, Griffin Mining started production of separate
              precious metals concentrate containing gold, silver and lead in an
              integrated circuit at the main processing facilities at
              Caijiaying. This allowed the full economic benefit of these metals
              to be obtained. Previously gold, silver and lead were not
              recoverable by the company.
            </p>
            <p>
              Numerous upgrades to the Caijiaying Mine and processing facilities
              have taken place since commissioning. The latest upgrade has taken
              the nameplate mill throughput capacity to 1.5 million tonnes of
              ore per annum.
            </p>
            <p>
              In June 2012, Griffin Mining acquired a further 28.8% interest in
              Hebei Hua Ao taking its stake to 88.8% and at the same time
              negotiated an extension of the term of the Hebei Hua Ao Joint
              Venture for a further 25 years to October 2037.
            </p>
            <p>
              In January 2016, the company completed a further upgrade of the
              processing facilities at the Caijiaying mine. It constructed two
              new 35 KV commercial electric power lines connected to the main
              grid enabling a new third primary ball mill to be commissioned at
              the ore processing plant. This latest upgrade has taken the
              nameplate capacity to 1.5 million tonnes of ore per annum.
            </p>
            <p>
              Underground development continues with the expansion of the
              existing mining operations at Zone III down to the 1,000 relative
              level.
            </p>
            <p>
              Access to the Zone II area to the south of Zone III has been
              constructed allowing for underground drilling and exploration at
              Zone II. The mining and development of Zone II are subject to the
              successful granting of a new mining licence over that area.
            </p>
            <p>
              Hebei Hua Ao was the first foreign- controlled entity to receive
              an exploration licence in China and the first to be awarded a new
              mining licence over a metal deposit in China, as well as being the
              first to construct a new mine.
            </p>
            <p>
              Griffin’s Chinese partner in Hebei Hua-Ao is the Zhangjiakou
              Guoxin Enterprise Management and Service Centre (formerly the
              Zhangjiakou Caijiaying Lead Zinc Mining Company), a partnership
              comprising of the Zhangjiakou City Government, and the Third
              Geological Brigade, of the Hebei Province. Griffin maintains good
              relations with the Chinese authorities at the township, county,
              provincial, and state levels, which has perhaps enabled Griffin to
              advance its Caijiaying project further than any other
              foreign-controlled mining project in China.
            </p>
            <p>
              In January 2004, a second contractual joint venture company, Hebei
              Anglo, was formed to hold the mineral rights to the area
              surrounding the original Hebei Hua Ao licence area and any other
              areas of interest in Hebei Province.
            </p>
            <p className="font-weight-bold">
              Key Drivers of Revenue and Profitability
            </p>
            <p>
              Griffin Mining sells zinc concentrate to Chinese smelters and
              other interested parties in China via an auction process held at
              Caijiaying each month. The price is calculated via a three-round
              auction process. Payment from the successful bidder is received in
              advance before the release of the concentrate from the company
              site.
            </p>
            <p>
              The company’s revenues are impacted the most by the prices of zinc
              on the local Chinese and global commodities market. Processed zinc
              concentrate is auctioned at the company’s premises to zinc
              smelters, who then use an electrolytic process to reduce the
              concentrate to pure zinc. Zinc smelting treatment costs impact
              sales price achieved by Griffin Mining. Smelters typically deduct
              their smelting spreads from the commodity market price of zinc
              metal before buying from mines.
            </p>
            <p>
              Depending on the outlook of the commodities market, Griffin Mining
              determines its best strategy regarding the amount of production,
              ore stockpiling, and sales.
            </p>
            <p>
              Zinc treatment prices are quite volatile. Over the last few years,
              zinc treatment costs reached a peak up around USD 300 per tonne in
              2019-20 due to some smelting units going offline owing to stricter
              environmental regulation in China. Charges had reduced to around
              USD 250 per tonne by April 2020 as the supply of zinc concentrate
              dipped due to CoVID-19 related mine closures.
            </p>
            <p>
              Zinc metal prices have also declined in the last couple of years
              after peaking at around 2017-18. Global zinc supply is strongly
              affected by the significant zinc mines in the world being retired
              and new ones coming online.
            </p>
          </div>
          <div id="board-and-management" className="mt-5">
            <h2 className="text-left mb-4 seperator pb-2">Board and Management</h2>
            <p className="font-weight-bold">Shareholder Aligned Management</p>
            <p>
              Griffin Mining’s board and management team consist of a mix of
              individuals with considerable experience in Geology, Finance, and
              Management. Two board members of the company own a combined 19.4%
              of the company. Adam Usdan holds a vast majority of this holding
              with 19.2%, while Dal Brynelsen holds 0.2%. Both board directors
              have bought shares in the open market in the last year.
            </p>
            <p>
              Also, members of the board and management hold a substantial
              amount of vested and unvested options exercisable at GBP 0.30 and
              GBP 0.40. Some details can be found on the London Stock Exchange
              website{" "}
              <a
                href="https://www.londonstockexchange.com/news-article/GFM/extension-of-share-purchase-options/13874014"
                target="_blank"
                rel="noopener noreferrer"
              >
                here.
              </a>
            </p>
            <p className="font-weight-bold mt-5">Key Board Members</p>
            <p className="font-weight-bold">
              Mladen Ninkov, Executive Chairman of the Board
            </p>
            <p>
              Mladen has been the chairman of Griffin Mining since 2001. He has
              a mining, legal, fund management, and investment banking
              background. He is the principal of Keynes Capital, a company
              through which he collects his fees for serving Griffin Mining
              under a consultancy agreement. He was the Chairman and Managing
              Director of the Dragon Capital Funds management group, a director
              and Head of International Corporate Finance at ANZ Grindlays Bank
              Plc in London, and a Vice President of Prudential-Bache Securities
              Inc. in New York.
            </p>
            <p>
              He also worked at Skadden Arps Slate Meagher & Flom in New York
              and Freehill Hollingdale & Page in Australia. He has been chairman
              and director of a number of both public and private mining
              companies. Mladen holds a Master of Law Degree from Trinity Hall,
              Cambridge and Bachelor of Laws (with Honours) and Bachelor of
              Jurisprudence Degree from the University of Western Australia.
            </p>
            <p className="font-weight-bold">
              Roger Goodwin, Finance Director, Company Secretary & Executive
              Director
            </p>
            <p>
              Roger is the finance director and company secretary of Griffin
              Mining. He has been with the company since 1996. He has held
              senior positions in several public and private companies within
              the natural resources sector. Roger is a chartered accountant and
              a fellow of the Institute of Chartered Accountants in England and
              Wales. He was named as one of the UK’s top 100 finance directors
              by Finance Week magazine.
            </p>
            <p>
              Roger has a strong professional background, including that as a
              manager with KPMG, and a large public company. He also has
              corporate finance experience, and experience of emerging markets
              particularly in Africa, the CIS and Eastern Europe.
            </p>
            <p className="font-weight-bold">
              Dal Brynelsen, Independent Non-Executive Director
            </p>
            <p>
              Dal serves as an independent non- executive Director at Griffin
              Mining Limited. He is a graduate of the University of British
              Columbia in Urban Land Economics. Dal has been involved in the
              resource industry for over 30 years. He has been responsible for
              the discovery, development and operation of several underground
              gold mines during his career.
            </p>
            <p>
              In the past, Dal has served as the CEO, and later chairman, of
              Vangold Mining Corp. He has also served as the CEO of Vanoil
              Energy. He is also involved in the real estate business of
              Brynelsen Benzon Realty in Canada since June 1976.
            </p>
            <p className="font-weight-bold">
              Rupert Crowe, Independent Non-Executive Director
            </p>
            <p>
              Rupert has been an independent non- executive director at Griffin
              Mining since September 2013. He is a graduate geologist from
              Trinity College Dublin. As a consultant, he was responsible for
              the re- interpretation of the Caijiaying Zone III pre- mining
              resource model and also as project manager for the start-up
              development of the Caijiaying mine between 2003 and 2005. Later,
              he oversaw the discovery of the Zone II ore body at Caijiaying.
            </p>
            <p>
              He was the founding chairman and managing director of CSA Global,
              a mining consultancy company headquartered in Australia. He is a
              specialist in zinc-lead exploration and was involved as a
              principal in the discovery and development of several notable
              mines. He has served on the board of four public companies listed
              in Dublin, London, Vancouver, and Australia.
            </p>
            <p className="font-weight-bold">
              Adam Usdan, Non-Executive Director
            </p>
            <p>
              Adam is a director on the board since 2014. He is the President of
              Trellus Management Company, an equity hedge fund based in the U.S.
              Adam has been a cornerstone investor in Griffin Mining from as
              early as 1998. He is the largest shareholder of the company.
            </p>
            <p>
              He founded Trellus Management in January 1994 and has been in the
              investment advisory industry for over 25 years. Adam holds an MBA
              from the Kellogg Graduate School of Management at Northwestern
              University with majors in Finance, Marketing, and Accounting, and
              a BA in English from Wesleyan University.
            </p>
            <p className="font-weight-bold">Key Management Member</p>
            <p className="font-weight-bold">
              Mark Hine, Chief Operating Officer
            </p>
            <p>
              Mark has been the COO of the company since 2014. He is a mining
              engineer with over 25 years of senior management mining experience
              in both surface and underground mining operations.
            </p>
          </div>

          <div id="management-strategy" className="mt-5">
            <h2 className="text-left mb-4 seperator pb-2">Management Strategy</h2>
            <p>
              Griffin Mining’s chairman, Mladen is a value-oriented manager who
              often quotes Warren Buffet in Griffin Mining’s annual reports. His
              strategy was well summarised 20 years ago in the company annual
              report of 2000-1, Malden’s first year as the board chairman.
            </p>
            <p>
              “In this truly global economy, there are only two honest ways that
              I know of in building substantial shareholder value in a mining
              company.
            </p>
            <p>
              The first is to explore virgin ground and hope to find an
              economically extractable ore body. This is not only an extremely
              capital-intensive activity, but it is also becoming ever more
              difficult to accomplish, given the amount of exploration which has
              taken place in the western world over the past 2,000 years.
            </p>
            <p>
              The second option is to acquire or participate in a venture in an
              emerging market, such as China, which already has a proven ore
              body but which needs western capital to develop it.
            </p>
            <p>
              Although much has improved in China in terms to access to capital
              since the note was written, Griffin’s core strategy has not
              changed much. During the period, the company made opportunistic
              bids for new but proven ore bodies at attractive prices. However,
              on two out of two occasions, the price was bid up, by external
              factors, to levels that were no longer attractive.
            </p>
            <p>
              After having reviewed many potential acquisitions and following a
              policy of share buybacks at market dips since 2008, the company
              decided in 2012-13 that investment in the Caijiaying Mine would
              generate higher returns for the company and its shareholders than
              any new, low return/high-risk investment elsewhere.
            </p>
            <p>
              Accordingly, Griffin Mining plans to utilise internal cash
              accruals for further investment in Caijiaying Mine to increase
              production. The management aims to double production, and
              subsequently reassess the company’s dividend policy.
            </p>
            <p>
              However, as equity markets remain generally closed to the junior
              mining opportunities, the company continues to investigate a large
              number of potential mining ventures worldwide, with the hope that
              a hidden gem will be found either in junior public mining
              companies or in private organisations incapable of raising new
              equity in private or public markets.
            </p>
            <p className="font-weight-bold">
              Capital Raising and Capital Allocation
            </p>
            <p>
              Griffin Mining’s primary production mine in Zone III of Caijaiying
              was built after completing a feasibility test which assumed a Zinc
              price of USD 760/T. The company did a series of equity fundraising
              to raise the funds it initially needed to develop a zinc-
              gold-silver mine capable of mining 200,000 tonnes of zinc ore.
            </p>
            <p>
              Mladen decided not to follow the traditional path of financing
              with - a packaged debt and equity financing arranged by a
              commercial bank. That process would have involved a lengthy and
              prohibitively expensive bankable feasibility study for a company
              of Griffin Mining’s size. Instead, the board decided at the end of
              2000 to raise approximately USD 2 million of equity first and then
              speak with banks to raise the debt portion needed to build and
              commission the mine. Existing shareholders invested another USD 1
              million at the same time by exercising warrants.
            </p>
            <p>
              However, commercial and investment banks were not comfortable with
              the prospects of the Caijiaying mine, partly due to their lack of
              familiarity with investing in a Chinese mining asset. Driven by a
              need to begin construction of the processing plant to be completed
              before the onset of the 2004 winter, the company completed a GBP
              8.75 million equity placement in 2003 to meet its funding
              requirements to construct and commission the Caijiaying mine.
            </p>
            <p>
              In July 2005, Griffin Mining successfully commissioned the mine
              and processing facilities at Caijiaying with an initial production
              capacity of 200,000 tonnes of ore per annum. The company increased
              its production capacity to 300,000 tonnes per annum within seven
              months of starting operations, and then to 500,000 tonnes in 2007.
              The company declared a maiden FY 2006 dividend of USD 0.03 per
              share in April 2007.
            </p>
            <p>
              In July 2007, Griffin issued 68.2 million shares to Citadel Equity
              Fund Ltd, a hedge fund investor, at a historically high price of
              GBP 1.1 per share to raise USD 151.7 million to build a war chest
              for acquisitions.
            </p>
            <p>
              The next two years brought about a test of the management’s
              capital allocations skills. Zinc prices fell around 46% in 2007
              and then a further 50% in 2008. Due to this price decline, a
              decision was taken to suspend operations in the first quarter of
              2009 to allow maintenance and capital work. The company suspended
              its dividend in 2008 and kept a lookout for undervalued assets for
              acquisition and in the process reviewed over 600 mining companies
              across markets.
            </p>
            <p>
              In April 2008, Griffin mining negotiated a cash-based acquisition
              of the Canadian mining company Yukon Zinc Corporation with the aim
              for bringing Yukon Zinc’s Wolverine zinc-copper-lead-silver-gold
              underground mine into production. However, after the deal has been
              agreed, Yukon Zinc received a higher counter bid from Northwest
              Non Ferrous International Investment Company Limited and
              Jinduicheng Molybdenum Group Limited. Not wanting to over-bid for
              Yukon, Griffin Mining walked away from the transaction while
              collecting a CAD 2.5 million break-up fee from Yukon.
            </p>
            <p>
              Finally, in May 2008, the company opted for a share buyback as the
              best use of its capital and bought back its shares from Citadel
              Investment Corp. It repurchased all of the 68.2 million shares
              held by Citadel for USD 121.5 million. Griffin Mining had issued
              those same shares for USD 151.7 million in August 2007 and thus
              realised a gain of USD 30 million in the transaction.
            </p>
            <p>
              At the time of the share repurchase in 2008, Griffin Mining also
              acquired from Citadel a 39% stake in Spitfire Oil Limited, a long-
              term, high-risk venture listed on the AIM of the London Stock
              Exchange, for GBP 2.5million, (USD 4.5 million). Griffin Mining
              eventually disposed of the Spitfire asset at the end of 2013 for a
              loss of $2.23m as it could not find a suitable partner for
              exploration.
            </p>
            <p>
              In October 2008, Griffin’s management repurchased a further 68,000
              shares from the market at an average price of GBP 0.146 per share
              and, in January 2009, it purchased an additional 34,567 shares at
              an average price of GBP 0.15 per share.
            </p>
            <p>
              In March 2009, Griffin Mining made another opportunistic bid, this
              time an unsolicited one for Canada’s Ivernia Inc, the owner of the
              suspended Magellan Lead Mine in Western Australia. In response to
              the cash offer, Ivernia’s management opted to take a poison pill,
              to undergo massive dilution of 111% of its share capital and
              deliver control to a related party, without shareholder approval.
              This made the acquisition of Ivernia unattractive for Griffin
              Mining, and it withdrew its offer.
            </p>
            <p>
              Using its internally generated cash flows, Griffin Mining
              increased its production capacity to 750,000 tonnes of ore per
              annum in August 2010 and commissioned the new capacity in the
              first quarter of 2011. Management Strategy
            </p>
            <p>
              On 10 May 2012, the company increased stake in Hebei Hua Ao to
              88.8% from 60% and extended term of the joint venture to October
              2037 for approximately USD 118.5 million. The purchase was
              financed with a combination of Griffin Mining’s existing cash
              resources, undistributed dividends from Hebei Hua Ao, and Chinese
              banking facilities.
            </p>
            <p>
              A programme of further development and plant upgrades was carried
              out starting in 2013. Costs were funded from cash flow from
              operations, and surplus cash flow was directed towards repaying
              the banking facilities used in the transaction to buy the
              additional equity in, and the extension of the Hebei Hua Ao joint
              venture in 2012.
            </p>
            <p>
              Finally processing capacity was upgraded from 750,000 tonnes per
              annum to 1.5 million tonnes per annum using internal sources of
              cash flow.
            </p>
            <p>
              A new haulage drive was completed during 2016 with the dual
              purpose of improving ventilation and removing previous bottlenecks
              caused by having only a single haulage decline to surface.
              Development work at Zone II is planned to begin as soon as the new
              mining licence is received.
            </p>
            <p>
              The management paid off all company debt using cash flows. In
              2018, Griffin Mining became a net cash company once again.
            </p>
            <p>
              Efforts are now being made to obtain permits to enhance production
              and get a new mining licence at Zone II. This will allow all the
              known resources in Zones II and III to be extracted at a higher
              and more economical rate. Efforts are now being made to obtain
              permits to enhance production and get a new mining licence at Zone
              II. This will allow all the known resources in Zones II and III to
              be extracted at a higher and more economical rate.
            </p>
          </div>
          <div id="financial-analysis" className="mt-5">
            <h2 className="text-left mb-4 seperator pb-2">Financial Analysis</h2>
            <p className="font-weight-bold">Operating Performance</p>
            <p>
              Production of ore in Griffin’s Caijiaying mine has generally
              increased as production capacity was built over the years. Mineral
              ore resources have also generally increased over time. Sales and
              gross profit have mostly been in line with production.
            </p>
            <img
              src={operatingPerformanceOne}
              alt="Production increase over the years chart"
              className="img-fluid my-4 mx-auto"
            />
            <p>
              Griffin Mining has been a profitable company at the operating
              profit level on all years while the mine has been in production.
            </p>
            <img
              src={operatingPerformanceTwo}
              alt="Sales and gross profits have increased chart"
              className="img-fluid my-4"
            />
            <p>
              At the net income level, the company announced net losses in FY
              2015 and again in the first half of 2020, due to a combination of
              unfavourable markets and temporary closure of the mine due to
              unforeseen circumstances. In 2015, the mine was closed for three
              months due to two fatal accidents. Again in 2020, the mine was
              shut down for a few months due to the CoVID-19 pandemic.
            </p>
            <img
              src={operatingPerformanceThree}
              alt="Mine is profitable in almost all years chart"
              className="img-fluid my-4"
            />
            <p className="font-weight-bold mt-5">
              Balance Sheet and Capital Structure
            </p>
            <p>
              Griffin Mining has mostly been a debt-free company for most of its
              operating life except for a period between 2012 and 2017.
            </p>
            <img
              src={balanceSheetAndCapitalStructureOne}
              alt="Griffin Mininng is a net cash company"
              className="img-fluid my-4"
            />
            <p>
              In 2012, the company raised USD 47.1 million of debt. The money
              was used to fund a stake increase in Hebei Hua Ao from 60% to
              88.8% and extend the term of the joint venture to October 2037.
              The company’s cash reserves paid the rest of the total investment
              of USD 118.5 million.
            </p>
            <p>
              Over the next few years, the company paid down all its debt using
              internal cash flows and emerged debt free in 2018.
            </p>
            <img
              src={balanceSheetAndCapitalStructureTwo}
              alt="Book value per share has increased"
              className="img-fluid my-4"
            />
            <p>
              The company has also grown its book value per share over the last ten years through a combination of
              retaining its cashflow, reinvestments to increase production,
              paying down debt, and buying back shares.
            </p>
            <img
              src={balanceSheetAndCapitalStructureThree}
              alt="Number of shares outstanding has fallen"
              className="img-fluid my-4"
            />

            <p className="font-weight-bold">Free Cash Flow and Profitability</p>
            <img
              src={FnFAndProfitabilityOne}
              alt="CFO and FCF is positive most years chart"
              className="img-fluid my-4"
            />
            <p>
              Griffin Mining has generated positive operating cash flow from
              operations in almost all years. Free cash flow has also been
              positive in most years.
            </p>
            <img
              src={FnFAndProfitabilityTwo}
              alt="Capex has increased in last two years"
              className="img-fluid my-4"
            />
            <p>
              This is also represented in the cash and short-term investment on
              the company’s balance sheet. The company utilised most of its free
              cash flow to pay down debt in 2012-17. It did not generate free
              cash in 2018-19 because it has stepped up capital investments to
              increase its production capacity.
            </p>
            <img
              src={FnFAndProfitabilityThree}
              alt="Cash balance has not increased recently"
              className="img-fluid my-4 w-100"
            />
          </div>
          <div id="shareholders" className="mt-5">
            <h2 className="text-left mb-4 seperator pb-2">Shareholders</h2>
            <p className="font-weight-bold">Major Shareholders</p>
            <p>
              The presence of 19.2% shareholder, Adam Usdan, on the company
              board adds to the alignment of incentives of the management with
              common shareholders. Adam, who is a founder of a US-based hedge
              fund Trellus Management LLC, has been a significant long-term
              shareholder in Griffin Mining and has participated in every equity
              placement by the company since the first placement in 1997/1998.
              Adam was nominated to the board of Griffin Mining in 2014.
            </p>
            <p>
              Richard Griffiths, another professional investor, is the
              second-largest shareholder in the company. Richard and his
              controlled undertakings had disclosed a 3.35% stake in the company
              in April 2015. They had tripled it to 12.34% by January 2020.
            </p>
            <p className="font-weight-bold">Institutional Shareholders</p>
            <p>
              Institutions own around 8% of the outstanding capital of the
              company, all of whom are traditional investment managers. The
              largest holders among them include Jupiter Asset Management,
              Macquarie Investment Management, and Hargreave Hales’ Investment
              Management arm.
            </p>

            <p className="font-weight-bold">Recent Insider buying</p>
            <p>
              Adam bought 170,000 Griffin Mining shares in the open market in
              March 2020 at an average price of around GBP 0.33 per share.
              Another board director, Dal Brynelson, bought 40,000 shares in
              January 2020 at GBP 0.65 per share. Previously, Usdan had bought
              210,000 shares of Griffin Mining in September-December 2018 at an
              average price of around GBP 0.91 per share.
            </p>

            <p className="font-weight-bold">Shareholder returns</p>
            <p>
              In 2005, before the mine started production, the company had 184
              million shares outstanding with a net cash position. Today
              shareholders have 172 million shares outstanding with a net cash
              position. In the meantime, the company has grown mining capacity
              7.5x and increased its ownership in Hebei Hua-Ao by 28.8%,
              extended the life of the mining licence by 18 years, and increased
              resource estimates without raising any net debt.
            </p>
            <p>
              In the same period, the company has returned value to shareholders
              in the form of dividends and buybacks, while increasing the
              tangible book value per share from USD 0.18 in 2005 to USD 1.21 in
              2019.
            </p>
            <p className="font-weight-bold">Dividends</p>
            <p>
              Griffin Mining has paid a USD 0.03 per share dividends twice in
              its history, once in 2007 and once in 2008.
            </p>

            <p className="font-weight-bold">Share issuances and buyback</p>
            <p>
              After completing the required funding to develop the Caijiaying
              Mine, Griffin Mining had issued shares once. In a private
              placement to Citadel Equity Fund, in 2007 near a historically high
              price of GBP 1.10, the company raised USD 151.7 million, for
              making acquisitions. The company bought back those same shares for
              USD 121.5 million in 2008 after its cash offer for Yukon Zinc was
              outbid. The company bought back more shares in 20018 and 2009 and
              has continued to{" "}
              <a
                href="https://investegate.co.uk/AdvancedSearch.aspx?qsArticleType=ann&qsSearchFor=S1&qsSpan=120&qsKeyWord=Transaction+in+Own+Shares&qsContains=griffin+mining&pno=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                buy back shares
              </a>{" "}
              in the period between 2011 and 2018.
            </p>
          </div>
          <div id="valuation" className="mt-5">
            <h2 className="text-left mb-4 seperator pb-2">Valuation</h2>
            <p className="font-weight-bold">Current Market Valuation</p>
            <p className="font-weight-bold">Net Assets</p>
            <p>
              Griffin Mining is trading at 65% of its tangible book value (net
              asset value on the balance sheet after removing liabilities and
              intangible assets) which offers substantial downside protection.
              These valuations have been carried out by Griffin assuming a
              long-term zinc price of USD 2,230 per tonne.
            </p>
            <p>
              Given the company’s history of increasing its mineral resources
              and book value over time, the historical accounting figure of the
              tangible book value is likely a suitable floor for valuation for
              the company. As per the latest company filings, the tangible book
              value per share of the company was{" "}
              <span className="font-weight-bold">GBP 0.95</span> per share
            </p>
            <p>
              Although the price of zinc dipped to as low as USD 1,800 in March
              2020, current zinc prices have recovered to 2,300 to 2,500 in the
              last couple of months. The long-term assumption does not seem
              out-of-place as it is roughly equal to the mean Zinc price over
              the previous ten years.
            </p>
            <p>
              Additionally, a recent doubling of processing capacity and a
              pending second mining licence could put the company in a position
              to increase its production by at least 1.5X and up to 2X.
            </p>
            <p>
              Griffin’s management is working towards a licence for a second
              production mine in Zone II, south of the first mine in Zone III.
            </p>
            <p>
              The extended application process entered the final stage in August
              2019, and it is currently awaiting the acceptance of additional
              information requested by the Chinese government.
            </p>
            <p>
              In anticipation of the second mining licence, Griffin Mining
              doubled its stated processing capacity from 750,000 tonnes per
              year to 1.5 million tonnes per year in 2018/19. Much of this
              capacity is currently lying idle, ready to take up supply from the
              second mine.
            </p>
            <p className="font-weight-bold mt-5">Historical Valuations</p>
            <p className="font-weight-bold">Price to Tangible Book Value</p>
            <img
              src={HistoricalValuationsOne}
              alt="Historical PTBV has between 0.6x and 1.4x chart"
              className="img-fluid my-4"
            />
            <p>
              Griffin Mining is trading at 0.65x its tangible book value at
              present. In the past, this ratio has typically hovered between the
              0.6x and 1x mark. However, as recently as 2018, when the share
              price was GBP 1.5, it traded at 1.4X this number.
            </p>
            <p className="font-weight-bold">Earnings based valuation ratios</p>
            <img
              src={EarningsBasedValuationRatios}
              alt="Earnings multiples are at low end of range chart"
              className="img-fluid my-4"
            />
            <p>
              Due to the nature of the mining industry, Griffin Mining’s
              earnings have varied significantly over the last ten years.
              Moreover, the share price has also varied over a broad range
              between GBP 0.2 -1.6. As a result, LTM EV/EBIT (Enterprise value
              to EBIT) and LTM P/E (price to earnings) has varied significantly
              in the last 10-years. (Both ratios are calculated using the
              trailing 12-month earnings number when the latest interim
              half-year results are available). At the time of this writing this
              report, both ratios were at the lower end of its historical range.
            </p>
            <p className="font-weight-bold">Normalised Production Scenarios</p>
            <p>
              The current run rate of production in Caijiaying Zone III is
              somewhere between 860,000 to 920,000 million tonnes per annum.
              According to the management, the company can be increase
              production to one million tonnes per annum (MTPA) if required. We
              consider this as the first of three normalised scenarios, i.e.,
              how the company would perform in the middle of a commodity cycle.
            </p>
            <p>
              If the company acquires a Zone II mining licence, it should be
              able to churn out a combined 1.5 MTPA of ore from Zone III and II.
              We use this as the second of our three normalised scenarios.
            </p>
            <p>
              The management has also stated that it can increase its processing
              capacity to 2MTPA with minimum additional capital outlay. Combined
              with full-scale production of 2MTPA from Zone III and Zone III,
              this serves as our third and final normalised scenario.
            </p>
            <p className="font-weight-bold">Normalised Earnings Per Share</p>
            <p>
              As illustrated in the tables two pages below, we estimate that
              Griffin Mining should earn a normalised diluted EPS of USD 0.09 in
              normalised production scenario-one. This is based on an assumption
              of operating the Zone III mine at 1.0 MTPA. It implies a
              scenario-one PE multiple of 8.5x compared to the current Griffin
              Mining share price of GBP 0.62, which is equivalent to USD 0.80.
            </p>
            <p>
              Under scenario-two, we consider an additional 0.5 MTPA of
              production from Zone II after the additional mining licence is
              granted. We estimate a normalised EPS of USD 0.20 in this
              scenario, implying a PE multiple of 4.0x.
            </p>
            <p>
              Under scenario-three, we have assumed a combined 2MTPA production
              with 1MTPA being hauled from each of Zone II and Zone III. In this
              scenario, we estimate a normalised EPS of USD 0.31, implying a PE
              of 2.6x.
            </p>
            <p className="font-weight-bold">Plenty of Remaining Mine Life</p>
            <p>
              The latest mineral resource estimate for Caijiaying Zones totals
              67.7 million tonnes of ore. This represents enough ore to sustain
              around 70 years of mine life at the current rate of production of
              nearly 1MTPA. It represents about 45 years of mine life at 1.5
              MTPA and approximately 35 years at 2MTPA.
            </p>
            <p>
              Also, the mining resources can increase further as more
              exploration work is carried out. The current mining and
              exploration agreement at Caijiaying is valid for another 17 years
              till 2037 but should be extendable in the future.
            </p>
            <ProductionAndMetalConcentrates />
            <ImpliedPE />
          </div>
          <div id="potential-catalysts" className="mt-5">
            <h2 className="text-left mb-4 seperator pb-2">Potential Catalysts</h2>
            <p className="font-weight-bold">
              Potential Catalysts for Share Price
            </p>
            <p>
              Griffin mining shares are trading at a 35% discount to the net
              asset value. There are several catalysts on the horizon that could
              decrease this discount.
            </p>
            <p className="font-weight-bold">Zone II mining licence</p>
            <p>
              Griffin Mining is in the final stage of Zone II mining licence
              application, and the company has already increased processing
              capacity and completed a part of the development work in
              anticipation. Receipt of the licence could lead to a re-rating of
              the stock price, as it will allow the company to increase
              production considerably with minimal capital outlay.
            </p>
            <p className="font-weight-bold">Dividend reinstatement</p>
            <p>
              The announcement of a dividend by the company, possibly on receipt
              of the Zone II licence or the completion of the development work
              in Zone II, would give confidence about the prospects of the
              company to the stock market.
            </p>
            <p className="font-weight-bold">
              Further share buybacks or insider trading
            </p>
            <p>
              Further buybacks or insider trading could communicate confidence
              in the the company and support the share price.
            </p>
            <p className="font-weight-bold">A takeover by a Chinese buyer</p>
            <p>
              A takeover offer for Griffin Mining, possibly from a large Chinese
              mining company could immediately remove the discount to the net
              asset value of the shares of the company. From a strategic
              standpoint, it makes more sense for a local Chinese mining company
              or investor to own the assets in China. Hence, they may be willing
              to pay more for the assets.
            </p>
            <p className="font-weight-bold">
              Possible Hong Kong or Chinese listing
            </p>
            <p>
              The company indicated in 2011-12 that it plans to list on Hong
              Kong Stock exchange in the future. The timing will be driven by
              the company’s need for capital should the next acquisition require
              additional funds. A potential listing close to home would bring in
              shareholders more familiar to China and could lead to a reduction
              in the discount to assets of the company.
            </p>

            <p className="font-weight-bold">Further resource upgrades</p>
            <p>
              The company has several sources of further upside from resources
              upgrades.
            </p>
            <p>
              The conversion rate at the Caijiaying Mine from an inferred to a
              measured or indicated resource is effectively 157% based on a
              comparison of previous and current mineral resource estimate
              reports. This suggests that the company is likely to increase its
              total resources as it continues to do exploration and resource
              definition drilling in an area.
            </p>
            <p>
              Also, the 1.5 Km extended area between Zone III and Zone II may be
              a part of the same ore body. Further drilling in the area is
              expected to increase resource estimates.
            </p>
            <p>
              More exploration and resource definition work is ongoing at the
              other six known areas (zones) of mineralisation within Caijiaying.
            </p>
          </div>
          <div id="risks-and-concerns" className="mt-5">
            <h2 className="text-left mb-4 seperator pb-2">Risks and Concerns</h2>
            <p className="font-weight-bold">Risks and Concerns</p>
            <p className="font-weight-bold">Falling zinc ore grades</p>
            <p>
              Griffin discloses its mineral resources at a 1% zinc cut-off,
              which it determines as the lowest level of zinc concentration at
              which ore extraction is commercially viable. The average zinc ore
              concentration has lowered from 7% since the start of production to
              about 4.5% now. Extraction of ore above the average level of
              concentration should be profitable. However, extraction of ore
              near 1% concentration may not be value-adding for shareholders.
            </p>

            <p className="font-weight-bold">
              Timing of Zone II MLA is still not a certainty
            </p>
            <p>
              Griffin Mining has patiently followed up Chinese mining
              authorities on the Zone III mining licence agreement over several
              years and has overcome several hiccups due to a bureaucratic
              process. As of August 2019, it was in striking distance of getting
              final approval. However, the process got temporarily suspended in
              October 2019 with the authorities asking for more information from
              the company for a second time. There has been no update after
              that.
            </p>

            <p className="font-weight-bold">No dividends paid since FY 2007</p>
            <p>
              Griffin mining has not paid a dividend since FY 2007 and may not
              pay a dividend in the foreseeable future. Due to the company’s
              preferred strategy of staying net cash and building a war chest
              for opportunistic acquisitions, the management has chosen to pay
              down debt and investing in operations above paying out dividends
              to shareholders in the past. While the chairman has pointed
              towards considering a dividend should mining output increase after
              Zone II comes online, there is no certainty of that either.
            </p>

            <p className="font-weight-bold">Zinc smelter treatment charges</p>
            <p>
              Smelters in China cut production by 10% in 2018-19 to comply with
              environmental norms which led to soaring zinc treatment prices in
              2019 and early part of 2020. Any future regulatory or ecological
              bottlenecks in zinc smelting capacity can have a detrimental
              effect on the profitability of the zinc mining sector
            </p>

            <p className="font-weight-bold">
              Oversupply of Zinc concentrate due to several mines coming online
            </p>
            <p>
              Zinc supply increased quickly in 2018- 19 with new mines coming
              online following a period of historically high prices seen in
              2017-8. Zinc prices collapsed in 2019 and the first quarter of
              2020 till CoVID forced some of the largest mines in the world to
              go offline.
            </p>

            <p className="font-weight-bold">
              The mineral estimate was made by an individual who owned Griffin
              Mining share options
            </p>
            <p>
              The recent mineral resource estimate disclosed by the company have
              been made by Steve Rose, who was granted 37,500 Griffin Mining
              share options in February 2015. These options were exercised in
              December 2019, when the market price of the shares was around GBP
              0.68. The exercise price of the options was GBP 0.30 per share.
              Griffin Mining has disclosed that Steve’s work has been
              peer-reviewed for this reason.
            </p>

            <p className="font-weight-bold">Chairman Ninkov’s compensation</p>
            <p>
              Board chairman Mladen Ninkov is the principal of Keynes
              Investments, a consultancy firm which collects a fee from Griffin
              Mining under a consultancy agreement. The fee in 1999 and 2000 was
              USD 200,000. It has increased over time to USD 2.6 million as of
              2019.
            </p>

            <p className="font-weight-bold">Mining accidents</p>
            <p>
              Mining is a risky business and accidents, though rare, cannot be
              ruled out. In August 2010, mining operations at the Caijiaying
              mine were suspended for four months after two men employed by a
              mining contractor died in an accident. After investigations by
              government authorities, Hebei Hua Ao was exonerated from primary
              fault. Another three-month suspension came into effect after two
              more contractors died in 2015. Although the company makes every
              attempt to follow the best safety procedures, such accidents
              cannot be ruled out in the future.
            </p>

            <p className="font-weight-bold">Local government risks</p>
            <p>
              The Caijiaying Mine is situated close to the Caijiaying village in
              Hebei province in China. The company is dependent on the
              cooperation of the local authorities, including its JV partners in
              Hebei Hua-Ao, to carry out its operations. Griffin provides the
              largest source employment in the village and is also active in
              corporate social activities in the area, including running of a
              school. However, future issues with the local government cannot be
              completely ruled out.
            </p>

            <p className="font-weight-bold">Infrastructure risks</p>
            <p>
              The Caijiaying mine is suitably located with around the year easy
              access to highways, railways, water, commercial power lines, and
              water resources. The risk of a breakdown of infrastructure
              facilities, though highly unlikely, cannot be completely ruled
              out. In case of such an event, the mine may not be able to carry
              out its operations or sell products in the market.
            </p>

            <p className="font-weight-bold">Geopolitical risks</p>
            <p>
              Hebei Hua-Ao is a foreign-controlled mining entity in China.
              Unfavourable developments in international geopolitics could, in
              the worst-case scenario, leave Griffin Mining in a catch- 22
              situation whereby it could have to sell its ownership to a
              domestic Chinese entity or relinquish its mining and exploration
              licences in China.
            </p>
          </div>
          <div id="recent-developments" className="mt-5">
            <h2 className="text-left mb-4 seperator pb-2">Recent Developments</h2>
            <p className="font-weight-bold">Recent Developments</p>
            <p className="font-weight-bold">11 September 2020</p>
            <p>
              Griffin Mining{" "}
              <a
                href="https://www.londonstockexchange.com/news-article/GFM/appointment-of-nominated-adviser-and-broker/14683862"
                target="_blank"
                rel="noopener noreferrer"
              >
                announced
              </a>{" "}
              the appointment of Panmure Gordon (UK) as its nominated adviser.
              Panmure Gordon and Joh. Berenberg, Gossler & Co. were appointed as
              its Joint corporate brokers. The move came after the decision of
              the company’s previous Nominated Advisor and Broker, Numis
              Securities, to exit the natural resources sector.
            </p>

            <p className="font-weight-bold">23 July 2020</p>
            <p>
              Griffin Mining{" "}
              <a
                href="https://www.londonstockexchange.com/news-article/GFM/half-year-report/14625648"
                target="_blank"
                rel="noopener noreferrer"
              >
                announced
              </a>{" "}
              its results for the six months ended June 30, 2020. It reported a
              reduction in production and revenues in the first half of 2020
              compared to the first half of 2019. It also reported losses in the
              first half of the year. Operations were impacted by the suspension
              in operations at Caijiaying due to CoVID-19.
            </p>

            <p className="font-weight-bold">17 June 2020</p>
            <p>
              Griffin Mining{" "}
              <a
                href="https://www.londonstockexchange.com/news-article/GFM/final-results/14580377"
                target="_blank"
                rel="noopener noreferrer"
              >
                published
              </a>{" "}
              its financial statements for the year ended in 2019. Despite
              greater quantities of ore being mined and sold, it reported a
              lowering of revenues and profits. This was primarily caused by
              falling zinc prices and significantly higher smelter treatment
              charges in 2019.
            </p>

            <p className="font-weight-bold">3 March 2020</p>
            <p>
              Zinc treatment charges fell for the first time in almost two
              years. They were around USD 300 a tonne down from an 11 year high
              of USD 305 a tonne. In comparison, treatment charges were just USD
              15 a tonne as recently as March 2018.
            </p>
            <p className="font-weight-bold">30 October 2019</p>
            <p>
              The company{" "}
              <a
                href="https://www.londonstockexchange.com/news-article/GFM/mining-licence-update/14287165"
                target="_blank"
                rel="noopener noreferrer"
              >
                announced
              </a>{" "}
              that it had received notification from Beijing that further
              clarification was required for Zone II licence concerning farming
              surface rights, as a result of which the acceptance period was
              suspended with effect from 24th October 2019.
            </p>

            <p className="font-weight-bold">20 September 2019</p>
            <p>
              The company{" "}
              <a
                href="https://www.londonstockexchange.com/news-article/GFM/mining-licence-update/14235616"
                target="_blank"
                rel="noopener noreferrer"
              >
                announced
              </a>{" "}
              further documents for Zone II requested by Chinese Ministry of
              Natural Resources were lodged and approved.
            </p>

            <p className="font-weight-bold">8 Aug 2019</p>
            <p>
              On 8 August 2019, the Chinese Ministry of Natural Resources{" "}
              <a
                href="https://www.londonstockexchange.com/news-article/GFM/statement-re-mining-licence-application-accepted/14180794"
                target="_blank"
                rel="noopener noreferrer"
              >
                accepted
              </a>{" "}
              the lodgement by Hebei Hua-Ao of its mining licence application
              for Zone II at the Caijiaying Mine. The ministry is expected to
              provide its approval and issuance of the new mining licence within
              40-50 business days.
            </p>

            <p className="font-weight-bold">20 June 2019</p>

            <p>
              Griffin Mining{" "}
              <a
                href="https://www.londonstockexchange.com/news-article/GFM/trading-update/14118884"
                target="_blank"
                rel="noopener noreferrer"
              >
                announced
              </a>{" "}
              in a trading update that it would probably fail to meet market
              expectations in 2019. Revenues had been impacted by a 65% increase
              in smelter treatment charges from the same period in 2018. This
              was due to the closure of a number of smelters in China, primarily
              for environmental reasons.
            </p>

            <p className="font-weight-bold">3rd April 2019</p>
            <p>
              Griffin Mining{" "}
              <a
                href="https://www.londonstockexchange.com/news-article/GFM/resource-upgrade-zone-iii-caijiaying-mine/14026402"
                target="_blank"
                rel="noopener noreferrer"
              >
                announced
              </a>{" "}
              a 78.5% increase in mineral resource for Zone III. The company
              estimates that USD 7.8bn of metal are in Situ in Zone III alone.
              The full extraction of the new estimated resource will require the
              extension of Griffin Mining’s business licence beyond 2037 or an
              increase in production capacity and enhanced permits.
            </p>
          </div>
          <div id="company-timeline" className="mt-5">
            <h2 className="text-left mb-4 seperator pb-2">Company Timeline</h2>
            <HistoricalTimeline />
          </div>
          <div id="resources-and-production" className="mt-5">
            <h2 className="text-left mb-4 seperator pb-2">Resources and Production</h2>
            <img
              src={resourceAndProductionOne}
              className="img-fluid my-2"
              alt="Mineral resources have increased as exploration and development continues"
            />
            <img
              src={resourceAndProductionTwo}
              className="img-fluid my-2"
              alt="Ore production is approachinng one million tonnes per annum"
            />
            <img
              src={resourceAndProductionThree}
              className="img-fluid my-2"
              alt="Zinc and gold concentrate production has increased"
            />
            <img
              src={resourceAndProductionFour}
              className="img-fluid my-2"
              alt="Lead and silver concentrate production has also increased"
            />
            <img
              src={resourceAndProductionFive}
              className="img-fluid my-2"
              alt="Zinc and gold ore grades has fallen slowly over time"
            />
            <img
              src={resourceAndProductionSix}
              className="img-fluid my-2"
              alt="Lead and silver grades have also fallen"
            />
          </div>

          <div id="key-financials" className="mt-5">
            <h2 className="text-left mb-4 seperator pb-2">Key Financials</h2>
            <KeyBalanceAndIncome />
            <KeyCashFlowFinancials />
            <KeyFinancialsAndValuations />
          </div>

          <div id="disclaimer" className="mt-5">
            <h2 className="text-left mb-4 seperator pb-2">Disclaimer</h2>
            <p className="font-weight-bold">DISCLAIMER ABOUT THIS REPORT</p>
            <p>
            This is a sample equity research report. The aim of this report is to provide a detailed description of the Company while sharing our analysis and views on the Company. This report should be treated purely as a source of information about the company and not as an investment recommendation. Our officers and employees may currently, or in the future, have a position in securities of the Company, which may affect this report’s objectivity.
            </p>
            <ul className="text-light-grey">
              <li>
                <span className="font-weight-bold">No investment advice</span>:
                The information that we provide shall not be construed in any
                manner whatsoever as investment advice, personalised or
                otherwise. Also, the information provided by us should not be
                construed by the reader as Eightfold Research’s solicitation to
                effect, or attempt to effect, any transaction in a security. The
                securities described in the report may not be eligible for sale
                in all jurisdictions or to certain categories of investors.
              </li>
              <li>
                <span className="font-weight-bold">Accuracy of content</span>:
                While Eightfold Research has done its utmost best to verify the
                accuracy of the information used in the publication of this
                report, we offer no warranty, either expressed or implied,
                regarding the veracity and completeness of data and the
                interpretations of data included in the report. Opinions and
                view contained in this report, except specifically mentioned
                otherwise, represent those of Eightfold Research at the time of
                publication.
              </li>
              <li>
                <span className="font-weight-bold">Exclusion of Liability</span>
                : To the fullest extent allowed by law, Eightfold Research shall
                not be held responsible for any direct, indirect or
                consequential losses, loss of profit, damages, costs or expenses
                incurred or suffered by you arising out of or in connection with
                the use of this report.
              </li>
            </ul>
            <p className="font-weight-bold">COPYRIGHT</p>
            <p>
              The copyright of this report and the rights regarding the use of
              any information on this report towards the creation of any
              derivative work/product belongs to Eightfold Research. This report
              may be reproduced or modified for personal use, but the
              distribution, transfer, or other use of this report are strictly
              prohibited.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportBody;
