import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop(props) {
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes('/report')) {
      window.scrollTo(0, 0)
    }
  }, [location]);
  const scrollToTop = () => {
    if (location.pathname.includes('/report')) {
      var element = document.getElementById('tableOfContentsID')
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth'
        })
      }
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
  return <div className="back-to-top">
    <div>
      <button className="btn btn-primary" onClick={() => scrollToTop()}>
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          className="bi bi-caret-up"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M3.204 11L8 5.519 12.796 11H3.204zm-.753-.659l4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z"
          />
        </svg>
      </button>
    </div>
  </div>
}

export default ScrollToTop;
