import React from "react";

function KeyCashFlowFinancials(props) {
  return (
    <div className="mt-5 container-grey p-2 seperator-top">
      <div className="container-fluid no-gutters text-left table-meta">
        <div className="row">
          <div className="col-3">
            <p className="mb-0">Report Type</p>
          </div>
          <div className="col-3">
            <p className="font-weight-bold mb-0">Consolidated Annual</p>
          </div>
          <div className="col-3">
            <p className="mb-0">Accounting Standard</p>
          </div>
          <div className="col-3">
            <p className="font-weight-bold mb-0">IFRS</p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p className="mb-0">Unit</p>
          </div>
          <div className="col-3">
            <p className="font-weight-bold mb-0">USD Million</p>
          </div>
          <div className="col-3">
            <p className="mb-0">Accounting Year-End</p>
          </div>
          <div className="col-3">
            <p className="font-weight-bold mb-0">December</p>
          </div>
        </div>
      </div>
      <table className="table table-sm custom-tables table-striped table-responsive">
      <caption>Source: Griffin Mining, Eightfold Research</caption>
        <thead>
          <tr>
            <th>Period</th>
            <th>FY 2016</th>
            <th>FY 2017</th>
            <th>FY 2018</th>
            <th>FY 2019</th>
            <th>LTM Jun-20</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="6" className="seperator-xtra">
              <h4>Key Cash Flow Statement Items</h4>
            </td>
          </tr>

          <tr>
            <td>Cash Flows from Operations (CFO)</td>
            <td>22</td>
            <td>77</td>
            <td>20</td>
            <td>22</td>
            <td>13</td>
          </tr>
          <tr>
            <td>Cash Flows from Investing Activities</td>
            <td>-11</td>
            <td>-13</td>
            <td>-16</td>
            <td>-27</td>
            <td>-20</td>
          </tr>
          <tr>
            <td>Cash Flows from Financial Activities</td>
            <td>-22</td>
            <td>-51</td>
            <td>-2</td>
            <td>-3</td>
            <td>-2</td>
          </tr>
          <tr>
            <td>Unlevered Free Cash Flow (FCF)</td>
            <td>9</td>
            <td>58</td>
            <td>-1</td>
            <td>-5</td>
            <td>-5</td>
          </tr>
          <tr>
            <td>Levered Free Cash Flow (FCF)</td>
            <td>6</td>
            <td>57</td>
            <td>-1</td>
            <td>-6</td>
            <td>-5</td>
          </tr>
          <tr>
            <td>Capital Expenditure</td>
            <td>11</td>
            <td>13</td>
            <td>17</td>
            <td>27</td>
            <td>20</td>
          </tr>
          <tr>
            <td>Depreciation &amp; Amortization</td>
            <td>9</td>
            <td>10</td>
            <td>10</td>
            <td>14</td>
            <td>15</td>
          </tr>
        </tbody>
      </table>
      <table className="table table-sm custom-tables table-striped table-responsive">
      <caption>Source: Griffin Mining, Eightfold Research</caption>
        <thead>
          <tr>
            <th>Period</th>
            <th>FY 2016</th>
            <th>FY 2017</th>
            <th>FY 2018</th>
            <th>FY 2019</th>
            <th>LTM Jun-20</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="6" className="seperator-xtra">
              <h4 >Key Financial Ratios</h4>
            </td>
          </tr>

          <tr>
            <td>Gross Margin</td>
            <td>43%</td>
            <td>65%</td>
            <td>54%</td>
            <td>41%</td>
            <td>34%</td>
          </tr>
          <tr>
            <td>EBITDA Margin</td>
            <td>36%</td>
            <td>58%</td>
            <td>46%</td>
            <td>32%</td>
            <td>28%</td>
          </tr>
          <tr>
            <td>Operating Profit Margin</td>
            <td>23%</td>
            <td>50%</td>
            <td>36%</td>
            <td>15%</td>
            <td>5%</td>
          </tr>
          <tr>
            <td>Net Profit Margin</td>
            <td>16%</td>
            <td>98%</td>
            <td>56%</td>
            <td>13%</td>
            <td>(4%)</td>
          </tr>
          <tr>
            <td>CFO/Total Sales</td>
            <td>33%</td>
            <td>61%</td>
            <td>21%</td>
            <td>26%</td>
            <td>21%</td>
          </tr>
          <tr>
            <td>COGS/Total Sales</td>
            <td>57%</td>
            <td>35%</td>
            <td>46%</td>
            <td>59%</td>
            <td>66%</td>
          </tr>
          <tr>
            <td>SG&amp;A/Total Sales</td>
            <td>20%</td>
            <td>15%</td>
            <td>18%</td>
            <td>24%</td>
            <td>26%</td>
          </tr>
          <tr className="seperator-row">
            <td>CapEx/Total Sales</td>
            <td>17%</td>
            <td>11%</td>
            <td>17%</td>
            <td>33%</td>
            <td>30%</td>
          </tr>
          <tr>
            <td>ROE</td>
            <td>4%</td>
            <td>23%</td>
            <td>12%</td>
            <td>3%</td>
            <td>(1%)</td>
          </tr>
          <tr className="seperator-row">
            <td>ROA</td>
            <td>3%</td>
            <td>17%</td>
            <td>10%</td>
            <td>2%</td>
            <td>(1%)</td>
          </tr>
          <tr>
            <td>Total Assets Turnover Ratio</td>
            <td>0.3x</td>
            <td>0.5x</td>
            <td>0.4x</td>
            <td>0.3x</td>
            <td>0.3x</td>
          </tr>
          <tr>
            <td>Accounts Receivable Turnover Ratio</td>
            <td>0.0x</td>
            <td>0.0x</td>
            <td>0.0x</td>
            <td>0.0x</td>
            <td>20.8x</td>
          </tr>
          <tr>
            <td>Inventory Turnover Ratio</td>
            <td>5.7x</td>
            <td>7.4x</td>
            <td>8.5x</td>
            <td>11.1x</td>
            <td>11.2x</td>
          </tr>
          <tr>
            <td>Accounts Payable Turnover Ratio</td>
            <td>2.5x</td>
            <td>3.4x</td>
            <td>4.7x</td>
            <td>3.6x</td>
            <td>1.7x</td>
          </tr>
          <tr>
            <td>Days Sales Outstanding (DSO)</td>
            <td>0.0x</td>
            <td>0.0x</td>
            <td>0.0x</td>
            <td>0.0x</td>
            <td>17.6x</td>
          </tr>
          <tr>
            <td>Days Inventory Outstanding (DIO)</td>
            <td>64.4x</td>
            <td>49.4x</td>
            <td>43.1x</td>
            <td>33.0x</td>
            <td>32.7x</td>
          </tr>
          <tr>
            <td>Days Payable Outstanding (DPO)</td>
            <td>157.4x</td>
            <td>115.3x</td>
            <td>91.8x</td>
            <td>89.2x</td>
            <td>227.8x</td>
          </tr>
          <tr>
            <td>Shareholders' Equity Ratio</td>
            <td>0.6x</td>
            <td>0.8x</td>
            <td>0.8x</td>
            <td>0.8x</td>
            <td>0.9x</td>
          </tr>
          <tr>
            <td>Debt / Equity</td>
            <td>0.4x</td>
            <td>0.0x</td>
            <td>0.0x</td>
            <td>0.0x</td>
            <td>0.0x</td>
          </tr>
          <tr>
            <td>Net Debt / Equity</td>
            <td>0.3x</td>
            <td>-0.1x</td>
            <td>-0.1x</td>
            <td>-0.1x</td>
            <td>0.0x</td>
          </tr>
          <tr>
            <td>Total Debt / EBITDA</td>
            <td>2.2x</td>
            <td>0.1x</td>
            <td>0.0x</td>
            <td>0.1x</td>
            <td>0.1x</td>
          </tr>
          <tr>
            <td>Net Debt / EBITDA</td>
            <td>1.6x</td>
            <td>-0.3x</td>
            <td>-0.6x</td>
            <td>-0.7x</td>
            <td>-0.5x</td>
          </tr>
          <tr>
            <td>Interest-Bearing Debt/ CFO</td>
            <td>2.0x</td>
            <td>0.0x</td>
            <td>0.0x</td>
            <td>0.0x</td>
            <td>0.0x</td>
          </tr>
          <tr>
            <td>Net Interest Bearing Debt/CFO</td>
            <td>1.4x</td>
            <td>-0.3x</td>
            <td>-1.4x</td>
            <td>-0.9x</td>
            <td>-0.8x</td>
          </tr>
          <tr>
            <td>Interest-Bearing Debt/FCF</td>
            <td>7.1x</td>
            <td>0.0x</td>
            <td>0.0x</td>
            <td>0.0x</td>
            <td>0.0x</td>
          </tr>
          <tr>
            <td>Net Interest Bearing Debt/FCF</td>
            <td>5.0x</td>
            <td>-0.5x</td>
            <td>33.7x</td>
            <td>3.6x</td>
            <td>2.1x</td>
          </tr>
          <tr className="seperator-xtra">
            <td>EBITDA / Interest Exp.</td>
            <td>5.8x</td>
            <td>35.4x</td>
            <td>764.7x</td>
            <td>129.0x</td>
            <td>66.9x</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default KeyCashFlowFinancials;
