import React from "react";
import "./section-three.scss";
import image from "../../../assets/images/about-us-section-three.png";
import imageMobile from "../../../assets/images/about-us-section-three-mobile.png";
import arrow from "../../../assets/images/about-us-section-three-arrow.svg";
import { useTranslation } from "react-i18next";
function SectionThree(props) {
  const { t } = useTranslation();
  return (
    <div className="about-us-section-three bg-light animate__animated animate__fadeIn animate__delay-1s">
      <div className="container-fluid p-0 no-gutters">
        <div className="row no-gutters">
          <div className="col-md-4 position-relative">
            <img
              src={image}
              alt="about us section three"
              className="img-fluid w-100 d-none d-sm-block"
            />
            <img
              src={imageMobile}
              alt="about us section three mobile"
              className="img-fluid w-100 d-sm-block d-md-none"
            />
            <h1
              className="text-white position-absolute w-100 text-center d-sm-block d-md-none"
              style={{ top: "50%", marginTop: "-40px" }}
            >
              {t("about-us.our-history")}
            </h1>
          </div>
          <div className="col-md-8 position-relative">
            <div className="container text-section d-flex flex-column justify-content-center ">
              <h3 className="text-center text-md-left d-none d-sm-block">
                {t("about-us.our-history")}
              </h3>
              <p className="font-weight-bold text-left">
                {t("about-us.why-the-world-needs")}
              </p>
              <p className="text-left">
              {t("about-us.eightfold-research-was-founded")}
              </p>
              <p className="text-left">
              {t("about-us.we-realised-that")}
              </p>
              <p className="text-left">
              {t("about-us.we-realised-that-this")}
              </p>
            </div>
            <img
              src={arrow}
              alt="styling arrow"
              className="position-absolute d-none d-sm-block"
              style={{ right: 0, top: 0, zIndex: 0 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionThree;
