import React from "react";
import ReportBody from "../../components/report/report-body/report-body";
import ReportHero from "../../components/report/report-hero/report-hero";
import HomepageSubscribe from "../../components/homepage/subscribe/subscribe";
function Report(props) {
  return (
    <>
      <ReportHero/>
      <ReportBody />
      <HomepageSubscribe/>
    </>
  );
}

export default Report;
