import React from "react";
import {
  useQuery,
  useQueryClient,
} from "react-query";
import Api from "../../services/api";
import AddCompany from "./add-company";
import DeleteCompany from "./delete-company";
import { Link } from "react-router-dom";
import Loader from "../../components/loader/loader";
import RightTopArrow from "../../components/right-top-arrow/right-top-arrow";

function ListCompanies(props) {
  const { isLoading, isError, data } = useQuery(
    "companies",
    Api.getAllCompanies
  );
  const queryClient = useQueryClient();

  const onDelete = () => {
    queryClient.invalidateQueries("companies");
  };

  if (isLoading) {
    return (
      <div className="container-fluid text-center h-100 d-flex flex-column justify-content-center">
        <Loader />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="container-fluid text-center h-100 d-flex flex-column justify-content-center">
        <h1 className="display-4">There was an error, please try again later.</h1>
      </div>
    );
  }

  if (data.errors) {
    return (
      <div className="container-fluid text-center h-100 d-flex flex-column justify-content-center">
        <h1 className="display-4">There was an error, please try again later.</h1>
      </div>
    );
  }

  return (
    <div className="container-fluid text-left h-100">
      <div className="d-flex justify-content-between my-3">
        <h2>Companies</h2>
        <AddCompany />
      </div>

      {data.length === 0 && (
        <div className="d-flex justify-content-end">
          <h3 className="mt-3">Click Add to add a new company</h3>
          <div style={{color:"#dd484c" ,width:40}} className="ml-2 mr-5">
            <RightTopArrow />
          </div>
        </div>
      )}

      {data.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Ticker</th>
              <th scope="col">Exchange</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map(({ ID, name, ticker, exchange }, index) => (
              <tr key={ID}>
                <th scope="row">{index + 1}</th>
                <td>{name}</td>
                <td>{ticker}</td>
                <td>{exchange}</td>
                <td className="text-right" style={{width:100}}>
                  <DeleteCompany id={ID} name={name} onDelete={onDelete} />
                </td>
                <td className="text-right" style={{width:100}}>
                  <Link className="btn btn-success" to={`/admin/company/${ID}`}>View</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default ListCompanies;
