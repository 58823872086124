import React from "react";
import icon_1 from "../../../assets/images/icons/1.svg";
import icon_2 from "../../../assets/images/icons/2.svg";
import icon_3 from "../../../assets/images/icons/3.svg";
import icon_4 from "../../../assets/images/icons/4.svg";
import icon_5 from "../../../assets/images/icons/5.svg";
import icon_6 from "../../../assets/images/icons/6.svg";
import icon_7 from "../../../assets/images/icons/7.svg";
import icon_8 from "../../../assets/images/icons/8.svg";
import img_1 from "../../../assets/images/img_1.png";
import img_2 from "../../../assets/images/img_2.png";
import img_3 from "../../../assets/images/img_3.svg";
import "./info.scss";
import { useTranslation } from "react-i18next";

function HomepageInfo(props) {
  const{t} = useTranslation();
  return (
    <div className="d-flex flex-column text-left homepage-info animate__animated animate__fadeIn animate__delay-1s">
      <div>
        <h1 className="apply-padding">
          {t('homepage.info.level-the-playing-field')}
        </h1>
        <img
          src={img_3}
          className="img-fluid w-100 my-4 d-block d-sm-none"
          alt="placeholder"
        />
        <p className="apply-padding">
          {t('homepage.info.small-investors')}
        </p>
        <p className="apply-padding">
        {t('homepage.info.we-at-eightfoldresearch')}
        </p>
      </div>
      <div className="row  no-gutters">
        <div className="col-md-8 icon-collection apply-padding">
          <h3 className="heading_one">{t('homepage.info.for-listed-companies')}</h3>
          <div className="row">
            <div className="col-md-6 d-flex align-items-center my-4">
              <img src={icon_1} alt="icon1" className="icon" />
              <p className="ml-4 mb-0">
              {t('homepage.info.high-quality')}
              </p>
            </div>
            <div className="col-md-6 d-flex align-items-center my-4">
              <img src={icon_2} alt="icon2" className="icon" />
              <p className="ml-4 mb-0">
              {t('homepage.info.global-exposure')}
              </p>
            </div>
            <div className="col-md-6 d-flex align-items-center my-4">
              <img src={icon_3} alt="icon3" className="icon" />
              <p className="ml-4 mb-0">
              {t('homepage.info.stability-and-consistency')}
              </p>
            </div>
            <div className="col-md-6 d-flex align-items-center my-4">
              <img src={icon_4} alt="icon4" className="icon" />
              <p className="ml-4 mb-0">
              {t('homepage.info.opportunity-to-communicate')}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <img
            src={img_1}
            // className="position-absolute d-none d-sm-none d-md-block w-100"
            className="d-none d-sm-none d-md-block w-100"
            // style={{ top: "-10%", right: 0 }}
            // style={{ top: "-10%", right: 0 }}
            alt="placeholder"
          />
        </div>
      </div>
      <div className="row  no-gutters">
        <div className="col-md-4">
          <img
            src={img_2}
            // className="position-absolute d-none d-sm-none d-md-block w-100"
            className="d-none d-sm-none d-md-block w-100"
            // style={{ top: "-45%", left: 0 }}
            alt="placeholder"
          />
        </div>
        <div className="col-md-8 icon-collection apply-padding">
          <h3 className="heading_two">{t('homepage.info.for-investors')}</h3>
          <div className="row">
            <div className="col-md-6 d-flex align-items-center my-4">
              <img src={icon_5} alt="icon5" className="icon" />
              <p className="ml-4 mb-0">
              {t('homepage.info.open-access-to-high-quality')}
              </p>
            </div>
            <div className="col-md-6 d-flex align-items-center my-4">
              <img src={icon_6} alt="icon6" className="icon" />
              <p className="ml-4 mb-0">
              {t('homepage.info.better-understanding-of-all')}
              </p>
            </div>
            <div className="col-md-6 d-flex align-items-center my-4">
              <img src={icon_7} alt="icon7" className="icon" />
              <p className="ml-4 mb-0">
              {t('homepage.info.discover-potential-investment')}
              </p>
            </div>
            <div className="col-md-6 d-flex align-items-center my-4">
              <img src={icon_8} alt="icon8" className="icon" />
              <p className="ml-4 mb-0">
              {t('homepage.info.receive-instant-alerts')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomepageInfo;
