import React from "react";
import "./hero.scss";
import { useTranslation } from "react-i18next";

function Hero(props) {
  const { t } = useTranslation();
  return (
    <div className="hero d-flex align-items-center animate__animated animate__fadeIn animate__delay-1s">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-6 text-left">
            <div className="text-white">
              <h1 className="text-bold">{t("homepage.hero.banner-text")}</h1>
              <p>{t("homepage.hero.subtitle")}</p>
            </div>
            <div className="text-white call-to-action">
              <p>{t("homepage.hero.call-to-action-title")}</p>
              <div className="input-group">
                <form name="signuponly" method="POST" data-netlify="true" className="d-flex  w-100">
                  <input type="hidden" name="form-name" value="signuponly" />
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder={t("homepage.hero.input-placeholder")}
                    aria-label={t("homepage.hero.input-placeholder")}
                    aria-describedby="email-input"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      id="button-signup"
                      style={{whiteSpace:'nowrap'}}
                    >
                      {t("homepage.hero.subscribe")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="info-box"></div> */}
    </div>
  );
}

export default Hero;
