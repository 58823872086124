import React from "react";
import "./our-services-section-two.scss";
import circleOneEn from "../../../assets/images/circle1-en.svg";
import circleTwoEn from "../../../assets/images/circle2-en.svg";
import circleOneJp from "../../../assets/images/circle1-jp.svg";
import circleTwoJp from "../../../assets/images/circle2-jp.svg";
import investmentIcon from "../../../assets/images/icons/investment-research.svg";
import globalIcon from "../../../assets/images/icons/global-distribution.svg";
import { useTranslation } from "react-i18next";
function OurServicesSectionTwo(props) {
  const { t , i18n} = useTranslation();
  const language = i18n.language;
  return (
    <div className="our-services-section-two d-flex flex-column bg-white text-left animate__animated animate__fadeIn animate__delay-1s">
      <div className="bg-light">
        <div className="apply-padding py-5">
          <h3>{t("our-services.what-is-issuer")}</h3>
          <p>{t("our-services.issuer-sponsored")}</p>
        </div>
      </div>
      <div className="bg-white apply-padding py-5">
        <p className="font-weight-bold" style={{ fontSize: 18 }}>
          {t("our-services.our-strength")}
        </p>
        <p>{t("our-services.our-specialty")}</p>
      </div>
      <div className="bg-white apply-padding">
        <p className="font-weight-bold" style={{ fontSize: 18 }}>
          {t("our-services.how-we-add-value")}
        </p>
        <div className="row text-center my-1">
          <div className="col-md-6 my-4">
            <p>{t("our-services.without-efr")}</p>
            {language !== 'ja' && <img src={circleOneEn} alt="without EFR" className="img-fluid" />}
            {language === 'ja' && <img src={circleOneJp} alt="without EFR" className="img-fluid" />}
          </div>
          <div className="col-md-6 my-4">
            <p>{t("our-services.with-efr")}</p>
            {language !== 'ja' && <img src={circleTwoEn} alt="with EFR" className="img-fluid" />}
            {language === 'ja' && <img src={circleTwoJp} alt="with EFR" className="img-fluid" />}
          </div>
        </div>
      </div>
      <div className="container bg-white d-flex mt-5">
        <div className="row w-100">
          <div className="col-md-6">
            <p className="font-weight-bold" style={{ fontSize: 18 }}>
              {t("our-services.we-provide-header")}
            </p>
            <p style={{ maxWidth: 380 }}>{t("our-services.we-provide-text")}</p>
          </div>
          <div className="col-md-4 text-center text-md-left">
            <div>
              <img src={investmentIcon} alt="investment research" />
              <p className="font-weight-bold">
                {t("our-services.investment-research-header")}
              </p>
              <p>{t("our-services.investment-research-subtitle")}</p>
            </div>
            <div className="mt-5">
              <img src={globalIcon} alt="Global Distribution" />
              <p className="font-weight-bold">
                {t("our-services.global-distribution-header")}
              </p>
              <p>{t("our-services.global-distribution-subtitle")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServicesSectionTwo;
