import React from "react";
import "./section-six.scss";
import image from "../../../assets/images/about-us-section-six.png";
import { useTranslation } from "react-i18next";
function SectionSix(props) {
  const { t } = useTranslation();
  return (
    <div className="about-us-section-six bg-white mt-5 animate__animated animate__fadeIn animate__delay-1s">
      <div className="container-fluid p-0 no-gutters">
        <div className="row no-gutters">
          <div className="col-md-8 position-relative">
            <div className="container text-section d-flex flex-column justify-content-center ">
              <h3 className=" text-left">{t("about-us.value-proposition")}</h3>
              <p className="text-left">
                {t("about-us.companies-under-our-coverage-benefit")}
              </p>
              <p className="text-left">
                {t("about-us.our-research-reports-and-distribution")}
              </p>
              <p className="text-left">
                {t("about-us.our-research-reports-on-your-company")}
              </p>
            </div>
          </div>
          <div className="col-md-4 position-relative">
            <img
              src={image}
              alt="about us section three"
              className="img-fluid w-100  h-100 d-none d-sm-block"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionSix;
