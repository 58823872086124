import React from "react";
import "./about-us.scss";
import arrow from '../../../assets/images/homepage-about-us-arrow.svg';
import {Link} from 'react-router-dom';
import { useTranslation } from "react-i18next";
function HomepageAboutUs(props) {
  const {t} = useTranslation();
  return (
    <div className="homepage-about-us d-flex flex-column justify-content-center align-items-center bg-white position-relative animate__animated animate__fadeIn animate__delay-1s">
      <img src={arrow} className="position-absolute" alt="layout design arrow" style={{right:0}}/>
      <p className="apply-padding">
      {t('homepage.about-us.eightfold-research')}
      </p>
      <Link className="btn btn-outline-danger mx-auto mt-2" to={'/about-us'}>{t('homepage.about-us.about-us')}</Link>
    </div>
  );
}

export default HomepageAboutUs;
