import React from "react";
import "./our-services.scss";
import rightArrow from "../../../assets/images/icons/chevron-right.svg";
import { useTranslation } from "react-i18next";
function HomepageServices(props) {
  const {t} = useTranslation();
  return (
    <div className="homepage-our-services d-flex flex-column text-white animate__animated animate__fadeIn animate__delay-1s">
      <div className="d-flex flex-column flex-md-row mt-5 mb-3 questions-padding">
        <div className="d-flex align-items-center mb-4 p-3">
          <img src={rightArrow} alt="rightarrow" className="icon" />
          <p className="mb-0 border-bottom pb-3">
          {t('homepage.our-services.is-your-company-doing-well')}
          </p>
        </div>
        <div className="d-flex align-items-center mb-4 p-3">
          <img src={rightArrow} alt="rightarrow" className="icon" />
          <p className="mb-0 border-bottom pb-3">
          {t('homepage.our-services.do-you-feel-your-company-stock')}
          </p>
        </div>
        <div className="d-flex align-items-center mb-4 p-3">
          <img src={rightArrow} alt="rightarrow" className="icon" />
          <p className="mb-0 border-bottom pb-3">
          {t('homepage.our-services.are-you-worried-about-the')}
          </p>
        </div>
      </div>
      <h4 className="mb-4 apply-padding">
      {t('homepage.our-services.do-you-know-that-sell-side')}
      </h4>
      <a href="/our-services" className="btn btn-outline-light mx-auto">{t('homepage.our-services.our-services-button')}</a>
    </div>
  );
}

export default HomepageServices;
