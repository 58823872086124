import React from 'react';
import PrivacyAndCookiesHero from '../../components/privacy-and-cookies/hero/hero';
import PrivacyAndCookiesContent from '../../components/privacy-and-cookies/privacy-and-cookies-content/privacy-and-cookies-content';

function PrivacyAndCookies(props){
    return (
        <div className="d-flex flex-column">
            <PrivacyAndCookiesHero/>
            <PrivacyAndCookiesContent/>
        </div>
    )
}

export default PrivacyAndCookies;