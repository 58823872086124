import React from "react";
import "./our-services-section-three.scss";
import leftArrow from "../../../assets/images/our-services-section-three-arrow-left.svg";
import rightArrow from "../../../assets/images/our-services-section-three-arrow-right.svg";
import { useTranslation } from "react-i18next";
function OurServicesSectionThree(props) {
  const { t } = useTranslation();
  return (
    <div className="our-services-section-three d-flex flex-column bg-white animate__animated animate__fadeIn animate__delay-1s">
      <div className="banner d-flex flex-columnn apply-padding">
        <div className="container d-flex flex-column">
          <div className="row my-auto">
            <div className="col-md-6 col-lg-8 text-left">
              <h2 className="text-dark font-weight-bold">
                {t("our-services.our-product-header")}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row bg-white">
          <div className="col-md-2 text-center text-md-right">
            <h1 className="font-weight-bold">Eureka</h1>
            <p>Reports</p>
          </div>
          <div className="col-md-10 text-left px-5">
            <p>
            {t('our-services.eureka-reports-text')}
            </p>
          </div>
        </div>
        <div className="row bg-white mt-4">
          <div className="col-md-2 text-center text-md-right">
            <h1 className="font-weight-bold">Gallop</h1>
            <p>Reports</p>
          </div>
          <div className="col-md-10 text-left px-5">
            <p>
            {t('our-services.gallop-reports-text')}
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white container-fluid no-gutters  text-left p-0 position-relative overflow-hidden list-container">
        <div className="row py-5 m-0">
          <div className="col-md-4 p-0 no-gutters">
            <div className="container list-heading mb-5">
              <h3 style={{ lineHeight: "3rem" }}>
              {t('our-services.what-kind-of-companies-header')}
              </h3>
            </div>
            <img
              src={leftArrow}
              alt="style left arrow"
              className="d-none d-sm-block"
            />
          </div>
          <div className="col-md-6 text-dark">
            <p className="font-weight-bold">{t('our-services.what-kind-of-companies-list-one.header')}</p>
            <ul>
              <li>
              {t('our-services.what-kind-of-companies-list-one.item-1')}
              </li>
              <li>
              {t('our-services.what-kind-of-companies-list-one.item-2')}
              </li>
            </ul>
            <p className="font-weight-bold">
            {t('our-services.what-kind-of-companies-list-two.header')}
            </p>
            <ul>
              <li>
              {t('our-services.what-kind-of-companies-list-two.item-1')}
              </li>
              <li>
                <p>
                {t('our-services.what-kind-of-companies-list-two.item-2')}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <img
          src={rightArrow}
          alt="right design arrow"
          className="position-absolute right-arrow d-none d-sm-block"
          style={{ right: 0, top: 0 }}
        />
      </div>
    </div>
  );
}

export default OurServicesSectionThree;
