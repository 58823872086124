class Api {
  constructor() {
    this.token = "";
    this.setToken = this.setToken.bind(this);
    // why bro....
    this.getAllCompanies = this.getAllCompanies.bind(this);
    this.getAllExchanges = this.getAllExchanges.bind(this);
    this.host = process.env.REACT_APP_DATA_HOST;
  }

  setToken(token) {
    this.token = token;
  }

  getAllExchanges() {
    return fetch(`${this.host}/exchanges`, {
      headers: { Authorization: `Bearer ${this.token}` },
    }).then((data) => data.json());
  }

  getAllCompanies() {
    return fetch(`${this.host}/companies`, {
      headers: { Authorization: `Bearer ${this.token}` },
    }).then((data) => data.json());
  }

  createCompany(body) {
    return fetch(`${this.host}/company`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((data) => data.json());
  }

  deleteCompany(id) {
    return fetch(`${this.host}/company/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      }
    }).then((data) => data.json());
  }

  fetchSingleCompany(id) {
    return fetch(`${this.host}/company/${id}`, {
      headers: { Authorization: `Bearer ${this.token}` },
    }).then((data) => data.json());
  }

  fetchDatapoints(id) {
    return fetch(
      `${this.host}/datapoints/range?id=${id}&range=MAX`
    ).then((data) => data.json());
  }

  fetchRangeDatapoints(id, range) {
    return fetch(
      `${this.host}/datapoints/range?id=${id}&range=${range}`
    ).then((data) => data.json());
  }

  createDatapoints(formData) {
    return fetch(`${this.host}/datapoints/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      body: formData,
    }).then((data) => data.json());
  }
}

const api = new Api();
export default api;
