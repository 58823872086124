import React from "react";
import { useQuery } from "react-query";
import { useParams, Link } from "react-router-dom";
import Loader from "../../components/loader/loader";
import Api from "../../services/api";
import Datapoints from "./datapoints";
function CompanyDatapoints(props) {
  const { id } = useParams();
  const { isLoading, isError, data, error } = useQuery(
    ["single-company", id],
    () => Api.fetchSingleCompany(id)
  );
  if (isLoading) {
    return (
      <div className="container-fluid h-100 d-flex flex-column justify-content-center">
        <Loader />
      </div>
    );
  }
  if (isError) {
    return (
      <div className="container-fluid h-100 d-flex flex-column justify-content-center">
        <h1 className="display-4">
          There was an error, please try again later.
        </h1>
      </div>
    );
  }
  return (
    <div className="container-fluid text-left h-100 pt-4">
      <Link to={"/admin/companies"}>Back</Link>
      {data && <h1 className="display-2 mt-4">{data.name}</h1>}
      {data && (
        <h1 className="display-4" style={{ color: "#dd484c" }}>
          {data.ticker} <small className="text-muted">({data.exchange})</small>
        </h1>
      )}
      <Datapoints id={id} />
    </div>
  );
}

export default CompanyDatapoints;
